import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

export function fireTrackingZipChange(value, actionCategory) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.FILTER_SEARCH_RESULT,
      ...(actionCategory ? { action_category: actionCategory } : {}),
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: 'edm-entry-facets-zip_and_radius',
      value,
    },
  });
}
