import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingDots } from 'site-modules/shared/components/loading-dots/loading-dots';
import { OdometerWrapperAsync } from 'site-modules/shared/components/odometer-wrapper/odometer-wrapper-async';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';

import './rebates-amount.scss';

export const RebatesAmount = props => {
  const { value, className } = props;
  const [odometerLoaded, setOdometerLoaded] = useState(false);

  const amountVisible = !isNaN(value) && odometerLoaded;

  const handleOdometerLoaded = useCallback(() => {
    setOdometerLoaded(true);
  }, []);

  return (
    <div
      className={classNames('rebates-amount', className)}
      style={{ minWidth: '78px', display: 'inline-flex', justifyContent: 'center' }}
    >
      <LoadingDots
        style={{
          position: 'absolute',
          visibility: amountVisible ? 'hidden' : 'visible',
          transform: 'translateY(-150%)',
        }}
      />
      <span
        className="visually-hidden"
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          padding: '0',
          margin: '-1px',
          overflow: 'hidden',
        }}
      >
        {formatPriceString(amountVisible ? value : 0)}
      </span>
      <div aria-hidden>
        <OdometerWrapperAsync
          value={amountVisible ? value : 0}
          classNames="rebates-amount-odometer"
          style={{ display: 'flex', lineHeight: '1rem', visibility: amountVisible ? 'visible' : 'hidden' }}
          duration={500}
          onDidMount={handleOdometerLoaded}
        />
      </div>
    </div>
  );
};

RebatesAmount.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
};

RebatesAmount.defaultProps = {
  value: undefined,
  className: undefined,
};
