import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DEFAULT_TRACKING_ID = 'default';

export class DrawerCloseButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    lgBtnClass: PropTypes.string,
    smBtnClass: PropTypes.string,
    trackingId: PropTypes.string,
    trackingValue: PropTypes.string,
    iconClass: PropTypes.string,
    btnText: PropTypes.string,
    btnPadding: PropTypes.string,
    btnAnchorClass: PropTypes.string,
    btnAnchorSpanClass: PropTypes.string,
    lgIconClass: PropTypes.string,
    linkBtnDisplayClass: PropTypes.string,
    iconBtnDisplayClass: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    trackingId: '',
    trackingValue: '',
    lgBtnClass: '',
    smBtnClass: '',
    iconClass: 'icon-cross2',
    btnText: 'back',
    btnPadding: 'pt-1',
    btnAnchorClass: 'text-info btn drawer-btn-back-circle btn-link',
    btnAnchorSpanClass: '',
    lgIconClass: 'circle icon-arrow-left3 bg-gray-lighter',
    linkBtnDisplayClass: 'd-none d-md-block',
    iconBtnDisplayClass: 'd-md-none',
  };

  render() {
    const {
      onClick,
      className,
      trackingId,
      trackingValue,
      lgBtnClass,
      smBtnClass,
      iconClass,
      btnText,
      btnPadding,
      btnAnchorClass,
      btnAnchorSpanClass,
      lgIconClass,
      linkBtnDisplayClass,
      iconBtnDisplayClass,
    } = this.props;

    return (
      <div
        className={className}
        data-tracking-id={trackingId || DEFAULT_TRACKING_ID}
        data-tracking-value={trackingValue}
        data-tracking-disabled={!trackingId}
      >
        <button
          type="button"
          className={classNames('drawer-close', linkBtnDisplayClass, btnPadding, lgBtnClass)}
          onClick={onClick}
        >
          <span className={classNames('text-decoration-none', btnAnchorClass)}>
            <span className={classNames(lgIconClass, btnAnchorSpanClass)} />
            &nbsp;<span className="h5">{btnText}</span>
          </span>
        </button>
        <button
          type="button"
          className={classNames('drawer-close row justify-content-end', iconBtnDisplayClass, smBtnClass)}
          onClick={onClick}
          aria-label="Close drawer"
        >
          <span className={classNames('d-block col-auto px-0_5 py-0_5')} data-dismiss="modal">
            <span className={iconClass} />
          </span>
        </button>
      </div>
    );
  }
}
