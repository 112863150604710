import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { getValidImageUrl, SOURCES } from 'client/utils/image-helpers';
import { AuthorTooltip } from 'site-modules/shared/components/editorial/author-tooltip/author-tooltip';

import './article-author.scss';

// eslint-disable-next-line react/prop-types
function wrapAuthorLink({ link, name, about, title, showBioTooltip }) {
  return (
    <Fragment>
      by
      {link ? (
        <a className="author btn-link text-gray-darker text-capitalize align-baseline fw-bold ps-0_25" href={link}>
          {name}
        </a>
      ) : (
        <span itemProp="author" className="author text-gray-darker align-baseline ps-0_25">
          {name}
        </span>
      )}
      {showBioTooltip && <AuthorTooltip aboutAuthor={about} name={name} title={title} />}
    </Fragment>
  );
}

export function ArticleAuthor({
  author,
  ratedBy,
  date,
  dateModified,
  publishDate,
  className,
  imageHeight,
  imageWidth,
  lazyLoadImage,
  showBioTooltip,
  isDateTextBelow,
}) {
  const { authorName, photoPath, authorLink, aboutAuthor, authorTitle } = author || {};
  const {
    authorName: ratedByName,
    photoPath: ratedByPhotoPath,
    authorLink: ratedByLink,
    aboutAuthor: aboutRatedBy,
    authorTitle: ratedByTitle,
  } = ratedBy || {};
  const imageLoading = lazyLoadImage ? 'lazy' : undefined;
  const imageProps = {
    className: 'author-photo rounded me-0_75',
    'aria-hidden': 'true',
    height: imageHeight,
    width: imageWidth,
    loading: imageLoading,
    style: { objectFit: 'cover' },
  };

  const dateText = date && (
    <div>
      {!isDateTextBelow && <span className="mx-0_5">•</span>}
      {dateModified && <meta itemProp="dateModified" content={dateModified} />}
      {publishDate && <meta itemProp="datePublished" content={publishDate} />}
      {date}
    </div>
  );

  return (
    <div className={classnames('article-author d-flex align-items-center text-gray-darker mb-1_5 mt-1', className)}>
      {authorName ? (
        <Fragment>
          {photoPath && <img src={getValidImageUrl(photoPath, SOURCES.STATIC)} alt="" {...imageProps} />}
          {ratedByPhotoPath && <img src={getValidImageUrl(ratedByPhotoPath, SOURCES.STATIC)} alt="" {...imageProps} />}
          <div className="author-links">
            {wrapAuthorLink({
              link: authorLink,
              name: authorName,
              about: aboutAuthor,
              title: authorTitle,
              showBioTooltip,
            })}
            {ratedByName && (
              <div>
                rating written{' '}
                {wrapAuthorLink({
                  link: ratedByLink,
                  name: ratedByName,
                  about: aboutRatedBy,
                  title: ratedByTitle,
                  showBioTooltip,
                })}
              </div>
            )}
            {isDateTextBelow && dateText}
          </div>
        </Fragment>
      ) : (
        <div>
          by <span className="author fw-bold">the Edmunds Experts</span>
          {isDateTextBelow && dateText}
        </div>
      )}
      {!isDateTextBelow && dateText}
    </div>
  );
}

ArticleAuthor.propTypes = {
  author: EditorialReviewEntities.AuthorData,
  ratedBy: EditorialReviewEntities.AuthorData,
  date: PropTypes.string,
  dateModified: PropTypes.string,
  publishDate: PropTypes.string,
  imageHeight: PropTypes.number,
  className: PropTypes.string,
  lazyLoadImage: PropTypes.bool,
  showBioTooltip: PropTypes.bool,
  isDateTextBelow: PropTypes.bool,
};

ArticleAuthor.defaultProps = {
  author: null,
  ratedBy: null,
  date: null,
  dateModified: null,
  publishDate: null,
  imageHeight: 45,
  imageWidth: 45,
  className: 'size-12',
  lazyLoadImage: false,
  showBioTooltip: false,
  isDateTextBelow: false,
};
