import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { getTotalRebateAmount } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-rebate-amount-from-incentives';
import { isLocalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-rebate';

export function getPotentialRebatesSum(incentives) {
  const filteredIncentives = incentives.filter(incentive =>
    [isOEMRebate, isFederalRebate, isLocalRebate].some(cb => !!cb(incentive))
  );

  return getTotalRebateAmount(filteredIncentives);
}
