import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getWriteReviewUrl, getConsumerReviewsUrl } from 'site-modules/shared/utils/core-link-constructor';
import { Link } from 'site-modules/shared/components/link/link';

const LINK_PROPS = {
  params: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  mmsy: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    make: PropTypes.string,
    model: PropTypes.string,
  }),
  classes: PropTypes.string,
  target: PropTypes.string,
  actionButtonsEnabled: PropTypes.bool,
  showArrowIcon: PropTypes.bool,
};

const DEFAULT_LINK_PROPS = {
  mmsy: {},
  classes: '',
  target: '_self',
  actionButtonsEnabled: false,
  showArrowIcon: true,
};

/**
 * Write review link (`/bmw/x3/2018/write-review.html`).
 */
export function WriteReview({ params, mmsy, classes, actionButtonsEnabled, showArrowIcon, ...otherProps }) {
  return (
    <Link
      to={getWriteReviewUrl(params)}
      className={classes}
      rel="nofollow"
      data-tracking-id="write_review"
      data-tracking-value={`Write a ${mmsy.year} ${mmsy.make} ${mmsy.model} review`}
      {...otherProps}
    >
      Write a vehicle review
      {showArrowIcon && <i className="icon-arrow-right4 size-10 ps-0_25" aria-hidden />}
    </Link>
  );
}

WriteReview.propTypes = LINK_PROPS;
WriteReview.defaultProps = { ...DEFAULT_LINK_PROPS, classes: 'text-primary-darker' };

/**
 * See all consumer reviews link (`/bmw/x3/2018/consumer-reviews/`).
 */
export function SeeAllReviewsLink({ params, reviewsCount, classes, rel, target, mmsy, displayVehicle, showArrowIcon }) {
  return (
    <Link
      to={getConsumerReviewsUrl(params)}
      className={classnames('see-all-reviews', classes)}
      data-tracking-id="read_all_reviews"
      data-tracking-value="Read All Reviews"
      rel={rel}
      target={target}
    >
      {`See all ${reviewsCount} reviews${
        displayVehicle || (mmsy && mmsy.model) ? ` of the ${displayVehicle || `${mmsy.make} ${mmsy.model}`}` : ''
      }`}
      {showArrowIcon && <i className="icon-arrow-right4 size-10 ps-0_25" aria-hidden />}
    </Link>
  );
}

SeeAllReviewsLink.propTypes = {
  ...LINK_PROPS,
  displayVehicle: PropTypes.string,
  rel: PropTypes.string,
  reviewsCount: PropTypes.number,
  target: PropTypes.string,
};

SeeAllReviewsLink.defaultProps = {
  ...DEFAULT_LINK_PROPS,
  classes: 'text-primary-darker',
  displayVehicle: null,
  rel: null,
  reviewsCount: 0,
  target: '_self',
};

/**
 * Report abuse comment link (`//help.edmunds.com/hc/en-us/requests/new?{tickedId};{ticketSubject};{ticketDescription`).
 */
export function ReportAbuseLink({ reviewTitle, reviewId, userName, classes }) {
  const ticketFormId = 'ticket_form_id=2343&amp';
  const ticketSubject = `ticket[subject]=Report review (${reviewId}) to Edmunds.com for invistigation&amp`;
  const ticketDescription = `ticket[description]=I am reporting this review ${reviewTitle} posted by ${userName} because...`;
  return (
    <Link
      to={`//help.edmunds.com/hc/en-us/requests/new?${ticketFormId};${ticketSubject};${ticketDescription}`}
      className={classnames('d-inline-block text-gray-darker', classes)}
      rel="nofollow"
    >
      Report abuse
      <i className="icon-arrow-right4 size-10 ps-0_25" aria-hidden />
    </Link>
  );
}

ReportAbuseLink.propTypes = {
  reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  reviewTitle: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

ReportAbuseLink.defaultProps = {
  classes: '',
};
