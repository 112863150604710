import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, sortBy, get, noop } from 'lodash';
import { Container } from 'reactstrap';
import {
  EXPANDED_FILTERS,
  FACETS_CONFIG,
  FACETS_GROUPS_CONFIG,
  FACETS_GROUPS_CONFIG_SHOP_3424,
  FACETS_SUBGROUPS_CONFIG,
  GROUPS_KEYS,
  SUBGROUP_KEYS,
} from 'site-modules/shared/constants/inventory/usurp-filters-config';
import { DISPLAY_PRICE, MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { GLOBAL_HEADER_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { SKIP_TO_LINKS_CONFIG } from 'site-modules/shared/constants/inventory/routes-constants';
import { UsurpFilteringByCollapse } from 'site-modules/shared/components/inventory/usurp-filtering-by/usurp-filtering-by-collapse';
import { FiltersGroupWrapper } from 'site-modules/shared/components/inventory/usurp-filters-group/filters-group-wrapper';
import { UsurpFiltersGroup } from 'site-modules/shared/components/inventory/usurp-filters-group/usurp-filters-group';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';
import { UsurpTabsGroup } from 'site-modules/shared/components/inventory/usurp-tabs-group/usurp-tabs-group';
import { SuggestedFacets } from 'site-modules/shared/components/inventory/suggested-facets/suggested-facets';
import { UsurpMultiSelectGroup } from 'site-modules/shared/components/inventory/usurp-multi-select-group/usurp-multi-select-group';
import { UsurpZipInput } from 'site-modules/shared/components/inventory/usurp-zip-input/usurp-zip-input';
import { ActiveFiltersGroup } from 'site-modules/shared/components/inventory/active-filters-group/active-filters-group';
import { EvEBuyingExperienceButtonGroup } from 'site-modules/shared/components/eve-buying-experience-button-group/eve-buying-experience-button-group';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { ANY_BTN, IN_STORE_BTN } from 'site-modules/shared/constants/inventory/buying-experience';

import './usurp-filters.scss';

export class UsurpFilters extends PureComponent {
  static propTypes = {
    onZipCodeUpdate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    facets: PropTypes.arrayOf(PropTypes.shape({})),
    filteringByFacets: PropTypes.arrayOf(PropTypes.shape({})),
    isNational: PropTypes.bool,
    isDealerSrp: PropTypes.bool,
    paymentType: PropTypes.string,
    isDrawer: PropTypes.bool,
    selectedFacets: PropTypes.shape({}),
    setIsMultiMM: PropTypes.func,
    searchResultsFound: PropTypes.number,
    hasBuyOnlineInventories: PropTypes.bool,
    pagePublicationState: PropTypes.string,
    isSHOP3424Chal: PropTypes.bool,
  };

  static defaultProps = {
    facets: [],
    filteringByFacets: [],
    isNational: false,
    isDealerSrp: false,
    paymentType: null,
    isDrawer: false,
    selectedFacets: {},
    setIsMultiMM: noop,
    searchResultsFound: undefined,
    hasBuyOnlineInventories: false,
    pagePublicationState: '',
    isSHOP3424Chal: false,
  };

  getFacets = facets => {
    const subgroupFacets = [];

    facets.forEach(facet => {
      const { type } = facet;
      const config = FACETS_CONFIG[type];

      if (config) {
        const { indexInsideGroup } = config;
        const facetProps = { config, indexInsideGroup, ...facet };

        subgroupFacets.push(facetProps);
      }
    });
    return sortBy(subgroupFacets, ['indexInsideGroup']);
  };

  getFacetConfig = facet => {
    const { type, group: subgroupName } = facet;

    return subgroupName ? FACETS_SUBGROUPS_CONFIG[subgroupName] : FACETS_CONFIG[type];
  };

  getGroupedFacets = () => {
    const groups = {};

    this.filterOutEmptyFacets().forEach(facet => {
      const { group: subgroupName, rangeFacet, values } = facet;
      const config = this.getFacetConfig(facet);

      let facetProps;

      if (config) {
        const { group, indexInsideGroup } = config;
        facetProps = subgroupName
          ? { group: subgroupName, config, indexInsideGroup, rangeFacet, values: this.getFacets(values) }
          : {
              config,
              indexInsideGroup,
              ...facet,
              values: facet.values && [...this.filterValues(facet.values, config)],
            };

        if (groups[group]) {
          groups[group].push(facetProps);
        } else {
          groups[group] = [facetProps];
        }
      }
    });

    return map(groups, (groupFacets, groupKey) => {
      const groupConfig = get(
        this.props.isSHOP3424Chal ? FACETS_GROUPS_CONFIG_SHOP_3424 : FACETS_GROUPS_CONFIG,
        [groupKey],
        {}
      );

      return {
        groupKey,
        facets: sortBy(groupFacets, ['indexInsideGroup']),
        ...groupConfig,
      };
    });
  };

  getGroupComponent = ({ group }) => {
    const {
      onZipCodeUpdate,
      isNational,
      isDealerSrp,
      paymentType,
      onUpdate,
      isDrawer,
      selectedFacets,
      facets: allFacets,
      setIsMultiMM,
      searchResultsFound,
    } = this.props;
    const { groupKey, facets, withInnerDividers } = group;

    const modelFacetValues = get(allFacets.find(({ type }) => type === MODEL), 'groups', {});
    const GeneralGroupComponent = (
      <UsurpFiltersGroup
        facets={facets.filter(facet => !facet.config.subgroup)}
        onUpdate={onUpdate}
        withInnerDividers={withInnerDividers}
        selectedFacets={selectedFacets}
        modelFacetValues={modelFacetValues}
        facetsConfig={FACETS_CONFIG}
      />
    );

    switch (groupKey) {
      case GROUPS_KEYS.LOCATION_GROUP: {
        return isDealerSrp ? null : (
          <Fragment>
            <UsurpZipInput onZipCodeUpdate={onZipCodeUpdate} isNational={isNational} className="mb-1" />
            {GeneralGroupComponent}
          </Fragment>
        );
      }
      case GROUPS_KEYS.PRICE_PAYMENT_GROUP: {
        const selectedTab = paymentType || DISPLAY_PRICE;
        const pricePaymentFacets = facets.filter(
          facet => facet.config.subgroup === SUBGROUP_KEYS.PRICE_PAYMENT_TABS_SUBGROUP
        );
        return (
          <Fragment>
            <UsurpTabsGroup
              facets={pricePaymentFacets}
              selectedTab={selectedTab}
              onUpdate={onUpdate}
              key={selectedTab}
              isMobile={isDrawer}
              facetsConfig={FACETS_CONFIG}
              searchResultsFound={searchResultsFound}
            />
            {GeneralGroupComponent}
          </Fragment>
        );
      }
      case GROUPS_KEYS.MM_GROUP: {
        return (
          <UsurpMultiSelectGroup
            facets={facets.filter(facet => !facet.config.subgroup)}
            onUpdate={onUpdate}
            withInnerDividers={withInnerDividers}
            selectedFacets={selectedFacets}
            setIsMultiMM={setIsMultiMM}
            facetsConfig={FACETS_CONFIG}
          />
        );
      }
      default: {
        return GeneralGroupComponent;
      }
    }
  };

  filterOutEmptyFacets = () => {
    const { facets } = this.props;

    return facets.filter(facet => {
      const config = this.getFacetConfig(facet);

      if (!config) {
        return true;
      }

      const { hideWhenAllValuesEmpty } = config;

      if (hideWhenAllValuesEmpty) {
        return !!this.filterValues(facet.values, config).length;
      }

      return true;
    });
  };

  filterValues = (values, config) => {
    const { excludeZeroCount, itemsToShow, hideWhenAllValuesEmpty } = config;
    let updatedValues = itemsToShow ? values.filter(item => itemsToShow.includes(item.name)) : values;
    updatedValues = updatedValues.filter(value => !excludeZeroCount || (value && value.count !== 0));

    if (hideWhenAllValuesEmpty && updatedValues.every(item => item.count === 0)) {
      return [];
    }

    return updatedValues;
  };

  render() {
    const {
      onUpdate,
      filteringByFacets,
      isDrawer,
      pagePublicationState,
      hasBuyOnlineInventories,
      selectedFacets,
    } = this.props;
    const groupedFacets = this.getGroupedFacets();

    return (
      <Container
        className="usurp-filters text-gray-darker px-0"
        tabIndex={-1}
        role="region"
        aria-labelledby="filters-panel-header"
        id={SKIP_TO_LINKS_CONFIG.FILTERS_CONTAINER_ID}
      >
        <div className="visually-hidden" role="heading" aria-level={2} id="filters-panel-header">
          Filters
        </div>
        {!isDrawer && (
          <>
            <ScrollLink
              to={SKIP_TO_LINKS_CONFIG.RESULTS_CONTAINER_ID}
              className="visually-hidden visually-hidden-focusable d-none d-lg-block"
              scrollConfig={{ offset: GLOBAL_HEADER_OFFSET }}
            >
              {SKIP_TO_LINKS_CONFIG.RESULTS_CONTAINER_LABEL}
            </ScrollLink>
            {!!filteringByFacets.length && <ActiveFiltersGroup facets={filteringByFacets} onUpdate={onUpdate} />}
            {pagePublicationState === PUB_STATES_LOWERCASE.USED && hasBuyOnlineInventories && (
              <EvEBuyingExperienceButtonGroup
                selectedFacets={selectedFacets}
                selectedBtn={get(selectedFacets, 'deliveryType[0]') === 'local' ? IN_STORE_BTN : ANY_BTN}
                headingTag="h3"
              />
            )}
          </>
        )}
        {isDrawer && (
          <UsurpFilteringByCollapse
            facets={filteringByFacets}
            onUpdate={onUpdate}
            className="py-1 px-1"
            suggestedFacetsComponent={SuggestedFacets}
          />
        )}
        {sortBy(groupedFacets, ['order']).map((group, index) => {
          const { groupKey, title, creativeId, isSrOnlyTitle, tooltip, adornmentGroupConfig, legendConfig } = group;

          const withCollapse = !EXPANDED_FILTERS.includes(groupKey);

          const GroupComponent = this.getGroupComponent({
            group,
          });

          return GroupComponent ? (
            <FiltersGroupWrapper
              key={groupKey}
              title={title}
              isSrOnlyTitle={isSrOnlyTitle}
              creativeId={creativeId}
              withCollapse={withCollapse}
              hideCollapseSeparator={index === groupedFacets.length - 1}
              tooltip={tooltip}
              adornmentGroupConfig={adornmentGroupConfig}
              legendConfig={legendConfig}
            >
              {GroupComponent}
            </FiltersGroupWrapper>
          ) : null;
        })}
      </Container>
    );
  }
}
