import { isEmpty, get, isUndefined } from 'lodash';

/* Constants */
import { LEAD_FORM_STATES } from 'site-modules/shared/constants/lead-form/lead-form-states';

/* Utils */
import { getLeadTargetInfo } from 'site-modules/shared/components/lead-form/utils/utils';
import { sendMarketoEmailReport } from 'client/data/utils/marketo-service';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getEspTrustDateText } from 'site-modules/shared/utils/esp';
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';

/*
  File contains methods from LeadFormWrapper component.
  All available LeadFormWrapper methods will be accessible via `this`.
  Methods in this file called by LeadFormWrapper and tested through it flow.
*/

export function processResult(leadIds) {
  if (isEmpty(leadIds)) {
    this.setState({ formState: LEAD_FORM_STATES.SUBMIT_LEAD_ERROR, showSubmitLoadingIndicator: false });
    return Promise.reject('error');
  }

  this.runInsiderAction();
  this.showThankYouPage();

  return Promise.resolve();
}

export function processResultWithoutThankYouPage(leadIds) {
  if (isEmpty(leadIds)) {
    return Promise.reject('error');
  }

  return Promise.resolve();
}

// TODO: Move this logic to backend once migrated to Marketo completely
export function sendUsedInventoryMarketoThankYouEmail() {
  const { make, model, year, style, customConfig } = this.props;
  const { email } = this.personalInfo;

  const { make: leadMake, model: leadModel, year: leadYear, style: leadStyle } = getLeadTargetInfo(
    get(this.selectedLeadTargets, '[0]')
  ).styleInfo;
  const { mileage } = get(this.selectedLeadTargets, '[0].inventory.vehicleInfo', '');
  const displayPrice = get(this.selectedLeadTargets, '[0].inventory.prices.displayPrice', '');

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_TOOL_SELECT,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: 'send_marketo_lead_confirm',
      creative_id: get(customConfig, 'creativeId'),
    },
  });

  const marketoTokens = {
    'my.make': make || leadMake,
    'my.model': model || leadModel,
    'my.year': (year || leadYear || '').toString(),
    'my.style': style || leadStyle || '',
    'my.leads': JSON.stringify(
      this.selectedLeadTargets
        .map(leadTarget => {
          const { inventory, leadAttributes } = leadTarget;
          const { dealerInfo } = getLeadTargetInfo(leadTarget);
          const distance = get(leadAttributes, 'distance', 0);

          const { areaCode = '', prefix = '', postfix = '' } =
            get(dealerInfo, 'phoneNumbers.ppTrackable') || get(dealerInfo, 'phoneNumbers.trackable', {});

          const dealerData = {
            name: get(dealerInfo, 'name', ''),
            street: get(dealerInfo, 'address.street', ''),
            city: get(dealerInfo, 'address.city', ''),
            state: get(dealerInfo, 'address.stateCode', ''),
            zip: get(dealerInfo, 'address.zip', ''),
            distance: distance > 0 ? distance.toFixed(1) : '',
            phone: {
              areaCode,
              prefix,
              postfix,
            },
          };

          const inventoryTypeName = isCpo(get(inventory, 'type', '')) ? 'Certified' : 'Used';
          const photoUrl =
            get(inventory, 'vehicleInfo.photo.defaultPhoto.large.url', '') ||
            get(inventory, 'vehicleInfo.photo.defaultPhoto.small.url', '');

          return {
            leadType: 'inventory',
            purchaseVehicle: {
              vin: inventory.vin,
              photoUrl: photoUrl.includes('evox') ? '' : photoUrl,
              stockNumber: inventory.stockNumber,
              bestDealPrice: displayPrice,
              inventoryTypeName,
              mileage,
            },
            dealer: dealerData,
            referringUrls: {
              vdp: `https://www.edmunds.com${getVdpUrl({ inventory })}`,
            },
          };
        })
        // Sort 'inventory' first, 'dealer' last
        .sort((a, b) => a.leadType.localeCompare(b.leadType) * -1)
    ),
  };
  return sendMarketoEmailReport({ data: marketoTokens, campaignName: 'used_pricing_confirmation_email', email });
}

export function sendPriceCheckerPromoEmail() {
  return sendMarketoEmailReport({
    data: {
      'my.visitorId': this.props.visitorId,
      'my.leadId': this.leadIds[0],
      'my.utmCampaign':
        get(this.props, 'customConfig.utmCampaign', '') ||
        get(this.props, 'customConfig.thankYouConfig.priceCheckerParams.priceCheckerPromoQuery.utm_campaign', ''),
      'my.make': makeNiceName(this.props.make),
      'my.model': makeNiceName(this.props.model),
      'my.year': this.props.year.toString(),
      'my.espTrustText': getEspTrustDateText(),
    },
    campaignName: 'price_checker_promotion_2',
    email: this.personalInfo.email,
  });
}

// TODO: Move this logic to backend once migrated to Marketo completely
export function sendMarketoThankYouEmail(additionalData) {
  const { make, model, year, trim, visitorId, zipCode, customConfig } = this.props;
  const { email } = this.personalInfo;

  const { make: leadMake = '', model: leadModel = '', year: leadYear = '', trim: leadTrim = '' } = getLeadTargetInfo(
    get(this.selectedLeadTargets, '[0]')
  ).styleInfo;
  // the logic from getSingleReceiptSubmissionInfo (site-modules/shared/utils/car-buying/submit-lead-receipt-helper.js)
  // to not send email
  const isPriceFocused = get(additionalData, 'deals[0].leadDetails.isPriceFocused');
  if (!isUndefined(isPriceFocused) && !isPriceFocused) {
    return Promise.resolve();
  }

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_TOOL_SELECT,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: 'send_marketo_lead_confirm',
      creative_id: get(customConfig, 'creativeId'),
    },
  });

  const marketoTokens = {
    'my.make': make || leadMake,
    'my.model': model || leadModel,
    'my.year': (year || leadYear).toString(),
    'my.trim': trim || leadTrim,
    'my.vehicleParams': JSON.stringify({
      make: makeNiceName(make || leadMake),
      model: makeNiceName(model || leadModel),
    }),
    'my.espTrustText': getEspTrustDateText(),
    'my.utmCampaign':
      get(customConfig, 'utmCampaign', '') ||
      get(customConfig, 'thankYouConfig.priceCheckerParams.priceCheckerPromoQuery.utm_campaign', ''),
    'my.visitorId': visitorId,
    'my.zipCode': zipCode,
    'my.hasTreehousePromo': `${get(customConfig, 'thankYouConfig.hasEmailTreehousePromotion', '')}`,
    'my.leads': JSON.stringify(
      // Sort 'inventory' first, 'dealer' last
      this.selectedLeadTargets
        .map(leadTarget => {
          const { leadAttributes, inventory } = leadTarget;
          const { dealerInfo, styleInfo } = getLeadTargetInfo(leadTarget);
          const distance = get(leadAttributes, 'distance', 0);

          const { areaCode = '', prefix = '', postfix = '' } =
            get(dealerInfo, 'phoneNumbers.ppTrackable') || get(dealerInfo, 'phoneNumbers.trackable', {});
          const dealerData = {
            name: get(dealerInfo, 'name', ''),
            street: get(dealerInfo, 'address.street', ''),
            city: get(dealerInfo, 'address.city', ''),
            state: get(dealerInfo, 'address.stateCode', ''),
            zip: get(dealerInfo, 'address.zip', ''),
            rooftopId: get(dealerInfo, 'rooftopId', ''),
            distance: distance > 0 ? distance.toFixed(1) : '',
            phone: {
              areaCode,
              prefix,
              postfix,
            },
          };

          // dealer
          if (!inventory) {
            return {
              leadType: 'dealer',
              dealer: dealerData,
            };
          }

          const photoUrl =
            get(inventory, 'vehicleInfo.photo.defaultPhoto.large.url', '') ||
            get(inventory, 'vehicleInfo.photo.defaultPhoto.small.url', '');

          return {
            leadType: 'inventory',
            purchaseVehicle: {
              vin: inventory.vin,
              styleName: get(styleInfo, 'style', ''),
              photoUrl: photoUrl.includes('evox') ? '' : photoUrl,
              exteriorColor: get(inventory, 'vehicleInfo.vehicleColors.exterior.name', ''),
              stockNumber: inventory.stockNumber,
              msrp: get(inventory, 'prices.displayPrice', ''),
              bestDealPrice: get(inventory, 'computedInfo.bestDealPrice', ''),
            },
            dealer: dealerData,
            attributes: {
              destinationFee: get(inventory, 'prices.destinationFee', ''),
              hideDestinationFee: (!!get(leadAttributes, 'hideDestinationFee', false)).toString(),
            },
          };
        })
        .sort((a, b) => a.leadType.localeCompare(b.leadType) * -1)
    ),
  };

  return sendMarketoEmailReport({ data: marketoTokens, campaignName: 'new_pricing_confirmation_email', email });
}

export function sendMarketoCSDDealerThankYouEmail(props) {
  const { make, model, year, trim, visitorId, inventory, email, distance, photoUrl, color } = props;

  const dealerInfo = get(inventory, 'dealerInfo', {});
  const { areaCode = '', prefix = '', postfix = '' } =
    get(dealerInfo, 'phoneNumbers.ppTrackable') || get(dealerInfo, 'phoneNumbers.trackable', {});
  const dealerData = {
    name: get(dealerInfo, 'name', ''),
    street: get(dealerInfo, 'address.street', ''),
    city: get(dealerInfo, 'address.city', ''),
    state: get(dealerInfo, 'address.stateCode', ''),
    zip: get(dealerInfo, 'address.zip', ''),
    rooftopId: get(dealerInfo, 'rooftopId', ''),
    distance: distance > 0 ? distance.toFixed(1) : '',
    phone: {
      areaCode,
      prefix,
      postfix,
    },
  };

  const leadConfig = !inventory
    ? {
        leadType: 'dealer',
        dealer: dealerData,
      }
    : {
        leadType: 'inventory',
        purchaseVehicle: {
          vin: inventory.vin,
          styleName: get(inventory, 'vehicleInfo.styleInfo.style', ''),
          photoUrl,
          exteriorColor: color || get(inventory, 'vehicleInfo.vehicleColors.exterior.name', ''),
          stockNumber: inventory.stockNumber,
          msrp: get(inventory, 'prices.displayPrice', ''),
          bestDealPrice: get(inventory, 'computedInfo.bestDealPrice', ''),
        },
        dealer: dealerData,
      };

  const marketoTokens = {
    'my.make': make,
    'my.model': model,
    'my.year': year.toString(),
    'my.trim': trim,
    'my.vehicleParams': JSON.stringify({
      make: makeNiceName(make),
      model: makeNiceName(model),
    }),
    'my.espTrustText': getEspTrustDateText(),
    'my.visitorId': visitorId,
    'my.leads': `[${JSON.stringify(leadConfig)}]`,
  };

  return sendMarketoEmailReport({ data: marketoTokens, campaignName: 'new_pricing_confirmation_email', email });
}
