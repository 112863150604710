import { buildPersistedIncentiveId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/persisted-incentive-id';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-rebate';
import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import {
  ENGINE_TYPE_QUESTION_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  ZIP_CODE_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { getPageTransactionType } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-page-transaction-type';
import { createDate } from 'site-modules/shared/utils/date-utils';

const pagePaymentTypeMatchesTransactionType = (answers, context) => {
  const transactionType = answers[TRANSACTION_TYPE_QUESTION_ID]?.[0];
  const { paymentType, inventoryType } = context;

  return (
    !transactionType ||
    transactionType.toLowerCase() === getPageTransactionType(inventoryType, paymentType).toLowerCase()
  );
};

const checkZipCodeMatches = (answers, context) => {
  const zipAnswer = answers[ZIP_CODE_QUESTION_ID]?.[0];
  const { zipCode } = context;

  return !zipCode || zipAnswer === zipCode;
};

const checkStateCodeMatches = (answers, context) => {
  const { stateCode, wizardState } = context;

  return stateCode === wizardState.stateCode;
};

const checkEngineTypeMatches = (answers, context) => {
  const engineType = answers[ENGINE_TYPE_QUESTION_ID]?.[0];
  const { engineTypes } = context;

  return !engineType || (engineTypes.length === 1 && engineType.toLowerCase() === engineTypes[0].toLowerCase());
};

const defaultCheckPersistedStateMatchWizardContext = (answers, context) =>
  [pagePaymentTypeMatchesTransactionType, checkEngineTypeMatches].every(checker => checker(answers, context));

export const checkPersistedWithZipCodeMatchWizardContext = (answers, context) =>
  checkZipCodeMatches(answers, context) && defaultCheckPersistedStateMatchWizardContext(answers, context);

export const checkPersistedWithStateCodeMatchWizardContext = (answers, context) =>
  checkStateCodeMatches(answers, context) && defaultCheckPersistedStateMatchWizardContext(answers, context);

export const isPersistedWizardStateApplicable = (
  { wizardState, incentives, zipCode, paymentType, inventoryType, engineTypes, stateCode },
  checkPersistedStateMatchWizardContext = checkPersistedWithZipCodeMatchWizardContext
) => {
  const { incentives: persistedIncentives, eligibleIncentiveIds, savingDate, answers } = wizardState;
  const supportedAvailableIncentives = incentives.filter(
    incentive => isFederalRebate(incentive) || isLocalRebate(incentive) || isOEMRebate(incentive)
  );

  if (!(savingDate && persistedIncentives && eligibleIncentiveIds && answers) || !supportedAvailableIncentives.length) {
    return false;
  }

  if (
    persistedIncentives.length !== supportedAvailableIncentives.length &&
    !checkPersistedStateMatchWizardContext(answers, {
      wizardState,
      paymentType,
      inventoryType,
      engineTypes,
      zipCode,
      stateCode,
    })
  ) {
    return false;
  }

  const availableIncentivesIds = supportedAvailableIncentives.map(incentive => [
    incentive.rebateOverridesByEligibility?.length
      ? incentive.rebateOverridesByEligibility?.map(override =>
          buildPersistedIncentiveId(incentive, override.eligibilityFactor?.id)
        )
      : [buildPersistedIncentiveId(incentive)],
    incentive.updateDate,
  ]);

  return availableIncentivesIds.every(
    ([complexAvailableIncentiveIds, updateDate]) =>
      createDate(updateDate) <= createDate(savingDate) &&
      complexAvailableIncentiveIds.some(complexId => persistedIncentives.includes(complexId))
  );
};
