import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from 'reactstrap/lib/Card';

import {
  IncentivesCreativeConfigDefaultProps,
  IncentivesCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  CommonAdDefaultProps,
  CommonAdPropTypes,
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { INCENTIVES_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';

import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'client/site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import {
  getDisplayHeadline,
  getTrackingValue,
} from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';

import { Styles } from './incentives-site-served-ad-styles';

const DEFAULT_HEADLINE = 'See Offers %%PATTERN:zip%%';

export function IncentivesSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  customTargeting,
  mobile,
  position,
  cardWrapper,
  horizontalLayout,
  noBorder,
  isAds10511Enabled,
  ads10512Recipe,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    ipZipCode,
    offersNumber,
    location,
    creativeConfigData: {
      logo,
      linkDisclaimer,
      disclaimerCopy,
      headline,
      headline2,
      linkDisplayUrl,
      cta,
      lineItemId,
      siteServedCreativeId,
      target,
      isConquest,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      const trackingValue = getTrackingValue(headline, offersNumber, zipCode, ipZipCode, DEFAULT_HEADLINE, 15, 15);
      adLoadTrackingListener(event, trackingValue);
    },
    [adLoadTrackingListener, headline, ipZipCode, offersNumber, zipCode]
  );

  if (!logo || !cta) {
    return null;
  }

  const displayHeadline = getDisplayHeadline(
    headline,
    offersNumber,
    zipCode,
    ipZipCode,
    DEFAULT_HEADLINE,
    15,
    15,
    location
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';
  const nativeStyle = INCENTIVES_AD.NATIVE_STYLE;

  const linkDisplay = (
    <div className="text-blue-40 text-center" style={{ ...Styles.mt_0_25, ...Styles.disclaimer }}>
      {linkDisplayUrl}
    </div>
  );

  const isTestChal = isAds10511Enabled || ['chal1', 'chal2'].includes(ads10512Recipe);
  const isAds10512Chal1 = ads10512Recipe === 'chal1';
  const isAds10512Chal2 = ads10512Recipe === 'chal2';
  const showChalOutlinedCta = isAds10511Enabled || (mobile && isAds10512Chal1) || isAds10512Chal2;
  const showChalSolidCta = !mobile && isAds10512Chal1;
  const showNoBorder = noBorder || showChalOutlinedCta;

  const cardStyleTest = showChalSolidCta ? { borderRadius: '8px' } : { overflow: 'hidden' };
  const style = showNoBorder ? { border: 'none' } : cardStyleTest;

  const conquestRibbonStyle = { ...Styles.conquestRibbon, marginLeft: showNoBorder ? '-16px' : 0 };
  const conquestRibbon = <ConquestRibbon style={conquestRibbonStyle} />;
  const mobileHeadlineStyles = showChalOutlinedCta ? { marginBottom: '0.5rem' } : Styles.headline_mobile;
  const ctaStyles = { ...Styles.fontWeightNormal, ...Styles.py_0_75 };
  const oemLogoStyles = showChalSolidCta ? { marginTop: '8px' } : Styles.my_0_25;

  return (
    <Card
      className={classnames('incentives-site-served pos-r w-100', cardWrapper)}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
      innerRef={adRef}
      style={style}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={nativeStyle}
        position={position}
        customTargeting={customTargeting}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
      />
      {adTrackers}
      <a
        className="site-served-ad-link no-focus text-decoration-none d-block"
        href={ctaURL}
        target={ctaTarget}
        rel="noopener noreferrer"
        onClick={adClickTrackingListener}
      >
        {!showNoBorder && <AdLabel />}
        <div
          className={classnames('w-100', {
            'd-flex justify-content-between': showNoBorder,
            'align-items-center mb-0_5': noBorder,
            'align-items-top': showChalOutlinedCta,
          })}
          style={!showChalOutlinedCta ? oemLogoStyles : { marginBottom: '1rem' }}
        >
          {isConquest ? (
            conquestRibbon
          ) : (
            <img className={!showNoBorder ? 'ms-0_5' : null} style={Styles.oemLogo} alt="" src={logo} loading="lazy" />
          )}
          {showNoBorder && <div style={{ fontSize: '10px', textAlign: 'right' }}>Sponsored</div>}
        </div>
        <div className={classnames('d-flex justify-content-between', { 'flex-column': !horizontalLayout })}>
          {!showChalSolidCta ? (
            <Fragment>
              <div style={{ ...(showNoBorder ? {} : Styles.px_1_5) }}>
                <div
                  className={classnames('headline fw-bold', {
                    'mb-0_25': !showChalOutlinedCta,
                    'text-gray-darkest': !isTestChal,
                    'text-cool-gray-10': isTestChal,
                  })}
                  style={{ ...Styles.mb_0_25, ...(mobile ? mobileHeadlineStyles : Styles.headline) }}
                >
                  <ContentFragment>{displayHeadline}</ContentFragment>
                </div>
                <div
                  className={classnames('small', {
                    'text-gray-darker': !isTestChal,
                    'text-cool-gray-30': isTestChal,
                  })}
                  style={Styles.headline2}
                >
                  <ContentFragment>{headline2}</ContentFragment>
                </div>
              </div>
              <div
                style={{
                  ...Styles.alignSelfStart,
                  ...(showNoBorder ? {} : Styles.px_1_5),
                  ...(!horizontalLayout ? Styles.mt_0_75 : {}),
                  ...(linkDisplayUrl ? Styles.mb_0_5 : Styles.withoutLinkDisplayUrl[mobile]),
                  ...(showChalOutlinedCta ? { paddingTop: '0.5rem', marginBottom: '0.25rem' } : {}),
                }}
                className={classnames({
                  'w-100': showNoBorder,
                })}
              >
                <div
                  className={classnames('btn text-nowrap py-0_5 px-0_75 text-transform-none size-16', {
                    'btn-blue-50': !showNoBorder && !isConquest,
                    'btn-outline-blue-50': !noBorder && isConquest,
                    'btn-outline-blue-50 w-100': showNoBorder,
                  })}
                  style={
                    !showChalOutlinedCta
                      ? { ...ctaStyles }
                      : { ...ctaStyles, ...Styles.py_0_75, lineHeight: '36px', borderRadius: '8px' }
                  }
                >
                  <CtaButtonText text={cta} />
                </div>
                {linkDisplay}
              </div>
            </Fragment>
          ) : (
            <div style={{ marginLeft: '1.5rem', marginRight: '2rem', marginTop: '0.25rem' }} className="d-flex">
              <div style={{ flex: '1 1 auto' }}>
                <div
                  className="headline fw-bold mb-0_25 text-cool-gray-10"
                  style={{ ...Styles.mb_0_25, ...Styles.headline }}
                >
                  <ContentFragment>{displayHeadline}</ContentFragment>
                </div>
                <div className="text-cool-gray-30">
                  <ContentFragment>{headline2}</ContentFragment>
                </div>
              </div>
              <div style={{ marginTop: '0.25rem', marginBottom: disclaimerCopy ? '1.5rem' : '1.25rem' }}>
                <div
                  className="btn text-nowrap text-transform-none size-16 btn-blue-50"
                  style={{
                    ...ctaStyles,
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                  }}
                >
                  <CtaButtonText text={cta} />
                </div>
                {linkDisplay}
              </div>
            </div>
          )}
        </div>
      </a>
      {disclaimerCopy && (
        <Disclaimer
          linkDisclaimer={linkDisclaimer}
          disclaimerText={disclaimerCopy}
          buttonClasses={showChalSolidCta ? 'pos-a bottom-0' : null}
          buttonStyle={showChalSolidCta ? { right: '8px' } : null}
        />
      )}
    </Card>
  );
}

IncentivesSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  customTargeting: CommonAdPropTypes.customTargeting,
  creativeConfig: IncentivesCreativeConfigPropTypes,
  cardWrapper: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  noBorder: PropTypes.bool,
  vehicle: PropTypes.shape({}),
  isAds10511Enabled: PropTypes.bool,
  ads10512Recipe: PropTypes.string,
};

IncentivesSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  customTargeting: CommonAdDefaultProps.customTargeting,
  creativeConfig: IncentivesCreativeConfigDefaultProps,
  cardWrapper: null,
  horizontalLayout: false,
  noBorder: false,
  vehicle: {},
  isAds10511Enabled: false,
  ads10512Recipe: null,
};

export const IncentivesSiteServedAd = siteServedAdTrackingWrapper(IncentivesSiteServedAdUI);
