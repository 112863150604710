import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VALUE_INPUTS_NAMES } from 'site-modules/shared/components/range/range';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

import './range-inputs-panel.scss';

export function RangeInputsPanel(props) {
  const {
    hasTwoThumbs,
    maxValue,
    minValue,
    inputAddon,
    inputSize,
    labelAddon,
    showLabels,
    disableInputs,
    minOutputText,
    onInputBlur,
    onInputChange,
    onInputFocus,
    onInputKeyDown,
    rangeName,
  } = props;

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <Fragment>
        <div className={classnames('direct-input-group', inputSize)}>
          {hasTwoThumbs && showLabels && (
            <label htmlFor={`${VALUE_INPUTS_NAMES.MIN}-${rangeName}`} className="small mb-0_25">
              {`${labelAddon}Min`}
            </label>
          )}
          <InputGroup>
            {inputAddon && <InputGroupText className="size-16 pe-0 ps-0_5">{inputAddon}</InputGroupText>}
            <Input
              className="direct-value-input d-inline-block size-16 text-gray-darker px-0_5 py-0_5"
              id={`${VALUE_INPUTS_NAMES.MIN}-${rangeName}`}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={minValue}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onFocus={onInputFocus}
              onKeyDown={onInputKeyDown}
              disabled={disableInputs}
              name={VALUE_INPUTS_NAMES.MIN}
              aria-label={hasTwoThumbs ? `Min ${rangeName} value` : minOutputText}
            />
          </InputGroup>
        </div>
        {hasTwoThumbs && (
          <Fragment>
            {showLabels && <span className="small mt-1_25 mx-0_25">to</span>}
            <div className={classnames('direct-input-group', inputSize)}>
              {hasTwoThumbs && showLabels && (
                <label htmlFor={`${VALUE_INPUTS_NAMES.MAX}-${rangeName}`} className="small mb-0_25">
                  {`${labelAddon}Max`}
                </label>
              )}
              <InputGroup>
                {inputAddon && <InputGroupText className="size-16 pe-0 ps-0_5">{inputAddon}</InputGroupText>}
                <Input
                  className="direct-value-input d-inline-block size-16 text-gray-darker px-0_5 py-0_5"
                  id={`${VALUE_INPUTS_NAMES.MAX}-${rangeName}`}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={maxValue}
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  onFocus={onInputFocus}
                  onKeyDown={onInputKeyDown}
                  disabled={disableInputs}
                  name={VALUE_INPUTS_NAMES.MAX}
                  aria-label={`Max ${rangeName} value`}
                />
              </InputGroup>
            </div>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
}

RangeInputsPanel.propTypes = {
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasTwoThumbs: PropTypes.bool,
  showLabels: PropTypes.bool,
  labelAddon: PropTypes.string,
  inputAddon: PropTypes.string,
  inputSize: PropTypes.string,
  disableInputs: PropTypes.bool,
  minOutputText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rangeName: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputKeyDown: PropTypes.func.isRequired,
};

RangeInputsPanel.defaultProps = {
  hasTwoThumbs: false,
  showLabels: false,
  labelAddon: '',
  inputAddon: null,
  inputSize: '',
  disableInputs: false,
  minOutputText: undefined,
};
