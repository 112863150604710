import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import Sticky from 'react-stickynode';

/* Components */
import { FilterButton } from 'site-modules/shared/components/inventory/filters-button/filters-button';
import { SemanticSearch } from 'site-modules/shared/components/inventory/semantic-search/semantic-search';

import './srp-query-input.scss';

const INPUT_HEADER = 'Describe what you’re looking for';
const BADGE_COPY = 'BETA';

export function SrpQueryInput({
  onFiltersBtnClick,
  activeFiltersCount,
  isMobile,
  YMMTPlaceholder,
  dynamicPlaceholderSuggestions,
  dealerId,
  disableLlm,
}) {
  const [isSticky, setIsSticky] = useState(false);

  const handleStateChange = useCallback(({ status }) => {
    setIsSticky(status === Sticky.STATUS_FIXED);
  }, []);

  return (
    <div
      className={classnames({
        'srp-query-input bg-white px-1 pt-1 pb-1 pb-md-0_5': !disableLlm || (disableLlm && isMobile),
        'pt-1': disableLlm && !isMobile,
      })}
    >
      {!disableLlm && (
        <>
          <div className="mb-0_5 d-flex">
            <h2 className="text-cool-gray-10 fw-bold mb-0 size-20">{INPUT_HEADER}</h2>
            <div
              className={classnames(
                'new-feature-badge fw-medium size-12 ms-0_5 d-flex justify-content-center align-items-center bg-cool-gray-90'
              )}
            >
              <span className="icon-sparkles icon text-yellow-20 me-0_25" aria-hidden />
              <span>{BADGE_COPY}</span>
            </div>
          </div>
          <Sticky enabled={!isMobile} activeClass="sticky-form" onStateChange={handleStateChange}>
            <div className={classnames({ container: isSticky })}>
              <SemanticSearch
                isMobile={isMobile}
                YMMTPlaceholder={YMMTPlaceholder}
                dynamicPlaceholderSuggestions={dynamicPlaceholderSuggestions}
                isSticky={isSticky}
                onFiltersBtnClick={onFiltersBtnClick}
                dealerId={dealerId}
                autosizeOnFocus
              />
            </div>
          </Sticky>
        </>
      )}
      <FilterButton
        onFiltersBtnClick={onFiltersBtnClick}
        activeFiltersCount={activeFiltersCount}
        classes="d-lg-none w-100 justify-content-center my-md-0_5"
      />
    </div>
  );
}

SrpQueryInput.propTypes = {
  onFiltersBtnClick: PropTypes.func,
  activeFiltersCount: PropTypes.number,
  YMMTPlaceholder: PropTypes.string,
  dynamicPlaceholderSuggestions: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  dealerId: PropTypes.string,
  disableLlm: PropTypes.bool,
};

SrpQueryInput.defaultProps = {
  onFiltersBtnClick: noop,
  activeFiltersCount: 0,
  YMMTPlaceholder: '',
  dynamicPlaceholderSuggestions: [],
  isMobile: false,
  dealerId: undefined,
  disableLlm: false,
};
