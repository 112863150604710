import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';

const YEARS = { 2021: true, 2020: true, 2019: true };
export const CPO_CARS = {
  hyundai: { elantra: YEARS, sonata: YEARS, tucson: YEARS, palisade: YEARS, 'santa-fe': YEARS },
  toyota: {
    86: YEARS,
    '4runner': YEARS,
    avalon: YEARS,
    'avalon-hybrid': YEARS,
    camry: YEARS,
    'camry-hybrid': YEARS,
    'c-hr': YEARS,
    corolla: YEARS,
    'corolla-hybrid': YEARS,
    'corolla-hatchback': YEARS,
    'gr-supra': YEARS,
    highlander: YEARS,
    'highlander-hybrid': YEARS,
    'land-cruiser': YEARS,
    mirai: YEARS,
    prius: YEARS,
    'prius-prime': YEARS,
    rav4: YEARS,
    'rav4-hybrid': YEARS,
    'rav4-prime': YEARS,
    sequoia: YEARS,
    sienna: YEARS,
    tacoma: YEARS,
    tundra: YEARS,
    venza: YEARS,
    yaris: YEARS,
    'yaris-hatchback': YEARS,
  },
};

export function isCpoMessageMake(make) {
  return ['mercedes-benz', 'hyundai', 'toyota'].includes(makeNiceName(make));
}

export function getCpoMakeName(facets) {
  return (facets?.make?.length === 1 && facets.make[0]) || null;
}

export function checkCpoMessage(facets) {
  const make = getCpoMakeName(facets);
  const type = (facets?.inventoryType?.length === 1 && facets.inventoryType[0]) || '';
  const isCpoSRP = isCpo(type);
  return isCpoSRP && isCpoMessageMake(make);
}
