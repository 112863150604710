const SPLITTER = ':';
const SUBTYPE_GRANTOR_UNION_SYMBOL = '_';

const splitSubtypeGrantor = subtypeGrantor => {
  const [subtypeId, encodedGrantor] = subtypeGrantor.split(SUBTYPE_GRANTOR_UNION_SYMBOL);

  return [subtypeId, decodeURIComponent(encodedGrantor)];
};

export const parsePersistedIncentiveId = (complexIncentiveId, splitter = SPLITTER) => {
  const [subtypeGrantor, eligibilityFactorId] = complexIncentiveId.split(splitter);
  const [subtypeId, grantor] = splitSubtypeGrantor(subtypeGrantor);
  return { subtypeId: Number(subtypeId), grantor, eligibilityFactorId };
};

export const buildPersistedIncentiveId = (incentive, eligibilityFactorId = '', splitter = SPLITTER) => {
  const { subtypeId, grantor } = incentive;

  const incentiveId = `${subtypeId}${SUBTYPE_GRANTOR_UNION_SYMBOL}${encodeURIComponent(grantor)}`;

  return `${incentiveId}${splitter}${eligibilityFactorId}`;
};
