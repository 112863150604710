import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual, noop } from 'lodash';
import {
  IncentivesWizardEntities,
  IncentivesWizardModel,
  IncentivesWizardPaths,
} from 'client/data/models/incentives-wizard';
import { WithPersistedWizardState } from 'site-modules/shared/components/incentives/incentives-wizard/utils/with-persisted-wizard-state';
import { usePrevious } from 'site-modules/shared/hooks/use-previous';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';

const IncentivesWizardWidgetUI = props => {
  const {
    availableIncentives,
    children,
    zipCode,
    stateCode,
    inventoryType,
    paymentType,
    setModelValue,
    engineTypes,
    evaluatePersistedStateMatchWizardContext,
  } = props;
  const [incentives, setIncentives] = useState(availableIncentives);
  const availableIncentivesIds = useMemo(() => availableIncentives.map(incentive => incentive.id), [
    availableIncentives,
  ]);
  const prevAvailableIncentivesIds = usePrevious(availableIncentivesIds);

  useEffect(() => {
    if (!isEqual(prevAvailableIncentivesIds, availableIncentivesIds)) {
      setIncentives(availableIncentives);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableIncentives]);

  const handleFinish = useCallback((totalAmount, { incentives: wizardIncentives }) => {
    setIncentives(wizardIncentives);
  }, []);

  const handleStartOver = useCallback(() => {
    setIncentives(availableIncentives);
    setModelValue(IncentivesWizardPaths.getIncentivesWizardData(), IncentivesWizardModel, availableIncentives);
  }, [availableIncentives, setModelValue]);

  return (
    <WithPersistedWizardState
      incentives={incentives}
      zipCode={zipCode}
      stateCode={stateCode}
      inventoryType={inventoryType}
      paymentType={paymentType}
      engineTypes={engineTypes}
      evaluatePersistedStateMatchWizardContext={evaluatePersistedStateMatchWizardContext}
    >
      {wizardState =>
        children({
          incentives: wizardState?.incentives || incentives,
          wizardState,
          onFinish: handleFinish,
          onStartOver: handleStartOver,
        })
      }
    </WithPersistedWizardState>
  );
};

IncentivesWizardWidgetUI.propTypes = {
  availableIncentives: IncentivesWizardEntities.IncentivesData.isRequired,
  children: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
  stateCode: PropTypes.string,
  paymentType: PropTypes.string.isRequired,
  inventoryType: PropTypes.string.isRequired,
  setModelValue: PropTypes.func,
  engineTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  evaluatePersistedStateMatchWizardContext: PropTypes.func,
};

IncentivesWizardWidgetUI.defaultProps = {
  setModelValue: noop,
  evaluatePersistedStateMatchWizardContext: undefined,
  stateCode: undefined,
};

export const IncentivesWizardWidget = connectToModel(IncentivesWizardWidgetUI, {});
