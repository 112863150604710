import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uniqBy } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildPopularStylesPath, VehicleModel } from 'client/data/models/vehicle-v2';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getTrimsComparisonUrl } from 'site-modules/shared/utils/core-link-constructor';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';

export function TrimsComparatorLinkUI({
  className,
  vehicle: {
    make: { slug: makeSlug, name: makeName },
    model: { slug: modelSlug, name: modelName },
    year,
  },
  hasTrims,
  isMobile,
}) {
  if (!hasTrims) {
    return null;
  }

  return (
    <div className={classnames('trims-comparator-link rounded-12 bg-cool-gray-90 px-1 py-1', className)}>
      <ShoppingLink
        to={getTrimsComparisonUrl({ makeSlug, modelSlug, year })}
        label={`Compare ${year} ${makeName} ${modelName} trim levels`}
        description="Helpful trims summary and side-by-side comparison chart"
        descriptionClassName={classnames({ 'mt-0_75': isMobile })}
        iconClassName="icon-compare_cars"
        className="bg-white rounded-8 px-1 py-1 py-md-1_25 ps-md-1_5 pe-md-2"
        labelClassName="heading-5 mb-0_25"
        iconContainerClassName="me-0_75 me-md-1_5 size-20 text-white bg-blue-50"
        iconStyles={{ height: '48px', width: '48px' }}
        arrowClassName="icon-arrow-right3 size-16 text-primary-darker"
        isDescriptionBelow={isMobile}
        data-tracking-id="compare_trims"
        data-tracking-value="Compare trim levels"
      />
    </div>
  );
}

TrimsComparatorLinkUI.propTypes = {
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
  hasTrims: PropTypes.bool,
  isMobile: PropTypes.bool,
};

TrimsComparatorLinkUI.defaultProps = {
  className: null,
  hasTrims: false,
  isMobile: false,
};

export const TrimsComparatorLink = connectToModel(TrimsComparatorLinkUI, {
  hasTrims: bindToPath(
    ({ vehicle }) => buildPopularStylesPath(getParamsFromVehicle(vehicle)),
    VehicleModel,
    styles => uniqBy(styles, 'trim')?.length > 1
  ),
});
