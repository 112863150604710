import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, merge } from 'lodash';
/* Constants */
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { CARD_VIEW, LEAD_FORM_VIEWS, THANK_YOU_VIEWS } from 'site-modules/shared/constants/lead-form/lead-form-params';
import { DELIVERY_CODES } from 'site-modules/shared/components/delivery/constants';
/* Components */
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
import { sendUsedInventoryMarketoThankYouEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
/* Utils */
import { getDealCheckTags } from 'site-modules/shared/utils/lead-form/tags';
import { isInstockOnline } from 'site-modules/shared/utils/dealer-details-utils';
import { getCarZingTags } from 'site-modules/shared/components/inventory/utils/carzing';
import { getMultiDigitalRetailLeadFormPropsCommon } from './multi-digital-retail-offer-type-lead-form-config-utils';

function getTags({ isUsedSrpDealCheck, isPromo, partnerCode }) {
  const defaultTags = isUsedSrpDealCheck ? null : getDealCheckTags({ isPromo });
  return getCarZingTags(partnerCode) || defaultTags;
}

export function MultiDigitalRetailOfferTypeLeadFormSrpUsedUI({ params, options, pageName, visitorId, isUserSetZip }) {
  const { distance, deliveryCode, zipCode, radius, isUsedSrpDealCheck, partnerCode } = params;
  const { isPromo } = options;

  const config = LEAD_FORM_CONFIGS.UNIFIED;
  const isDeliveryAvailable = DELIVERY_CODES.includes(deliveryCode) && !!zipCode;
  const isInStockOnline = isInstockOnline({ distance, radius, isDeliveryAvailable });
  const postProcessMethods = sendUsedInventoryMarketoThankYouEmail;

  const usedFormProps = {
    customConfig: {
      tags: getTags({ isUsedSrpDealCheck, isPromo, partnerCode }),
      leadFormComponent: LEAD_FORM_VIEWS.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_USED,
      leadFormConfig: {
        cardView: CARD_VIEW.DEALER_VIN_SRP_USED,
        isUsedSrpDealCheck,
        isPriceChecker: !isUsedSrpDealCheck,
        isInStockOnline,
      },
      thankYouComponent: isUsedSrpDealCheck
        ? THANK_YOU_VIEWS.DEAL_CHECK_AVAILABILITY
        : THANK_YOU_VIEWS.SRP_PRICE_CHECKER,
      thankYouConfig: {},
    },
    config: {
      ...config,
    },
  };

  const formProps = merge(
    getMultiDigitalRetailLeadFormPropsCommon({
      params,
      options,
      visitorId,
      pageName,
      postProcessMethods,
      isNewVin: false,
      isUserSetZip,
    }),
    usedFormProps
  );

  return (
    <Fragment>
      <UnifiedLeadFormAsync {...formProps} />
    </Fragment>
  );
}

MultiDigitalRetailOfferTypeLeadFormSrpUsedUI.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  pageName: PropTypes.string,
  visitorId: PropTypes.string,
  isUserSetZip: PropTypes.bool,
};

MultiDigitalRetailOfferTypeLeadFormSrpUsedUI.defaultProps = {
  pageName: '',
  visitorId: null,
  isUserSetZip: false,
};

export const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  visitorId: get(state, 'visitor.id'),
  isUserSetZip: !!get(state, 'visitor.location.userSet'),
});

export const MultiDigitalRetailOfferTypeLeadFormSrpUsed = connect(mapStateToProps)(
  MultiDigitalRetailOfferTypeLeadFormSrpUsedUI
);
