import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DFPManager } from '@edmunds/react-dfp';

import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { isRenderedAdUnit } from 'client/utils/ads';

import { CommonAdDefaultProps, CommonAdPropTypes } from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { getPwtEnabled } from 'client/utils/pwt/get-pwt-enabled-page';

import { collapseEmptyCarouselAdSlot } from './filmstrip-carousel/utils/filmstrip-carousel-utils';
import { FilmstripCarousel } from './filmstrip-carousel/filmstrip-carousel';

import './sponsored-cars.scss';

const MAX_AD_COUNT = 4;
const CREATIVE_ID = 'edm-entry-also-viewed-vehicles-sponsored-cars';

function SponsoredCarsUI({
  isMobile,
  wrapperClassName,
  showTopDividingLine,
  showBottomDividingLine,
  showLargeArrows,
  pwtEnabled,
  verticalOffset,
  adsCustomTargeting,
  trackLoading,
  inlineStyle,
}) {
  const containerRef = useRef();
  const totalAdCalls = useRef(0);
  const totalAdsLoaded = useRef(0);
  const totalAdCallsRequested = useRef(0);
  const adCallsRequested = useRef([]);

  const [showFilmstrip, setShowFilmstrip] = useState(false);

  const handleSlotRenderEnd = useCallback(
    slotObj => {
      const isLoaded = isRenderedAdUnit(slotObj);

      totalAdCalls.current += 1;
      if (isLoaded) {
        totalAdsLoaded.current += 1;
      }

      if (totalAdCalls.current === MAX_AD_COUNT) {
        if (trackLoading) {
          EventToolbox.fireTrackAction({
            event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
            event_data: {
              action_name: TrackingConstant.ACTION_SHOW_CONTENT,
              action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
              subaction_name: 'sponsored_cars_displayed',
              creative_id: CREATIVE_ID,
              value: !!totalAdsLoaded.current,
            },
          });
        }
        setShowFilmstrip(totalAdsLoaded.current > 0);
        totalAdCalls.current = 0;
        totalAdsLoaded.current = 0;
        collapseEmptyCarouselAdSlot(containerRef.current, '.slick-slide');
      }
    },
    [trackLoading]
  );

  const onSlotRegister = useCallback(({ slotId }) => {
    adCallsRequested.current.push(slotId);
    totalAdCallsRequested.current += 1;
    // window.PWT?.adsPwtLoaded - see client/utils/pwt/get-pwt-bids.js
    if (totalAdCallsRequested.current === MAX_AD_COUNT && window.PWT?.adsPwtLoaded) {
      setTimeout(() => {
        DFPManager.refresh(...adCallsRequested.current);
      }, 100);
    }
  }, []);

  const pwtProps = pwtEnabled
    ? {
        skipPwtRefresh: true,
        onSlotRegister,
      }
    : {};

  const sponsoredContent = (
    <div
      className={classNames('sponsored-cars', wrapperClassName, {
        'invisible d-none': !showFilmstrip,
        'is-wired': !isMobile,
        'is-mobile': isMobile,
        'show-large-arrows': showLargeArrows,
      })}
      data-tracking-parent={CREATIVE_ID}
      ref={containerRef}
      style={inlineStyle}
    >
      {showTopDividingLine && <hr className="mb-3_5" />}
      <h2 className="heading-3 mb-1_25 mb-md-2 text-start">Consider these recommendations</h2>

      <FilmstripCarousel noAdsRefresh totalAdCount={MAX_AD_COUNT} showArrows={showFilmstrip}>
        {new Array(MAX_AD_COUNT).fill().map((_, index) => (
          <div key={`native-ad-${index + 1}`} className="slick-slide" data-index={index}>
            <NativeAd
              nativeStyle="filmstrip"
              position={`${index + 1}`}
              slotRenderEndListener={handleSlotRenderEnd}
              customTargeting={adsCustomTargeting}
              {...pwtProps}
            />
          </div>
        ))}
      </FilmstripCarousel>
      {showBottomDividingLine && <hr className="mt-2 mb-3_5" />}
    </div>
  );

  return (
    <RenderWhenViewable verticalOffset={verticalOffset} placeholder={<div className="sponsored-cars" />}>
      {sponsoredContent}
    </RenderWhenViewable>
  );
}

SponsoredCarsUI.propTypes = {
  adsCustomTargeting: CommonAdPropTypes.customTargeting,
  isMobile: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  showTopDividingLine: PropTypes.bool,
  showBottomDividingLine: PropTypes.bool,
  showLargeArrows: PropTypes.bool,
  pwtEnabled: PropTypes.bool,
  trackLoading: PropTypes.bool,
  verticalOffset: PropTypes.string,
  inlineStyle: PropTypes.shape({}),
};

SponsoredCarsUI.defaultProps = {
  adsCustomTargeting: CommonAdDefaultProps.customTargeting,
  isMobile: false,
  wrapperClassName: '',
  showTopDividingLine: false,
  showBottomDividingLine: true,
  showLargeArrows: false,
  pwtEnabled: false,
  trackLoading: false,
  verticalOffset: '30%',
  inlineStyle: null,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  pwtEnabled: getPwtEnabled(state),
});

export const SponsoredCars = connect(mapStateToProps)(SponsoredCarsUI);
