import React, { useCallback, useEffect, useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { noop } from 'lodash';
import { PopoverContent } from 'site-modules/shared/components/popover/popover-content';
import { useClickOutside } from 'site-modules/shared/hooks/use-click-outside';
import { SIDE } from 'site-modules/shared/components/popover/use-dynamic-alignment';

import './popover.scss';

export const Popover = props => {
  const {
    renderTarget,
    role,
    children,
    isOpen: isOpenProp,
    targetAlignment,
    className,
    dialogAriaLabel,
    creativeId,
    trackingId,
    onToggle,
    dialogClassName,
  } = props;
  const targetRef = useRef();
  const ref = useRef();
  const dialogRef = useRef();

  const [isOpen, setIsOpen] = useState(isOpenProp);
  const dialogId = useId();

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  const handleTargetClick = useCallback(() => {
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  }, [isOpen, onToggle]);

  useClickOutside(ref, handleTargetClick, { isEnabled: isOpen });

  return (
    <div
      ref={ref}
      className={classnames('pos-r edm-popover', className)}
      data-tracking-id={trackingId}
      data-tracking-parent={creativeId}
    >
      {renderTarget({
        ref: targetRef,
        onClick: handleTargetClick,
        'aria-expanded': isOpen,
        'aria-haspopup': true,
        'aria-controls': dialogId,
      })}
      {
        <CSSTransition in={isOpen} timeout={200} unmountOnExit classNames="edm-popover-dialog" nodeRef={dialogRef}>
          <PopoverContent
            role={role}
            id={dialogId}
            ref={dialogRef}
            ariaLabel={dialogAriaLabel}
            className={classnames('edm-popover-dialog', dialogClassName)}
            targetAlignment={targetAlignment}
          >
            {children}
          </PopoverContent>
        </CSSTransition>
      }
    </div>
  );
};

Popover.propTypes = {
  renderTarget: PropTypes.func.isRequired,
  role: PropTypes.string,
  targetAlignment: PropTypes.oneOf(Object.values(SIDE)),
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dialogAriaLabel: PropTypes.string,
  trackingId: PropTypes.string,
  creativeId: PropTypes.string,
  onToggle: PropTypes.func,
  dialogClassName: PropTypes.string,
};

Popover.defaultProps = {
  role: 'dialog',
  targetAlignment: SIDE.LEFT,
  isOpen: false,
  className: undefined,
  dialogAriaLabel: undefined,
  creativeId: undefined,
  trackingId: undefined,
  onToggle: noop,
  dialogClassName: undefined,
};
