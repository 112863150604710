import React from 'react';
import PropTypes from 'prop-types';

export function CpoLabel({ cpoData }) {
  const { oemLogo } = cpoData;
  return (
    <div
      className="cpo-label d-flex align-items-center rounded bg-blue-100 pos-a"
      style={{ top: '8px', left: '8px', zIndex: 3 }}
    >
      {oemLogo && (
        <div className="px-0_25">
          <img alt="" src={oemLogo} style={{ maxHeight: '18px' }} />
        </div>
      )}
      <div className="small fw-bold text-gray-darekest px-0_25 py-0_25">Certified Pre-Owned</div>
    </div>
  );
}

CpoLabel.propTypes = {
  cpoData: PropTypes.shape({
    oemLogo: PropTypes.string,
  }).isRequired,
};
