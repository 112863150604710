import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, isUndefined } from 'lodash';

import './view-more.scss';

export const ViewMore = memo(props => {
  const {
    expandedSummary,
    collapsedSummary,
    className,
    children,
    open: openProp,
    onToggle,
    trackingId,
    trackingValue,
    ariaLabel,
    summaryContainerTag: SummaryContainerTag,
    summaryContainerClassName,
    btnIgnoreTrackingValue,
    noIcon,
    iconClasses,
  } = props;
  const [open, setOpen] = useState(isUndefined(openProp) ? false : openProp);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  const handleToggle = useCallback(
    event => {
      onToggle(!open);
      // Set local state only when component is uncontrolled
      if (isUndefined(openProp)) {
        setOpen(!open);
      }
      event.preventDefault();
    },
    [onToggle, open, openProp]
  );

  return (
    <details className="view-more" open={open}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <summary
        className={classnames('px-0 py-0 small text-primary-darker d-flex align-items-center size-16', className)}
        data-tracking-id={trackingId}
        data-tracking-value={trackingValue}
        onClick={handleToggle}
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
        {...(btnIgnoreTrackingValue ? { 'data-tracking-ignore-value': true } : {})}
      >
        <SummaryContainerTag className={classnames(summaryContainerClassName, { 'd-none': !open })}>
          {expandedSummary}
        </SummaryContainerTag>
        <SummaryContainerTag className={classnames(summaryContainerClassName, { 'd-none': open })}>
          {collapsedSummary}
        </SummaryContainerTag>
        {!noIcon && (
          <i
            className={classnames(iconClasses, 'ms-0_25', {
              'icon-arrow-down4': !open,
              'icon-arrow-up4': open,
            })}
            aria-hidden
          />
        )}
      </summary>
      {children}
    </details>
  );
});

ViewMore.propTypes = {
  expandedSummary: PropTypes.node.isRequired,
  collapsedSummary: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  summaryContainerTag: PropTypes.string,
  summaryContainerClassName: PropTypes.string,
  btnIgnoreTrackingValue: PropTypes.bool,
  noIcon: PropTypes.bool,
  iconClasses: PropTypes.string,
};

ViewMore.defaultProps = {
  className: '',
  trackingId: 'extra_links_show_all',
  trackingValue: '',
  ariaLabel: '',
  onToggle: noop,
  open: undefined,
  summaryContainerTag: 'span',
  summaryContainerClassName: null,
  btnIgnoreTrackingValue: false,
  noIcon: false,
  iconClasses: 'medium text-primary-darker',
};

ViewMore.displayName = 'ViewMore';
