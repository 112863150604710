import {
  EXTERIOR_COLOR,
  HISTORY_INFO,
  EXCLUDE_HISTORY_INFO,
  DEAL_TYPE,
  DELIVERY_TYPE,
  FEATURE,
  INTERIOR_COLOR,
  CYLINDERS_COUNT,
  TRANSMISSION,
  DRIVE_TRAIN,
  FUEL_TYPE,
  OPTION,
  SPECIAL_IDENTIFIER,
  TRIM,
  YEAR,
  MILEAGE,
  MAKE,
  MODEL,
  RADIUS,
  BODY_TYPE,
  VEHICLE_STYLE,
  INVENTORY_TYPE,
  DISPLAY_PRICE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  RECENTLY_LISTINGS,
  PRICE_DROP,
  COMBINE_MPG,
  ELECTRICITY_RANGE,
  TRUCK_CAB_SIZE,
  BED_LENGTH,
  DISPLACEMENT,
  REAR_WHEELS,
  ENGINE_TYPE,
  HOME_DELIVERY,
  COMFORT_CONVENIENCE,
  SAFETY,
  ENGINE_EXTERIOR,
  ENTERTAINMENT,
  PAYMENT_TYPE,
  WITH_PHOTO,
  WITH_PRICE,
  EXCLUDE_IN_TRANSIT,
  BATTERY_RANGE_SCORE,
  CREDIT_PROVIDER,
  TOTAL_SEATING,
  EDITORIAL_RATING,
  EDITORIAL_LABEL,
  SELF_DRIVING_LEVEL,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { EDITORIAL_RATING_TO_SHOW, FACETS_CONTROLS } from 'site-modules/shared/constants/inventory/facets-config';
import {
  LOCATION_GROUP,
  MM_GROUP,
  YEAR_GROUP,
  CONDITION_GROUP,
  PRICE_PAYMENT_GROUP,
  CREDIT_PROVIDER_GROUP,
  RATING_GROUP,
  MILEAGE_GROUP,
  HISTORY_GROUP,
  MODEL_TYPE_GROUP,
  TRIM_GROUP,
  MPG_GROUP,
  EXT_COLOR_GROUP,
  INT_COLOR_GROUP,
  ELECTRIC_DETAILS_GROUP,
  ENGINE_GROUP,
  FEATURES_GROUP,
  OPTIONS_GROUP,
  VEHICLE_LISTING_DETAILS_GROUP,
  TRUCK_DETAILS_GROUP,
  TOTAL_SEATING_GROUP,
  PRICE_PAYMENT_TABS_SUBGROUP,
  LISTING_DETAILS,
  EDITORIAL_RATING_GROUP,
} from 'site-modules/shared/constants/inventory/filters-constants';
import { EdmundsTested } from 'site-modules/shared/components/inventory/usurp-editorial-rating-filter/edmunds-tested';

export function getFacetCreativeId(str) {
  return `edm-entry-facets-${str}`;
}

const DELIVERY_SUBGROUP = 'delivery';

export const MILES = 'miles';
export const CURRENCY = 'currency';
export const MPG = 'MPG';
export const MI = 'mi';

export const GROUPS_KEYS = {
  LOCATION_GROUP,
  MM_GROUP,
  YEAR_GROUP,
  CONDITION_GROUP,
  PRICE_PAYMENT_GROUP,
  CREDIT_PROVIDER_GROUP,
  RATING_GROUP,
  MILEAGE_GROUP,
  HISTORY_GROUP,
  MODEL_TYPE_GROUP,
  TRIM_GROUP,
  MPG_GROUP,
  EXT_COLOR_GROUP,
  INT_COLOR_GROUP,
  ELECTRIC_DETAILS_GROUP,
  ENGINE_GROUP,
  FEATURES_GROUP,
  OPTIONS_GROUP,
  VEHICLE_LISTING_DETAILS_GROUP,
  TRUCK_DETAILS_GROUP,
  EDITORIAL_RATING_GROUP,
};

export const SUBGROUP_KEYS = {
  PRICE_PAYMENT_TABS_SUBGROUP,
};
export const selfDrivingNameMap = {
  'Level 3': 'Conditional Automation',
  'Level 4': 'High Automation',
  'Level 5': 'Full Automation',
};
export const selfDrivingTooltipMap = {
  'Level 3':
    'The vehicle self-driving system offers complete hands and feet-free operation, including management of all steering, braking and acceleration, when operating under certain conditions (e.g. "on approved lane-marked road up to 50 MPH"). Driver must be alert and ready to takeover all driving tasks, including steering, braking and acceleration, when the vehicle has encountered a driving scenario it can no longer handle autonomously. The vehicle self-driving system must be certified as Level 3 in at least one state within the United States.',
  'Level 4':
    'The vehicle self-driving system offers complete hands and feet-free operation, including management of all steering, braking and acceleration, when operating under certain specified conditions. If the vehicle encounters conditions that the self-driving system can no longer operate the vehicle under (e.g. extreme weather), the vehicle will autonomously implement safety measures. No driver intervention is required. The vehicle self-driving system must be certified as Level 4 in at least one state within the United States.',
  'Level 5':
    'The vehicle self-driving system offers complete hands and feet-free operation, including management of all steering, braking and acceleration, when operating under any and all conditions. No driver intervention is required. The vehicle self-driving system must be certified as Level 5 in at least one state within the United States.',
};

export const FACETS_CONFIG = {
  [MAKE]: {
    title: null,
    label: 'Search by Make',
    control: FACETS_CONTROLS.SELECT,
    group: MM_GROUP,
    indexInsideGroup: 0,
    toggle: 'Add Make',
    creativeId: getFacetCreativeId('make_and_model'),
  },
  [MODEL]: {
    title: null,
    label: 'Search by Model',
    control: FACETS_CONTROLS.SELECT,
    group: MM_GROUP,
    indexInsideGroup: 1,
    toggle: 'Add Model',
    creativeId: getFacetCreativeId('make_and_model'),
  },
  [YEAR]: {
    title: null,
    label: 'Year(s)',
    control: FACETS_CONTROLS.RANGE,
    creativeId: getFacetCreativeId('year'),
    shortName: 'year',
    allowEqualValues: true,
    step: 1,
    filterClassName: 'mb-2',
    group: YEAR_GROUP,
  },
  [INVENTORY_TYPE]: {
    title: null,
    creativeId: getFacetCreativeId('condition'),
    shortName: 'cond',
    control: FACETS_CONTROLS.CHECKBOX,
    group: CONDITION_GROUP,
  },
  [BODY_TYPE]: {
    title: 'Type',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('type'),
    group: MODEL_TYPE_GROUP,
    indexInsideGroup: 0,
    withoutCollapse: true,
  },
  [VEHICLE_STYLE]: {
    title: 'Style',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('type'),
    group: MODEL_TYPE_GROUP,
    indexInsideGroup: 1,
    withoutCollapse: true,
  },
  [EXTERIOR_COLOR]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('exterior_colors'),
    shortName: 'exte',
    group: EXT_COLOR_GROUP,
    withoutCollapse: true,
  },
  [HISTORY_INFO]: {
    title: null,
    group: HISTORY_GROUP,
    control: FACETS_CONTROLS.CHECKBOX,
    withoutCollapse: true,
  },
  [EXCLUDE_HISTORY_INFO]: {
    title: 'Exclude vehicles with',
    group: HISTORY_GROUP,
    control: FACETS_CONTROLS.CHECKBOX,
    withoutCollapse: true,
  },
  [DEAL_TYPE]: {
    title: null,
    subtitle: {
      subtitleText: "Edmunds' ratings are backed by 15+ years of pricing research done by our data scientists",
      subtitleTooltip: {
        title: 'How are ratings determined?',
        text:
          'We analyze millions of data points to generate our ratings. We look at vehicle configuration, options, history, nearby transactions, market pricing and more — the ratings are up-to-date and relevant to your search area.',
      },
    },
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('edmunds_deal_rating'),
    shortName: 'deal',
    group: RATING_GROUP,
    labelPostfix: 'Price',
  },
  [EDITORIAL_RATING]: {
    title: 'Vehicle Rating',
    subtitle: {
      subtitleText: 'We drove it! Our ratings are based on our Edmunds expert’s extensive vehicle testing.',
      subtitleClassNames: 'mb-0_75',
    },
    control: FACETS_CONTROLS.CHECKBOX,
    group: EDITORIAL_RATING_GROUP,
    indexInsideGroup: 0,
    itemsToShow: EDITORIAL_RATING_TO_SHOW,
    hideWhenAllValuesEmpty: true,
  },
  [EDITORIAL_LABEL]: {
    title: 'Expert Opinions',
    isSmallTitle: true,
    control: FACETS_CONTROLS.CHECKBOX,
    group: EDITORIAL_RATING_GROUP,
    indexInsideGroup: 1,
    maxOptionsBeforeCollapse: 4,
    creativeId: getFacetCreativeId('opinions'),
    noWidgetView: true,
    excludeZeroCount: true,
    hideWhenAllValuesEmpty: true,
    countClasses: '',
  },
  [FEATURE]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('features'),
    shortName: 'feat',
    group: FEATURES_GROUP,
    maxOptionsBeforeCollapse: 3,
  },
  [TOTAL_SEATING]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('seating'),
    shortName: 'seat',
    group: TOTAL_SEATING_GROUP,
    excludeZeroCount: true,
    withoutCollapse: true,
  },
  [SELF_DRIVING_LEVEL]: {
    title: 'Self-Driving',
    control: FACETS_CONTROLS.CHECKBOX,
    group: FEATURES_GROUP,
    indexInsideGroup: 0,
    maxOptionsBeforeCollapse: 3,
    getDisplayName(facet) {
      return selfDrivingNameMap[facet.name];
    },
    getValueName(facet) {
      return selfDrivingNameMap[facet.name];
    },
    getValueTooltip(facet) {
      return selfDrivingTooltipMap[facet.name];
    },
  },
  [COMFORT_CONVENIENCE]: {
    title: 'Comfort & Convenience',
    control: FACETS_CONTROLS.CHECKBOX,
    group: FEATURES_GROUP,
    indexInsideGroup: 1,
    maxOptionsBeforeCollapse: 3,
  },
  [SAFETY]: {
    title: 'Safety',
    control: FACETS_CONTROLS.CHECKBOX,
    group: FEATURES_GROUP,
    indexInsideGroup: 2,
    maxOptionsBeforeCollapse: 3,
  },
  [ENGINE_EXTERIOR]: {
    title: 'Engine & Exterior',
    control: FACETS_CONTROLS.CHECKBOX,
    group: FEATURES_GROUP,
    indexInsideGroup: 3,
    maxOptionsBeforeCollapse: 3,
  },
  [ENTERTAINMENT]: {
    title: 'Entertainment',
    control: FACETS_CONTROLS.CHECKBOX,
    group: FEATURES_GROUP,
    indexInsideGroup: 4,
    maxOptionsBeforeCollapse: 3,
  },
  [INTERIOR_COLOR]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('interior_colors'),
    shortName: 'inte',
    group: INT_COLOR_GROUP,
    getDisplayName(facet) {
      return `${facet.name} Interior`;
    },
    withoutCollapse: true,
  },
  [ELECTRICITY_RANGE]: {
    title: 'EPA Range',
    tooltip: {
      id: 'epa-range-explanation',
      text:
        'For new vehicles, this is the distance you can expect to travel on a full charge. EPA advises this range should be used for comparison purposes only. Actual range will vary depending on the condition of this vehicle’s battery pack, how you drive, driving conditions and other factors.',
    },
    control: FACETS_CONTROLS.RANGE,
    creativeId: getFacetCreativeId('electricityRange'),
    shortName: 'electric',
    group: ELECTRIC_DETAILS_GROUP,
    labelType: MI,
    labelPostfix: ` ${MI}`,
    indexInsideGroup: 0,
    step: 1,
  },
  [BATTERY_RANGE_SCORE]: {
    title: 'Battery Range Score',
    tooltip: {
      id: 'battery-range-explanation',
      text:
        'Range Score is based on this vehicle’s current Estimated Range and Recurrent Motors’ comparison of that Estimated Range with its estimated range when new.',
    },
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('battery_score'),
    group: ELECTRIC_DETAILS_GROUP,
    indexInsideGroup: 1,
    getDisplayName(facet) {
      return `${facet.name} Range Score`;
    },
    getPixelDisplayName(facet) {
      return `${facet.name} Range Score`;
    },
  },
  [CYLINDERS_COUNT]: {
    title: 'Cylinders',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_GROUP,
    indexInsideGroup: 3,
    maxOptionsBeforeCollapse: 3,
    withoutCollapse: true,
  },
  [TRANSMISSION]: {
    title: 'Transmission',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_GROUP,
    indexInsideGroup: 0,
    maxOptionsBeforeCollapse: 3,
    withoutCollapse: true,
  },
  [DRIVE_TRAIN]: {
    title: 'Drivetrain',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_GROUP,
    indexInsideGroup: 2,
    maxOptionsBeforeCollapse: 3,
    withoutCollapse: true,
  },
  [FUEL_TYPE]: {
    title: 'Fuel Type',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_GROUP,
    indexInsideGroup: 4,
    maxOptionsBeforeCollapse: 3,
    withoutCollapse: true,
  },
  [ENGINE_TYPE]: {
    title: 'Engine Type',
    control: FACETS_CONTROLS.CHECKBOX,
    group: ENGINE_GROUP,
    indexInsideGroup: 1,
    maxOptionsBeforeCollapse: 3,
    withoutCollapse: true,
  },
  [OPTION]: {
    title: 'Options',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('options_and_packages'),
    group: OPTIONS_GROUP,
    indexInsideGroup: 0,
    maxOptionsBeforeCollapse: 3,
  },
  [SPECIAL_IDENTIFIER]: {
    title: 'Trim Packages',
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('options_and_packages'),
    group: OPTIONS_GROUP,
    indexInsideGroup: 1,
    maxOptionsBeforeCollapse: 3,
  },
  [TRIM]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    creativeId: getFacetCreativeId('trim'),
    shortName: 'trim',
    group: TRIM_GROUP,
    withoutCollapse: true,
  },
  [MILEAGE]: {
    title: null,
    label: 'Mileage',
    control: FACETS_CONTROLS.RANGE,
    creativeId: getFacetCreativeId('mileage'),
    shortName: 'mile',
    group: MILEAGE_GROUP,
    appendZeroOption: true,
    labelType: MILES,
    step: 1000,
    filterClassName: 'mb-2',
  },
  [DISPLAY_PRICE]: {
    title: 'Price',
    control: FACETS_CONTROLS.RANGE,
    group: PRICE_PAYMENT_GROUP,
    subgroup: PRICE_PAYMENT_TABS_SUBGROUP,
    appendZeroOption: true,
    labelType: CURRENCY,
    creativeId: getFacetCreativeId('price_and_payments'),
    step: 1000,
    filterClassName: 'mb-2',
    filterOnSelect: { type: PAYMENT_TYPE, value: '' },
    dataTrackingId: 'view_purchase_pricing',
    withHistogram: true,
  },
  [LOAN_PAYMENT]: {
    title: 'Loan',
    control: FACETS_CONTROLS.RANGE,
    group: PRICE_PAYMENT_GROUP,
    subgroup: PRICE_PAYMENT_TABS_SUBGROUP,
    indexInsideGroup: 0,
    disclaimer: 'Assumes no money down and a 60-month term. Click a vehicle for more details.',
    prefix: 'Up to',
    postfix: 'per month',
    appendZeroOption: true,
    labelType: CURRENCY,
    creativeId: getFacetCreativeId('price_and_payments'),
    labelPostfix: '/mo',
    step: 10,
    filterClassName: 'mb-2',
    filterOnSelect: { type: PAYMENT_TYPE, value: 'loan' },
    dataTrackingId: 'view_loan_pricing',
    withHistogram: true,
  },
  [LEASE_PAYMENT]: {
    title: 'Lease',
    control: FACETS_CONTROLS.RANGE,
    group: PRICE_PAYMENT_GROUP,
    subgroup: PRICE_PAYMENT_TABS_SUBGROUP,
    indexInsideGroup: 0,
    disclaimer: 'Assumes $1K due at signing. Click a vehicle for more details.',
    prefix: 'Up to',
    postfix: 'per month',
    appendZeroOption: true,
    labelType: CURRENCY,
    creativeId: getFacetCreativeId('price_and_payments'),
    labelPostfix: '/mo',
    step: 10,
    filterClassName: 'mb-2',
    filterOnSelect: { type: PAYMENT_TYPE, value: 'lease' },
    dataTrackingId: 'view_lease_pricing',
    withHistogram: true,
  },
  [RADIUS]: {
    title: null,
    label: 'Search radius',
    control: FACETS_CONTROLS.RADIUS_SLIDER,
    group: LOCATION_GROUP,
    indexInsideGroup: 1,
    creativeId: getFacetCreativeId('zip_and_radius'),
    labelPostfix: 'mi',
    filterClassName: 'mb-2',
  },
  [DELIVERY_TYPE]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    creativeId: getFacetCreativeId('available_for_delivery'),
    shortName: 'avail',
    group: LOCATION_GROUP,
    indexInsideGroup: 1,
    withoutCollapse: true,
  },
  [HOME_DELIVERY]: {
    title: null,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    creativeId: getFacetCreativeId('available_for_delivery'),
    shortName: 'avail',
    group: LOCATION_GROUP,
    indexInsideGroup: 0,
    filterClassName: 'mb-0_75',
    withoutCollapse: true,
  },
  [RECENTLY_LISTINGS]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    indexInsideGroup: 0,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    filterClassName: 'mb-0_75',
    withoutCollapse: true,
  },
  [PRICE_DROP]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    indexInsideGroup: 1,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    filterClassName: 'mb-0_75',
    withoutCollapse: true,
  },
  [WITH_PHOTO]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    indexInsideGroup: 2,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    filterClassName: 'mb-0_75',
    withoutCollapse: true,
  },
  [WITH_PRICE]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    indexInsideGroup: 3,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    filterClassName: 'mb-0_75',
    withoutCollapse: true,
  },
  [EXCLUDE_IN_TRANSIT]: {
    group: VEHICLE_LISTING_DETAILS_GROUP,
    indexInsideGroup: 4,
    control: FACETS_CONTROLS.CHECKBOX,
    hideCount: true,
    filterClassName: 'mb-0_75',
    withoutCollapse: true,
  },
  [COMBINE_MPG]: {
    title: null,
    label: 'MPG',
    alternativeTitle: 'MPG/e',
    control: FACETS_CONTROLS.RANGE,
    creativeId: getFacetCreativeId('combinedMPG'),
    shortName: 'fuel',
    group: MPG_GROUP,
    labelType: MPG,
    labelPostfix: ` ${MPG}`,
    indexInsideGroup: 0,
    step: 1,
    filterClassName: 'mb-2',
  },
  [TRUCK_CAB_SIZE]: {
    title: 'Cabin Size',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
    indexInsideGroup: 0,
    withoutCollapse: true,
  },
  [BED_LENGTH]: {
    title: 'Bed Length',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
    indexInsideGroup: 1,
    withoutCollapse: true,
  },
  [DISPLACEMENT]: {
    title: 'Displacement',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
    indexInsideGroup: 2,
    maxOptionsBeforeCollapse: 3,
    withoutCollapse: true,
  },
  [REAR_WHEELS]: {
    title: 'Rear Wheels',
    control: FACETS_CONTROLS.CHECKBOX,
    group: TRUCK_DETAILS_GROUP,
    indexInsideGroup: 3,
    withoutCollapse: true,
  },
  [CREDIT_PROVIDER]: {
    title: null,
    creativeId: getFacetCreativeId('financing'),
    shortName: 'creditProvider',
    copy: 'Dealers that accept my prequalification',
    control: FACETS_CONTROLS.SWITCH,
    group: CREDIT_PROVIDER_GROUP,
  },
};

export const EXPANDED_FILTERS = [LOCATION_GROUP];

// When change order, please move value/key pair correspondingly for readability
export const FACETS_GROUPS_CONFIG = {
  [LOCATION_GROUP]: {
    title: 'Location',
    order: 0,
    creativeId: getFacetCreativeId('zip_and_radius'),
  },
  [MM_GROUP]: {
    title: 'Make & Model',
    creativeId: getFacetCreativeId('make_and_model'),
    shortName: 'make',
    order: 1,
  },
  [YEAR_GROUP]: {
    title: 'Year',
    order: 1,
  },
  [CONDITION_GROUP]: {
    title: 'Condition',
    order: 2,
  },
  [PRICE_PAYMENT_GROUP]: {
    title: 'Price & Payment',
    creativeId: getFacetCreativeId('price_and_payments'),
    shortName: 'pric',
    order: 3,
  },
  [CREDIT_PROVIDER_GROUP]: {
    title: 'Financing',
    creativeId: getFacetCreativeId('financing'),
    shortName: 'creditProvider',
    order: 4,
  },
  [RATING_GROUP]: {
    title: 'Price Rating',
    order: 5,
  },
  [MODEL_TYPE_GROUP]: {
    title: 'Body Type',
    withInnerDividers: true,
    order: 6,
  },
  [TRIM_GROUP]: {
    title: 'Trim',
    order: 7,
  },
  [TRUCK_DETAILS_GROUP]: {
    title: 'Truck Details',
    withInnerDividers: true,
    creativeId: getFacetCreativeId('truck_details'),
    shortName: 'truck',
    order: 8,
  },
  [MILEAGE_GROUP]: {
    title: 'Mileage',
    order: 9,
  },
  [HISTORY_GROUP]: {
    title: 'Vehicle History',
    creativeId: getFacetCreativeId('vehicle_history'),
    shortName: 'hist',
    order: 10,
  },
  [ELECTRIC_DETAILS_GROUP]: {
    title: 'Electric Vehicle Details',
    withInnerDividers: true,
    shortName: 'elec',
    order: 11,
  },
  [ENGINE_GROUP]: {
    title: 'Engine & Drivetrain',
    withInnerDividers: true,
    creativeId: getFacetCreativeId('engine_mechanics'),
    shortName: 'engi',
    order: 12,
  },
  [EXT_COLOR_GROUP]: {
    title: 'Exterior Color',
    order: 13,
  },
  [INT_COLOR_GROUP]: {
    title: 'Interior Color',
    order: 14,
  },
  [TOTAL_SEATING_GROUP]: {
    title: 'Total Seating',
    order: 15,
  },
  [EDITORIAL_RATING_GROUP]: {
    title: 'Edmunds Expert Opinions',
    legendConfig: {
      titleClassNames: 'd-inline',
    },
    withInnerDividers: true,
    tooltip: {
      id: 'rating-scorecard-tooltip',
      iconClassName: 'text-cool-gray-10 size-12',
      text:
        'The Edmunds Vehicle Testing Team evaluates a fresh batch of vehicles every week, pairing objective assessments at our test track with real-world driving on city streets, freeways and winding roads. The data we gather results in our Expert Ratings. They’re based on 30-plus scores that cover every aspect of the automotive experience.',
    },
    adornmentGroupConfig: {
      component: EdmundsTested,
      props: { className: 'mb-1' },
    },
    order: 16,
  },
  [FEATURES_GROUP]: {
    title: 'Features',
    withInnerDividers: true,
    creativeId: getFacetCreativeId('features'),
    shortName: 'feat',
    order: 17,
  },
  [OPTIONS_GROUP]: {
    title: 'Options & Packages',
    withInnerDividers: true,
    order: 18,
  },
  [MPG_GROUP]: {
    title: 'MPG',
    order: 19,
  },
  [VEHICLE_LISTING_DETAILS_GROUP]: {
    title: 'Vehicle Listing Details',
    creativeId: getFacetCreativeId('recently_listings'),
    shortName: 'rece',
    order: 20,
  },
};
export const FACETS_GROUPS_CONFIG_SHOP_3424 = {
  ...FACETS_GROUPS_CONFIG,
  [CONDITION_GROUP]: {
    ...FACETS_GROUPS_CONFIG[CONDITION_GROUP],
    title: 'New / Used',
  },
  [PRICE_PAYMENT_GROUP]: {
    ...FACETS_GROUPS_CONFIG[PRICE_PAYMENT_GROUP],
    title: 'Price & Payments',
  },
  [HISTORY_GROUP]: {
    ...FACETS_GROUPS_CONFIG[HISTORY_GROUP],
    title: 'Accidents & History',
  },
  [ELECTRIC_DETAILS_GROUP]: {
    ...FACETS_GROUPS_CONFIG[ELECTRIC_DETAILS_GROUP],
    title: 'EV Details',
  },
  [EDITORIAL_RATING_GROUP]: {
    ...FACETS_GROUPS_CONFIG[EDITORIAL_RATING_GROUP],
    title: 'Edmunds Review',
    tooltip: undefined,
    adornmentGroupConfig: {
      component: EdmundsTested,
      props: {
        className: 'mb-1',
        tooltip: { ...FACETS_GROUPS_CONFIG[EDITORIAL_RATING_GROUP].tooltip, title: 'Edmunds Review' },
      },
    },
  },
  [VEHICLE_LISTING_DETAILS_GROUP]: {
    ...FACETS_GROUPS_CONFIG[VEHICLE_LISTING_DETAILS_GROUP],
    title: 'Listing Details',
  },
};

export const FACETS_SUBGROUPS_CONFIG = {
  [DELIVERY_SUBGROUP]: { group: LOCATION_GROUP, indexInsideGroup: 2, control: FACETS_CONTROLS.CHECKBOX },
  [LISTING_DETAILS]: { group: VEHICLE_LISTING_DETAILS_GROUP, indexInsideGroup: 0, control: FACETS_CONTROLS.CHECKBOX },
};
