import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getFullImageUrl, getStaticPhotoUrl, isStaticSrc } from 'client/utils/image-helpers';
import { stripHTML } from 'site-modules/shared/utils/strip-html';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { EmbeddedYoutubeAsync } from 'site-modules/shared/components/video/embedded-youtube/embedded-youtube-async';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';

const EMBED_ID_REGEX = /<embed\s+data-embed-id="(.*?)"\s*\/>/g;
const COLLAPSE_THRESHOLD = {
  true: 145,
  false: 360,
};

export function RatingScorecardSection({ name, sectionKey, rating, scale, content, embeds, className, isMobile }) {
  const imageSize = isMobile ? 300 : 717;
  const hasCollapse = stripHTML(content).length > COLLAPSE_THRESHOLD[isMobile];

  const onToggleContentCollapseState = useCallback(
    isOpen => {
      if (!isOpen) {
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
          event_data: {
            action_category: TrackingConstant.USER_ACTION_CATEGORY,
            action_name: TrackingConstant.ACTION_VIEW_CONTENT,
            subaction_name: `view_scorecard_${sectionKey}`,
            creative_id: 'edm-entry-review-scorecard',
            value: name,
          },
        });
      }
    },
    [name, sectionKey]
  );

  const contentFragment = content.split(EMBED_ID_REGEX).map((contentChunk, index) => {
    const key = `name-${index}`;
    const isEmbed = !!(index % 2);
    const embed = isEmbed && embeds && embeds.find(({ id }) => id === contentChunk);
    let thumbnailSrc;

    if (get(embed, 'thumbnailURL')) {
      thumbnailSrc = isStaticSrc(embed.thumbnailURL)
        ? getStaticPhotoUrl(embed.thumbnailURL, isMobile)
        : getFullImageUrl(embed.thumbnailURL, imageSize);
    }

    return (
      <Fragment key={key}>
        {isEmbed ? (
          <Fragment>
            {!!get(embed, 'videoId') && (
              <div className="mb-1" data-tracking-parent={`video-short-${sectionKey}`}>
                <EmbeddedYoutubeAsync
                  videoId={embed.videoId}
                  startTime={+embed.startTime}
                  videoTitle={embed.videoTitle}
                  thumbnailSrc={thumbnailSrc}
                  creativeId={`video-${embed.videoId}`}
                  loaderClassName="m-0_25 pos-a top-0 left-0 right-0 bottom-0 border-0"
                  loaderTrackingId="vapor_video_start"
                  lazyLoadPreview
                  noPlayIcon
                />
              </div>
            )}
          </Fragment>
        ) : (
          <ContentFragment classes="review-section-fragment text-gray-darker size-16">{contentChunk}</ContentFragment>
        )}
      </Fragment>
    );
  });

  return (
    <div className={classnames('rating-scorecard-section text-gray-darker rounded-8 bg-white px-1 py-1', className)}>
      <div className="d-flex align-items-baseline mb-0_75">
        <h4 className="size-16 fw-bold mb-0 me-1">{name}</h4>
        {!!rating && (
          <div
            className={classnames('medium px-0_5 rounded-16', {
              'bg-orange-100': rating <= 7.1,
              'bg-purple-100': rating > 7.1 && rating <= 7.6,
              'bg-turquoise-100': rating > 7.7 && rating <= 8,
              'bg-green-100': rating > 8,
            })}
          >
            <strong>{parseFloat(parseFloat(rating).toFixed(1))}</strong>/{scale}
          </div>
        )}
      </div>
      {hasCollapse ? (
        <ContentCollapse
          minHeight="4.125rem" // 3 lines
          isEclipseFade={false}
          btnClasses="px-0_5 py-0 mt-0_25 size-16 text-blue-40"
          btnContainerClasses="py-0"
          justify="justify-content-start"
          showBtnBelow
          btnTextClasses="fw-normal text-underline"
          classes="size-16"
          ariaLabelHeading={`about ${name}`}
          onToggleContentCollapseState={onToggleContentCollapseState}
          trackingId={null}
        >
          {contentFragment}
        </ContentCollapse>
      ) : (
        contentFragment
      )}
    </div>
  );
}

RatingScorecardSection.propTypes = {
  scale: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sectionKey: PropTypes.string.isRequired,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.string,
  embeds: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

RatingScorecardSection.defaultProps = {
  rating: null,
  content: null,
  embeds: null,
  className: null,
  isMobile: false,
};
