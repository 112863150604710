import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import Button from 'reactstrap/lib/Button';

import { EventToolbox } from 'client/utils/event-toolbox';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { bindToContent, CmsEntities } from 'client/data/models/cms';
import { getCpoMakeContentPath } from 'site-modules/shared/utils/cpo-helper';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { ImpressionTracker } from 'site-modules/shared/components/impression-tracker/impression-tracker';
import { TrackingConstant } from 'client/tracking/constant';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

const DATA_TRACKING_PARENT = 'site-cpo';

export function CertifiedPreOwnedMessageUI({ content, make, isMobile }) {
  useEffect(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: 'native_ad_load',
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        creative_id: DATA_TRACKING_PARENT,
        value: make,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allMetadata = content.getAllMetadata();
  if (isEmpty(allMetadata)) {
    return null;
  }

  const { headline, subheadline, logo, ctaCopy, ctaTarget, oemURL, ...data } = allMetadata;
  const device = isMobile ? 'Mobile' : 'Wired';
  const ctaUrl = data[`ctaClickUrl${device}`];
  const impressionTracker = data[`impressionTracker${device}`];

  return (
    <div
      className="cpo-message bg-white rounded-8 pos-r pt-1 pb-1 px-1_25 mb-1_5"
      data-tracking-parent={DATA_TRACKING_PARENT}
    >
      <AdLabel styles={{ right: '8px', bottom: '8px', top: 'unset' }} />

      <Row className="d-flex justify-content-between pe-1 text-gray-darkest">
        <Col xs={12} md={10}>
          <ContentFragment componentToUse="h2" classes="size-16 mb-0_25">
            {headline}
          </ContentFragment>
          <ContentFragment classes="medium">{subheadline}</ContentFragment>
        </Col>
        {!isMobile && (
          <Col md={2} className="pos-r">
            <img src={logo} alt="" className="pos-a top-0 right-0" style={{ maxHeight: '66px' }} />
          </Col>
        )}
      </Row>
      <hr />
      <ul className="list-unstyled medium text-gray-darker mb-1">
        {new Array(2).fill().map((_, i) => {
          const key = `bulletText${i + 1}`;
          return (
            !!data[key] && (
              <li key={key}>
                <i className="icon-checkmark text-green-50 me-0_75" aria-hidden />
                <ContentFragment componentToUse="span">{data[key]}</ContentFragment>
              </li>
            )
          );
        })}
      </ul>
      <div className="d-flex">
        <div className={classnames('d-flex align-items-center me-1', { 'flex-column': isMobile })}>
          <Button
            tag="a"
            color="outline-blue-50"
            size="lg"
            href={ctaUrl}
            target={ctaTarget}
            rel="noopener noreferrer"
            className={classnames('fw-medium text-transform-none px-0_75 py-0_75 me-0_75', { 'mb-0_25': isMobile })}
            data-tracking-id={TrackingConstant.OEM_SITE_CLICKOUT}
            data-tracking-value={ctaCopy}
          >
            <CtaButtonText text={ctaCopy} />
          </Button>
          <span className="text-blue-50 size-10">{oemURL}</span>
        </div>
        {isMobile && (
          <div className="pos-r">
            <img src={logo} alt="" className="pos-a top-0 left-0" />
          </div>
        )}
      </div>
      {impressionTracker && <ImpressionTracker src={impressionTracker} className="pos-a" />}
    </div>
  );
}

CertifiedPreOwnedMessageUI.propTypes = {
  make: PropTypes.string.isRequired,
  content: CmsEntities.Content,
  isMobile: PropTypes.bool,
};

CertifiedPreOwnedMessageUI.defaultProps = {
  content: DEFAULT_CONTENT,
  isMobile: false,
};

export const CertifiedPreOwnedMessage = connectToModel(CertifiedPreOwnedMessageUI, {
  content: bindToContent(({ make }) => getCpoMakeContentPath(makeNiceName(make)), 'cpo-message'),
});
