import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { InventoryEntities } from 'client/data/models/inventory';
import { generateSEOTitle } from 'site-modules/shared/utils/inventory/srp-seo-utils';
import { isNationalSRP, isDealerSRP } from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { Link } from 'site-modules/shared/components/link/link';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { getDealershipPageUrl } from 'site-modules/shared/utils/inventory-utils/get-dealership-page-url';

/**
 * Returns SRP Header title text.
 * @param {Number} inventoryCount
 * @param {String} zipCode
 * @param {Boolean} showZipCode
 * @returns {String}
 */
export function getMatchingTitle({ inventoryCount, zipCode, showZipCode }) {
  if (!inventoryCount) {
    return 'No matching vehicles found, please update your search criteria';
  }

  return !zipCode
    ? `${numberWithCommas(inventoryCount)} listings`
    : `${numberWithCommas(inventoryCount)} matching vehicles ${showZipCode ? `near ${zipCode}` : ''}`;
}

/**
 * Renders title w/ or w/o back button to a dealer details page.
 * @param {Object} attributes
 * @param {String} title
 * @param {Boolean} isNationalSrp
 * @param {String} arrowClasses
 * @returns {String|ReactElement}
 */
export function SEOTitleLink({ title, isNationalSrp, attributes, arrowClasses }) {
  const dealerInfoPageURL = getDealershipPageUrl(attributes, isNationalSrp);
  if (!dealerInfoPageURL) {
    return title;
  }
  const rooftopId = get(attributes, 'dealerInfo.rooftopId');

  return (
    <Link
      to={dealerInfoPageURL}
      className="d-inline-block srp-text-decoration-none"
      data-tracking-id="dealer_engagement_view_details"
      data-dealer-rooftop-id={rooftopId}
    >
      <span className={classnames(arrowClasses, 'icon-arrow-left3 me-0_25')} aria-hidden />
      {title}
    </Link>
  );
}

SEOTitleLink.propTypes = {
  title: PropTypes.string.isRequired,
  isNationalSrp: PropTypes.bool,
  attributes: InventoryEntities.Attributes,
  arrowClasses: PropTypes.string,
};

SEOTitleLink.defaultProps = {
  isNationalSrp: false,
  attributes: {},
  arrowClasses: 'bg-gray-lighter rounded-circle p-md-0_5 p-0_25',
};

/**
 * Renders SRP Search Header tittle.
 * @param {String} zipCode
 * @param {Number} inventoryCount
 * @param {String} visitorCity
 * @param {Object} urlContext
 * @param {Object} selectedFacets
 * @param {Object} seoData
 * @param {Object} attributes
 * @param {String} visitorStateCode
 * @returns {ReactElement}
 */
export function SrpSearchTitle({
  zipCode,
  inventoryCount,
  urlContext,
  selectedFacets,
  visitorCity,
  visitorStateCode,
  seoData,
  attributes,
}) {
  const isDealerSrp = isDealerSRP(selectedFacets);
  const isNationalSrp = isNationalSRP(urlContext);
  const { isCarFinder } = seoData;
  const title = generateSEOTitle({
    seoData,
    visitorLocation: {
      city: visitorCity,
      stateCode: visitorStateCode,
    },
    isMeta: false,
  });

  return (
    <Fragment>
      <h1 className="d-md-inline-block text-gray-darker mb-0 me-md-1 body">
        {isDealerSrp ? <SEOTitleLink attributes={attributes} title={title} isNationalSrp={isNationalSrp} /> : title}
      </h1>
      <div className="d-md-inline-block xsmall text-gray-dark">
        {getMatchingTitle({
          inventoryCount,
          zipCode: !isNationalSrp && !isCarFinder ? zipCode : null,
          showZipCode: !isDealerSrp,
        })}
      </div>
    </Fragment>
  );
}

SrpSearchTitle.propTypes = {
  zipCode: PropTypes.string,
  visitorCity: PropTypes.string,
  visitorStateCode: PropTypes.string,
  inventoryCount: PropTypes.number,
  urlContext: PropTypes.shape({}),
  selectedFacets: PropTypes.shape({}),
  seoData: PropTypes.shape({}).isRequired,
  attributes: InventoryEntities.Attributes,
};

SrpSearchTitle.defaultProps = {
  zipCode: '',
  visitorCity: '',
  visitorStateCode: '',
  inventoryCount: 0,
  urlContext: {},
  selectedFacets: {},
  attributes: {},
};
