import { compact, flatten, get, sortBy } from 'lodash';
import { PAID_CODE, FREE_CODE } from 'site-modules/shared/components/delivery/constants';
import { LEASE_PAYMENT, LOAN_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { isTexas } from 'site-modules/shared/utils/inventory-utils/is-texas';
import { getDifferenceInDays } from 'site-modules/shared/utils/date-utils';
import { getCabType } from 'site-modules/shared/utils/get-cab-type';
import {
  isAMSI,
  isCarMax,
  isDeliveryEligible,
  isDriveway,
  isFiveStarDealer as getIsFiveStarDealer,
  isInstockOnline,
  isPartnerDealer,
  isTransferDealership,
  isCarZingPremium,
} from 'site-modules/shared/utils/dealer-details-utils';
import { isLeasePaymentsEligible } from 'client/site-modules/shared/utils/inventory-utils/is-lease-payments-eligible';
import { isNew as isNewType } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getEngineTypeValue } from 'site-modules/shared/utils/inventory/get-engine-type-value';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { getPlural } from 'client/utils/plural';
import {
  getIconClasses,
  getOwnerDisplayDescription,
  getRecurrentRangeValue,
  getRecurrentScoreValue,
  getTitleDisplayDescription,
  getUsageTypeDisplayDescription,
} from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-helper';
import {
  ASSIGNED_DEALER_NAMES,
  BODY_TYPES_SRP_EXPANDED,
  IN_STOCK_ONLINE,
} from 'site-modules/shared/components/inventory/constants/usurp-inventory-card';
import classnames from 'classnames';
import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import React, { Fragment } from 'react';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { toShortFormattedAddress } from 'site-modules/shared/utils/address-format';
import { getEngineAttrIcon } from 'site-modules/shared/components/inventory/utils/get-engine-attr-icon';
import { getEngineAttr } from 'site-modules/shared/components/inventory/utils/get-engine-attr';
import { CARMAX } from 'site-modules/shared/constants/inventory/buy-online-dealers';
import { isDealerPriceAvailable } from 'site-modules/shared/utils/inventory-utils/is-dealer-price-available';
import { DEFAULT_PRICE } from 'site-modules/shared/components/deal/deal-constants';
import { isUsedDealCheckAvailable } from 'site-modules/shared/utils/car-buying/is-used-deal-check-available';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { toSentenceCase } from 'client/utils/string-utils';

const BODY_TYPES_ENHANCED = ['Hatchback', 'Coupe', 'Convertible'];

export const INVENTORY_BADGE_LABELS = {
  PRICE_DROP: 'Price Drop',
  NEW_LISTING: 'New Listing',
  ARRIVING_SOON: 'Arriving Soon',
  EV_REBATE: 'EV Tax Credit',
};

export function getDeliveryText({ isNational, vehicle, isCarmaxDealer, radius, visitorStateCode }) {
  const deliveryCode = get(vehicle, 'deliveryOptions.deliveryCode');

  if (deliveryCode) {
    if (isCarmaxDealer) {
      if (isNational) {
        return 'Transfer available';
      }

      const vinDistance = vehicle?.dealerInfo?.distance;

      if (vinDistance > radius) {
        const vinStateCode = vehicle?.dealerInfo?.address?.stateCode;

        if (vinStateCode !== visitorStateCode) {
          return `Transfer available from ${vinStateCode} to ${visitorStateCode}`;
        }

        const vinCity = vehicle?.dealerInfo?.address?.city;

        return `Transfer available from ${vinCity}, ${vinStateCode}`;
      }

      return '';
    }

    if (isNational) {
      return 'Delivery available';
    }
    const parentDealershipName = get(vehicle, 'dealerInfo.displayInfo.parentDealershipName', '');
    if (deliveryCode === PAID_CODE) {
      return isTransferDealership(parentDealershipName) ? 'Delivery available' : 'Home delivery';
    }
    if (deliveryCode === FREE_CODE) {
      return isTransferDealership(parentDealershipName) ? 'Free delivery available' : 'Free home delivery';
    }
  }
  return '';
}

export function getPaymentDetails({ prices, usePaymentType, selectedPaymentType, paymentType }) {
  const paymentTypeValue = usePaymentType ? paymentType : selectedPaymentType;
  const loanPayment = get(prices, 'loan.payment', 0);
  const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);

  let paymentTitle = null;
  let paymentValue = null;

  if (loanPayment && paymentTypeValue === LOAN_PAYMENT) {
    paymentTitle = 'loan';
    paymentValue = formatPriceString(loanPayment);
  } else if (leasePayment && paymentTypeValue === LEASE_PAYMENT) {
    paymentTitle = 'lease';
    paymentValue = formatPriceString(leasePayment);
  }

  return {
    paymentTitle,
    paymentValue,
  };
}

export function getDisplayBelowMarketCopy({ paymentValue, isNew, dealerStateCode, savings }) {
  return !paymentValue && !isNew && !isTexas(dealerStateCode) && savings >= 200;
}

// do not show Trademark Character for Mercedes-Benz in SRP cards - BLT-1186
export function getVehicleName(vehicle, isEnhanced = false, isSrpExpanded = false) {
  const { vehicleInfo, type } = vehicle;
  const { make, model, year, trim = '', specialIdentifier = '' } = get(vehicleInfo, 'styleInfo', {});
  const styleInfo = get(vehicleInfo, 'styleInfo');
  const bodyType = get(styleInfo, 'bodyType');
  const isCpoType = isCpo(type);
  const cabType = getCabType(styleInfo, isSrpExpanded);

  let vehicleName = `${isCpoType ? 'Certified' : ''} ${year} ${make} ${model} ${trim}`.trim();
  const firstLine = `${isCpoType ? 'Certified' : ''} ${year} ${make} ${model}`.trim();
  let secondLine = `${trim}`.trim();

  if (isEnhanced) {
    const engineTypeValue = getEngineTypeValue({ model, trim, engineType: get(vehicleInfo, 'partsInfo.engineType') });
    vehicleName = [vehicleName, engineTypeValue].join(' ');
    secondLine = [secondLine, engineTypeValue].join(' ');
  }

  vehicleName = `${vehicleName} ${cabType}`.trim();
  secondLine = `${secondLine} ${cabType}`.trim();

  if (isSrpExpanded) return [firstLine, `${secondLine} ${specialIdentifier}`.trim()];

  if (isEnhanced && BODY_TYPES_ENHANCED.includes(bodyType) && !model.includes(bodyType) && !trim.includes(bodyType)) {
    vehicleName = `${vehicleName} ${bodyType}`;
  }

  return vehicleName;
}

export function getVdpLink({ vehicle, isNational, paymentType, radius, buyonline, deliveryFee }) {
  const skipLeasePaymentType = paymentType === LOAN_PAYMENT || !isLeasePaymentsEligible(vehicle);
  return getVdpUrl({ inventory: vehicle, isNational, skipLeasePaymentType, radius, buyonline, deliveryFee });
}

export function getInventoryBadgeText(vinInfo, showInTransit) {
  const { firstPublishedDate, prices, inTransit, type } = vinInfo;
  const { displayPriceDelta, displayPriceDeltaTimestamp } = prices;
  let text = null;
  if (showInTransit && inTransit && isNewType(type)) {
    return INVENTORY_BADGE_LABELS.ARRIVING_SOON;
  }

  if (getDifferenceInDays(new Date(firstPublishedDate)) < 7) {
    text = INVENTORY_BADGE_LABELS.NEW_LISTING;
  } else if (displayPriceDelta < 0 && getDifferenceInDays(new Date(displayPriceDeltaTimestamp)) < 7) {
    text = INVENTORY_BADGE_LABELS.PRICE_DROP;
  }

  return text;
}

export function transformDerivedFeatures(vehicle) {
  return sortBy(get(vehicle, 'derivedGenericFeatures', []), ['displayPriority']).map(({ optionText }) => ({
    name: optionText,
  }));
}

export function getKeyPoints({
  vehicle,
  visitorLocation,
  radius,
  isNational,
  showNationalDelivery,
  isExpandedRadius,
  isSrp,
  showRecurrentData,
  isGrayIcons,
  isShop3165Enabled,
}) {
  const { vehicleInfo, historyInfo, dealerInfo, type } = vehicle;
  const isNewVinType = isNewType(type);
  const mileage = get(vehicleInfo, 'mileage', 0);
  const distance = Math.round(get(dealerInfo, 'distance', 0));

  const dealerAddress = get(dealerInfo, 'address');
  const accidentText = get(historyInfo, 'accidentText');
  const cleanTitle = get(historyInfo, 'cleanTitle');

  const parentDealershipName = get(vehicle, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
  const isDrivewayDealer = isDriveway(parentDealershipName);
  const isCarmaxDealer = isCarMax(parentDealershipName);
  const zipCode = visitorLocation?.zipCode;
  const visitorStateCode = visitorLocation?.stateCode;
  const deliveryText = getDeliveryText(
    isSrp ? { vehicle, isNational, isCarmaxDealer, radius, visitorStateCode } : { vehicle, isNational }
  );
  const isFiveStarDealer = getIsFiveStarDealer(vehicle);
  const isDeliveryAvailable = isDeliveryEligible(vehicle, zipCode);
  let dealerLocationLabel;
  let isBoldDealerLocationLabel = false;
  const isInStockOnline =
    (!isNewVinType || isDrivewayDealer) && isInstockOnline({ distance, radius, isDeliveryAvailable });
  if (showNationalDelivery) {
    dealerLocationLabel = isDeliveryAvailable ? IN_STOCK_ONLINE : toShortFormattedAddress(dealerAddress);
  } else {
    dealerLocationLabel = isInStockOnline ? IN_STOCK_ONLINE : `${numberWithCommas(distance)} mi away`;
    isBoldDealerLocationLabel = isExpandedRadius && !isDeliveryAvailable && distance >= 100 && !isInStockOnline;
  }
  const bodyType = get(vehicleInfo, 'styleInfo.bodyType');
  const dealerName = get(dealerInfo, 'name', '');
  const dealerRating = get(dealerInfo, 'displayInfo.salesOverallRating', 0);
  const dealerReviewCount = get(dealerInfo, 'displayInfo.salesReviewCount', 0);

  const accidentDisplay = getTitleDisplayDescription(historyInfo);
  const ownerDisplay = getOwnerDisplayDescription(historyInfo);
  let usageDisplay = getUsageTypeDisplayDescription(historyInfo, true).toLowerCase();
  usageDisplay = usageDisplay.charAt(0).toUpperCase() + usageDisplay.slice(1);

  const isElectric = get(vehicle, 'vehicleInfo.partsInfo.engineType') === 'electric';
  const showElectricityRange = isElectric && !showRecurrentData;
  const engineAttrIcon = getEngineAttrIcon(vehicleInfo, showElectricityRange);
  const engineAttr = getEngineAttr({
    vehicleInfo,
    showElectricityRange,
    isUsedVinType: !isNewVinType,
    isShop3165Enabled,
  });
  const recurrentRangeValue = getRecurrentRangeValue(vehicle);
  const recurrentScoreValue = getRecurrentScoreValue(vehicle);

  let assignedDealerName = '';

  if (dealerLocationLabel === IN_STOCK_ONLINE) {
    assignedDealerName = ASSIGNED_DEALER_NAMES.includes(parentDealershipName) && parentDealershipName;
    assignedDealerName = parentDealershipName === CARMAX ? 'CarMax' : assignedDealerName;
  }

  const shouldRenderDealerRating = isNewVinType && !!dealerRating;

  return compact([
    !isNewVinType && {
      icon: 'icon-meter',
      iconTitle: 'Car Mileage',
      content: mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided',
    },
    !isNewVinType &&
      (accidentDisplay || ownerDisplay || usageDisplay) && {
        icon: getIconClasses({ accidentText, cleanTitle, isGrayIcons }),
        iconTitle: 'Vehicle History',
        content: isShop3165Enabled
          ? toSentenceCase(compact([accidentDisplay, ownerDisplay, usageDisplay]).join(', '))
          : compact([accidentDisplay, ownerDisplay, usageDisplay]).join(', '),
      },
    BODY_TYPES_SRP_EXPANDED.includes(bodyType) &&
      !showRecurrentData &&
      engineAttr && {
        icon: engineAttrIcon,
        iconTitle: 'Engine attribute',
        content: engineAttr,
      },
    showRecurrentData &&
      isShop3165Enabled && {
        icon: 'icon-low-miles',
        iconTitle: 'Current Est. Range',
        content: recurrentRangeValue,
      },
    showRecurrentData &&
      isShop3165Enabled && {
        icon: 'icon-battery_charging_full2',
        iconTitle: 'Battery Range Score',
        content: recurrentScoreValue,
      },
    (!isNational || showNationalDelivery) && {
      icon: 'icon-location',
      iconTitle: 'Dealer Location',
      content: (
        <span>
          <span>{assignedDealerName || dealerName} </span>
          <span
            className={classnames(
              {
                'fw-bold': isBoldDealerLocationLabel,
                'me-0_25': shouldRenderDealerRating,
              },
              'text-cool-gray-40'
            )}
          >
            ({dealerLocationLabel})
          </span>
          {shouldRenderDealerRating && (
            <span className="text-nowrap">
              <RatingStars className="text-primary-darker medium" rating={dealerRating} />
              {!!dealerReviewCount && <span className="ms-0_25 text-cool-gray-40">({dealerReviewCount})</span>}
            </span>
          )}
        </span>
      ),
    },
    deliveryText && {
      icon: 'icon-transit',
      iconTitle: 'Shipping',
      content: <Fragment>{deliveryText}*</Fragment>,
      showFiveStar: isFiveStarDealer,
    },
    !deliveryText &&
      isFiveStarDealer && {
        icon: 'icon-5star text-blue-50',
        iconTitle: 'Five Star Dealer',
        content: <span className="size-12 fw-medium text-blue-50">Five Star Dealer</span>,
      },
  ]);
}

export function getSimilarCardsKeyPoints({
  vehicle,
  visitorLocation,
  radius,
  isNational,
  showNationalDelivery,
  isSrp,
  isGrayIcons,
}) {
  const { vehicleInfo, historyInfo, dealerInfo, type } = vehicle;
  const isNewVinType = isNewType(type);
  const mileage = get(vehicleInfo, 'mileage', 0);
  const distance = Math.round(get(dealerInfo, 'distance', 0));

  const dealerAddress = get(dealerInfo, 'address');
  const accidentText = get(historyInfo, 'accidentText');
  const cleanTitle = get(historyInfo, 'cleanTitle');

  const parentDealershipName = get(vehicle, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
  const isDrivewayDealer = isDriveway(parentDealershipName);
  const isCarmaxDealer = isCarMax(parentDealershipName);
  const zipCode = visitorLocation?.zipCode;
  const visitorStateCode = visitorLocation?.stateCode;
  const deliveryText = getDeliveryText(
    isSrp ? { vehicle, isNational, isCarmaxDealer, radius, visitorStateCode } : { vehicle, isNational }
  );
  const isFiveStarDealer = getIsFiveStarDealer(vehicle);
  const isDeliveryAvailable = isDeliveryEligible(vehicle, zipCode);
  let dealerLocationLabel;
  const isInStockOnline =
    (!isNewVinType || isDrivewayDealer) && isInstockOnline({ distance, radius, isDeliveryAvailable });
  if (showNationalDelivery) {
    dealerLocationLabel = isDeliveryAvailable ? IN_STOCK_ONLINE : toShortFormattedAddress(dealerAddress);
  } else {
    dealerLocationLabel = isInStockOnline
      ? IN_STOCK_ONLINE
      : `${numberWithCommas(distance)} ${getPlural('mile', distance)} away`;
  }

  return compact([
    !isNewVinType && {
      icon: 'icon-meter',
      iconTitle: 'Car Mileage',
      content: mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided',
    },
    (!isNational || showNationalDelivery) && {
      icon: 'icon-location',
      iconTitle: 'Dealer Location',
      content: dealerLocationLabel,
    },
    !isNewVinType &&
      accidentText && {
        icon: getIconClasses({ accidentText, cleanTitle, isGrayIcons }),
        iconTitle: 'Vehicle History',
        content: getTitleDisplayDescription(historyInfo),
      },
    isFiveStarDealer && {
      icon: 'icon-5star',
      iconTitle: 'Five Star Dealer',
      content: 'Five Star Dealer ',
    },
    deliveryText && {
      icon: 'icon-transit',
      iconTitle: 'Shipping',
      content: <Fragment>{deliveryText}*</Fragment>,
    },
  ]);
}

export function getLlmCardKeyPoints({ vehicle }) {
  const { vehicleInfo, vin, type } = vehicle;
  const isNewVinType = isNewType(type);
  const mileage = get(vehicleInfo, 'mileage', 0);

  return compact([
    !isNewVinType && {
      icon: 'icon-meter',
      iconTitle: 'Car Mileage',
      content: mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided',
    },
    vin && {
      icon: 'icon-price-tag3',
      iconTitle: 'Vin',
      content: vin,
    },
  ]);
}

export function getIsLeaseCardWithLeasePayment({ selectedPaymentType, prices }) {
  const isLeaseCard = selectedPaymentType === LEASE_PAYMENT;
  const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);
  return Boolean(isLeaseCard && leasePayment);
}

export function getIsLoanCardWithLoanPayment({ selectedPaymentType, prices }) {
  const isLoanCard = selectedPaymentType === LOAN_PAYMENT;
  const loanPayment = get(prices, 'loan.payment', 0);
  return Boolean(isLoanCard && loanPayment);
}

export function getVehicleNameAriaLabel({ isSimilarCards, vehicle, isEnhancedName, prices, selectedPaymentType }) {
  const vehicleName = flatten([getVehicleName(vehicle, isEnhancedName, !isSimilarCards)]).join(' ');

  const vehiclePrice =
    isDealerPriceAvailable(vehicle) &&
    !getIsLeaseCardWithLeasePayment({ selectedPaymentType, prices }) &&
    !getIsLoanCardWithLoanPayment({ selectedPaymentType, prices })
      ? get(vehicle, DATA_PATH.DEALER_PRICE)
      : get(prices, 'displayPrice', 0);
  return `${vehiclePrice ? formatPriceString(vehiclePrice) : DEFAULT_PRICE} ${vehicleName}`;
}

export function getTCPAParentDealershipName({ parentDealershipName, partnerCode }) {
  const isCarMaxDealer = isCarMax(parentDealershipName);
  const isAMSIDealer = isAMSI(parentDealershipName);
  const isCarZingDealer = isCarZingPremium(partnerCode);

  if (isCarMaxDealer || isAMSIDealer) {
    return parentDealershipName;
  }

  return isCarZingDealer ? partnerCode : null;
}

export function isUsedCTAAvailable({ vehicle }) {
  const isPartner = isPartnerDealer(vehicle);
  return isUsedDealCheckAvailable(vehicle) || isPartner;
}

export function checkDealerSupportsCTA({ vehicle, disableDrivewayNewCarLeadForms }) {
  const isDrivewayDealer = isDriveway(vehicle.dealerInfo?.displayInfo?.parentDealershipName);
  const isPartner = isPartnerDealer(vehicle);
  const isUsedType = isUsed(vehicle.type);
  const isNew = isNewType(vehicle.type);

  return !(isDrivewayDealer && isPartner && (isUsedType || (disableDrivewayNewCarLeadForms && isNew)));
}

export function isCTAAvailable({ vehicle, disableDrivewayNewCarLeadForms }) {
  const isNewVinType = isNewType(vehicle.type);

  return (
    checkDealerSupportsCTA({ vehicle, disableDrivewayNewCarLeadForms }) &&
    (isNewVinType || isUsedCTAAvailable({ vehicle }))
  );
}
