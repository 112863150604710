import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, merge } from 'lodash';

// Constants
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { CARD_VIEW, LEAD_FORM_VIEWS, THANK_YOU_VIEWS } from 'site-modules/shared/constants/lead-form/lead-form-params';

// Components
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
import { sendMarketoThankYouEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
// Utils
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { getMultiDigitalRetailLeadFormPropsCommon } from './multi-digital-retail-offer-type-lead-form-config-utils';

export function MultiDigitalRetailOfferTypeLeadFormSrpNewUI({
  params,
  options,
  pageName,
  visitorId,
  isUserSetZip,
  shop3561Recipe,
}) {
  const postProcessMethods = sendMarketoThankYouEmail;
  const subtitle = (
    <div className="mb-1_5">
      <div className="mb-1">
        Did you know? You should know exactly what you should pay for a car before you test drive it.
      </div>
      <div> We&apos;ll show you what to pay on the next page.</div>
    </div>
  );
  const subtitleChal1 = (
    <div className="mb-1_5">
      <div className="mb-1">
        Don’t negotiate at the dealership. Request price quotes from our dealers and find the best deal from your own
        home.
      </div>
    </div>
  );
  const subtitleChal2 = (
    <div className="mb-1_5">
      <div className="mb-1">
        Most dealers don’t display their lowest price online. Use this form to request price quotes and get the best
        deal before going to the dealership.
      </div>
    </div>
  );
  const subtitleChal3 = (
    <div className="mb-1_5">
      <div className="mb-1">
        Submit your contact information to request written price quotes that include everything—no surprises at the
        dealership.
      </div>
    </div>
  );
  const subtitleChal4 = (
    <div className="mb-1_5">
      <div className="mb-1">
        Most dealers don’t display their lowest price online. Use this form to request price quotes and get the best
        deal before going to the dealership.
      </div>
    </div>
  );

  const TITLE_MAP = {
    ctrl: '[savingsValue=0?Save with Edmunds:Save {savingsValue} with Edmunds]',
    chal1: 'Save Money, Skip the Hassle',
    chal2: 'Know Before You Go',
    chal3: 'Get the Lowest Out-the-Door Price',
    chal4: 'Beat the Sticker Price',
  };
  const SUBTITLE_MAP = {
    ctrl: subtitle,
    chal1: subtitleChal1,
    chal2: subtitleChal2,
    chal3: subtitleChal3,
    chal4: subtitleChal4,
  };

  const newFormProps = {
    customConfig: {
      tags: null,
      leadFormComponent: LEAD_FORM_VIEWS.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW,
      leadFormConfig: {
        cardView: CARD_VIEW.DEALER_VIN_SRP_NEW,
        isPriceChecker: true,
        title: TITLE_MAP[shop3561Recipe],
        titleClassName: 'text-blue-50 fw-bold size-24 mb-1 mb-md-1_5',
        subtitle: SUBTITLE_MAP[shop3561Recipe],
      },
      thankYouComponent: THANK_YOU_VIEWS.SRP_PRICE_CHECKER,
      thankYouConfig: {
        isNewVinOnSrp: true,
      },
      apiParams: {
        enableAdvertisedPrice: true,
      },
    },
    config: {
      ...LEAD_FORM_CONFIGS.UNIFIED_PURCHASE,
    },
  };

  const formProps = merge(
    getMultiDigitalRetailLeadFormPropsCommon({
      params,
      options,
      visitorId,
      pageName,
      postProcessMethods,
      isNewVin: true,
      isUserSetZip,
    }),
    newFormProps
  );

  return <UnifiedLeadFormAsync {...formProps} />;
}

MultiDigitalRetailOfferTypeLeadFormSrpNewUI.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  pageName: PropTypes.string,
  visitorId: PropTypes.string,
  isUserSetZip: PropTypes.bool,
  shop3561Recipe: PropTypes.string,
};

MultiDigitalRetailOfferTypeLeadFormSrpNewUI.defaultProps = {
  pageName: '',
  visitorId: null,
  isUserSetZip: false,
  shop3561Recipe: 'ctrl',
};

export const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  visitorId: get(state, 'visitor.id'),
  isUserSetZip: !!get(state, 'visitor.location.userSet'),
  shop3561Recipe: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'shop-3561-nci-srp-form',
    defaultVal: 'ctrl',
  }),
});

export const MultiDigitalRetailOfferTypeLeadFormSrpNew = connect(mapStateToProps)(
  MultiDigitalRetailOfferTypeLeadFormSrpNewUI
);
