import { get, isEmpty } from 'lodash';

// Models
import { InventoryModel, InventoryPaths } from 'client/data/models/inventory';
import { UrlModel, URL_CONTEXT_PATH } from 'client/data/models/url';
import { LinkWidgetsModel, getPageLinksPath } from 'client/data/models/link-widgets';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { PageModel } from 'client/data/models/page';
import { getReduxModelStateFromStore } from 'client/data/luckdragon/redux/redux-model';
import { VisitorModel } from 'client/data/models/visitor';
import { buildDealerIcoWidgetConfigurationPath, DealerIcoWidgetModel } from 'client/data/models/dealer-ico-widget';

// Preloaders
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { VehiclePreloader } from 'site-modules/shared/utils/core-page/vehicle-preloader';

// Utils
import { pageDefinition } from 'site-modules/shared/pages/page';
import { getQuery } from 'client/utils/location';
import {
  getPageFiltersValues,
  getPagePublicationState,
  getUrlPattern,
} from 'site-modules/shared/utils/inventory/srp-utils';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isNationalSRP, isMakeModelLeaseDeals } from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { getSrpSeoObject } from 'site-modules/shared/utils/inventory/srp-seo-object';
import { getFilterFromLocation } from 'site-modules/shared/utils/inventory/search-filter';
import { getVehicleFromSrpInventoryData } from 'site-modules/shared/components/native-ad/utils/get-ad-targeting';
import { setAdsListToPreload } from 'site-modules/shared/components/native-ad/utils/utils';
import { getUsurpImages } from 'client/site-modules/shared/utils/inventory/srp-images';
import { getHasLinkWidget } from 'site-modules/inventory/utils/link-widget';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { handle404Error } from 'site-modules/shared/utils/errors';
import { someInventoryHasCTA } from 'site-modules/inventory/utils/some-inventory-has-cta';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { isInventoryWithRecurrentInfo } from 'site-modules/shared/utils/inventory/is-inventory-with-recurrent-info';
import {
  getNoInventoryDealerlessMessageType,
  isNoInventoryPorscheMessage,
} from 'site-modules/shared/components/inventory/utils/no-inventory-message';
import { getMultiMMCriticalKeyValue } from 'site-modules/shared/utils/inventory/multi-make-model';
import { checkCpoMessage } from 'site-modules/shared/utils/cpo-message';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { isCTAAvailable } from 'site-modules/shared/utils/inventory/usurp-inventory-card';

// Actions
import { legacyPageUpdate } from 'client/actions/legacy-page';
import { updateVehicle } from 'client/actions/vehicle';
import { setCriticalCss } from 'client/actions/critical-css';
import { redirect } from 'client/actions/redirect';
import { forceDisableLooseEtag } from 'client/actions/etag';

// Constants
import {
  CORE_SRP_URL_PATTERN,
  MAKE_TYPE_SRP_URL_PATTERN,
} from 'site-modules/shared/constants/inventory/srp-url-patterns';
import { SRP_PAGE_NAME } from 'site-modules/shared/constants/page-names';
import {
  BUILD_PRICE_AD,
  EAS_LISTING_AD,
  INCENTIVES_AD,
  SRP_AD,
} from 'site-modules/shared/components/native-ad/utils/constants';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';

// Components
import { EdmundsCpaNoAdhesionDecorator } from 'client/site-modules/shared/pages/decorators/edmunds-cpa-no-adhesion';
import { SEOModel } from 'client/data/models/seo';
import {
  buildAuthorPath,
  buildRatedByPath,
  buildReviewPath,
  EditorialReviewModel,
} from 'client/data/models/editorial-review';
import {
  buildDefaultVehiclePath,
  buildForSaleYearsPath,
  buildMmyModelFamilyIdsPath,
  buildModelFamilyIdsModelYearsPath,
  buildVehiclePath,
  VehicleModel,
} from 'client/data/models/vehicle-v2';
import { buildWhatsNewPath, VehicleDetailsModel } from 'client/data/models/mmy-details';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { isReviewSectionEmpty } from 'site-modules/inventory/utils/is-review-section-empty';
import { isUsedSRPReviewComboPage } from 'site-modules/shared/utils/inventory/srp-review-combo';

import { UsurpContainer } from './usurp-container';

const DESKTOP_NUM_CARDS = 4;
const MOBILE_NUM_CARDS = 2;

const getTopMessageCriticalCssKey = ({
  isNoInventoryPorsche,
  isNoInventoryDealerless,
  showExpandedRadiusMsg,
  isSrpNarrativeText,
}) => {
  if (isNoInventoryPorsche || isNoInventoryDealerless || showExpandedRadiusMsg) {
    return 'box';
  }

  if (isSrpNarrativeText) {
    return 'txt';
  }

  return false;
};

export function getCriticalCssKeys({
  type = '',
  hasCpoMessage = false,
  isNational = false,
  isMakeModelLeaseDealsPage = false,
  inventoryResultsAboveFold = [],
  hasCtas,
  disableInventoryMLPhotoSort,
  showExpandedRadiusMsg = false,
  isMobile = false,
  isSrpNarrativeText = false,
  isRecurrentRendered = false,
  isNoInventoryPorsche = false,
  isNoInventoryDealerless = false,
  multiMM = false,
  srpReviewCombo = 'no',
}) {
  let typeKey = false;

  if (isMakeModelLeaseDealsPage) {
    typeKey = 'lease';
  } else if (isNew(type)) {
    typeKey = 'newOnly'; // new cards have slightly different layout
  } else if (hasCpoMessage) {
    typeKey = 'cpoMessage'; // we have a zero position cpo component that displays above the results and a cpo-label on the cards
  }

  const hasMultiplePhotosAboveFold = inventoryResultsAboveFold.some(
    vehicle => getUsurpImages(vehicle, disableInventoryMLPhotoSort).photoUrls.length > 1
  );

  const ctaPart = hasCtas ? 'cta' : 'noCta';
  const recurrentPart = isRecurrentRendered ? 'Rec' : 'NoRec';
  const cardConf = `${ctaPart}${recurrentPart}`;

  // 4 * 2 * 4 * 2 * 3 * 4 * 3 = 2304 values
  const keys = {
    type: typeKey, // 4 values (false, lease, newOnly, cpoMessage)
    ntl: isNational, // 2 values (true, false)
    cardConf, // 4 values (ctaRec, ctaNoRec, noCtaRec, noCtaNoRec)
    photos: hasMultiplePhotosAboveFold, // 2 values (true, false)
    topm: getTopMessageCriticalCssKey({
      isNoInventoryPorsche,
      isNoInventoryDealerless,
      showExpandedRadiusMsg,
      isSrpNarrativeText,
    }), // 3 values (false, box, txt)
    multiMM, // 3 values for desktop (select, addAnother, bubbles); 1 value for mobile (false)
    srpReviewCombo, // 3 values (no, rtng, no-rtng)
  };

  if (isMobile) {
    /*
      TODO: delete criticalCssHeight (and clean up keys) after https://edmunds.atlassian.net/browse/PLAT-2314 is merged

      Increasing the critical css height for mobile to confidently get 2 cards into view for all scenarios

      We chose this number to give additional padding for 2 full cards.  This number is closer to fitting
      3 cards.  We have some scenarios where we have a large zero box as well as some line-wrapping
      that can start pushing cards closer and closer to the critical css bounds.

      Example: https://www.edmunds.com/used-certified-pre-owned-toyota-camry/?test-zip=90401

      For this example above, we need 1280 pixels to fit two cards.  However, for safety, we would like to
      add a third card, which takes us to 1750 pixels.

      While this might seem like a lot of extra padding, minimal css will be added because the
      cards are overall very similar.  If we were on a page with a lot of different content, we might
      use smaller height, but since these cards are so similar, the risk doesn't outweigh the reward.
    */
    keys.criticalCssHeight = 1750;
  } else {
    /*
      TODO: delete criticalCssHeight (and clean up keys) after https://edmunds.atlassian.net/browse/PLAT-2314 is merged

      For desktop, we are increasing the height to 1300 so that the "Price and Payment Filter" is within the viewport.
      This filter causes a CLS when user scrolls before the non-critical css is loaded.
    */
    keys.criticalCssHeight = 1300;
  }

  return keys;
}

export const getPreload = (store, props, params = {}) => {
  const { isSrpOnMakeType, isSrpOnUsedCore, disableCriticalCss } = params;

  // Predefine/Preload data required for page start
  let preloader = null;

  if (isSrpOnUsedCore) {
    preloader = new VehiclePreloader(store, { ...props, params });
  } else {
    preloader = new ModelPreloader(store, { debugId: 'usurp-definition.jsx' });
  }
  const state = store.getState();

  const modelState = getReduxModelStateFromStore(store);
  const urlContext = modelState.get(URL_CONTEXT_PATH, UrlModel);
  const isNational = isNationalSRP(urlContext);

  const redirectUrl = get(urlContext, 'redirectUrl', '');
  const query = getQuery(props.location);

  if (redirectUrl) {
    const hasQueryString = redirectUrl.includes('?');

    store.dispatch(
      redirect({
        path: isEmpty(query) ? redirectUrl : `${redirectUrl}${hasQueryString ? '&' : '?'}${objectToQueryString(query)}`,
      })
    );

    // Short circuit here, because we are going to redirect (so no point in continuing)
    return preloader.load();
  }

  if (!isSrpOnUsedCore && !isSrpOnMakeType && urlContext && !urlContext.isValidUrl) {
    throw handle404Error({ errorMessage: 'Page not found' });
  }

  const urlPattern = getUrlPattern(urlContext);

  let updatedUrlContext = urlContext;

  if (isSrpOnMakeType) {
    updatedUrlContext = {
      ...urlContext,
      isSrpOnMakeType: true,
      urlPattern: MAKE_TYPE_SRP_URL_PATTERN,
    };

    preloader.set(URL_CONTEXT_PATH, UrlModel, updatedUrlContext);
  }

  if (isSrpOnUsedCore) {
    updatedUrlContext = {
      ...urlContext,
      isSrpOnUsedCore: true,
      urlPattern: CORE_SRP_URL_PATTERN,
    };

    preloader.set(URL_CONTEXT_PATH, UrlModel, updatedUrlContext);
  }

  const pageFilters = {
    ...getFilterFromLocation(
      {
        ...query,
        ...(isSrpOnMakeType ? { make: params.make, ...params.typeFilter } : {}),
        ...(isSrpOnUsedCore
          ? {
              make: params.make,
              model: params.model,
              year: params.year,
              inventorytype: 'used,cpo',
            }
          : {}),
      },
      updatedUrlContext
    ),
    ...(query.wz ? { wz: query.wz } : {}),
  };

  if (getHasLinkWidget(updatedUrlContext)) {
    preloader.resolve(getPageLinksPath(), LinkWidgetsModel);
  }

  const { selectedMake, inventoryType } = getPageFiltersValues(modelState, pageFilters);

  const rooftopId = get(pageFilters, 'rooftopId');

  preloader.set('searchResultsFilter', InventoryModel, pageFilters);
  preloader.resolve('searchResults', InventoryModel);

  let pageNameUpdateValue = `${getPagePublicationState(inventoryType)}_car_inventory_srp`;

  if (isSrpOnMakeType) {
    pageNameUpdateValue = 'make_type_srp';
  }

  if (isNational) {
    pageNameUpdateValue = `${pageNameUpdateValue}_ntl`;
  }

  if (rooftopId) {
    pageNameUpdateValue = `dealer_${pageNameUpdateValue}`;
    preloader.resolve(
      buildDealerIcoWidgetConfigurationPath({
        rooftopId,
      }),
      DealerIcoWidgetModel
    );
  }

  store.dispatch(
    legacyPageUpdate({
      pageName: pageNameUpdateValue,
    })
  );

  const isPrequalified = get(state, 'financing.prequalificationData');
  const isSeot4290Enabled = modelState.get('enable-seot-4290-car-type-schema-2022', FeatureFlagModel);
  const isSeot4456Enabled = modelState.get('enable-seot-4456-uci-meta-title', FeatureFlagModel);
  const usedSRPReviewComboPage = isUsedSRPReviewComboPage(updatedUrlContext, pageFilters);

  if (usedSRPReviewComboPage) {
    preloader.resolve(buildMmyModelFamilyIdsPath(params), VehicleModel);
    preloader.resolve(buildForSaleYearsPath(params), VehicleModel);
  }

  if (isSeot4290Enabled || isSeot4456Enabled) {
    preloader.resolve('seoExperiments', SEOModel);
  }

  return preloader.load().then(async stateModel => {
    const inventoryData = stateModel.get('searchResults', InventoryModel);
    const inventoryResults = get(inventoryData, 'inventories.results', []);
    const firstType = get(inventoryType, '[0]', '');
    const isOnlyType = inventoryType.length === 1;

    // Resolve available make with certified program
    const canResolveCpoMessage = checkCpoMessage(pageFilters);
    const cpoDataPath = `cpoData["${makeNiceName(selectedMake)}"]`;
    if (canResolveCpoMessage) {
      preloader.resolve(cpoDataPath, InventoryModel);
    }

    const isAds9372Enabled = ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'ads-9372',
      defaultVal: 'ctrl',
    }).includes('chal');
    let adsList = new Set([SRP_AD.AD_NAME, ...(isAds9372Enabled ? [EAS_LISTING_AD.AD_NAME] : [])]);

    const { pageContext } = state;
    let vehiclePageData;
    let vehicleAdData;
    const vehiclePath = params.year ? buildVehiclePath(params) : buildDefaultVehiclePath(params);

    if (isSrpOnUsedCore) {
      vehiclePageData = modelState.get('vehicle', PageModel);
      const makeModelSubmodelYear = modelState.get(vehiclePath, VehicleModel);

      if (usedSRPReviewComboPage) {
        adsList = [...adsList, BUILD_PRICE_AD.AD_NAME, INCENTIVES_AD.AD_NAME];
        const vehicleParams = getParamsFromVehicle(makeModelSubmodelYear);

        preloader.resolve(buildReviewPath(vehicleParams), EditorialReviewModel);
        preloader.resolve(buildAuthorPath(vehicleParams), EditorialReviewModel);
        preloader.resolve(buildRatedByPath(vehicleParams), EditorialReviewModel);
        preloader.resolve(buildWhatsNewPath(vehicleParams), VehicleDetailsModel);

        // Other years
        const modelFamilyIds = modelState.get(buildMmyModelFamilyIdsPath(params), VehicleModel);
        if (!isEmpty(modelFamilyIds)) {
          preloader.resolve(buildModelFamilyIdsModelYearsPath(modelFamilyIds), VehicleModel);
        }
      }
    } else {
      const vehicleData = getVehicleFromSrpInventoryData(inventoryData, pageFilters);
      vehiclePageData = vehicleData.vehiclePageData;
      vehicleAdData = vehicleData.vehicleAdData;
    }

    store.dispatch(updateVehicle(vehiclePageData));

    if (!rooftopId) {
      // rooftopId - is a dealer page, don't show ads
      // when page is make-srp ot bt type, vehicleAdData is defined otherwise is null
      setAdsListToPreload(preloader, state, adsList, vehicleAdData || vehiclePageData, {
        pageLocation: pageContext.location,
        spaMode: true,
      });

      preloader.set(InventoryPaths.usePageVehicleForAdsTargetingPath(), InventoryModel, !!isSrpOnUsedCore);
      preloader.resolve(InventoryPaths.getAdTargeting(), InventoryModel);
    }

    const zipCode = modelState.get('location.zipCode', VisitorModel);

    /**
     * enable critical css when there is:
     *
     *   1. Is not disabled in params
     *   2. has search results cards
     *   3. at least one filter is selected
     *   4. the user has a valid US zip code
     *   5. is not "prequalified" for capital one
     *   6. is not dealerSrp
     */
    const enableCriticalCss =
      !disableCriticalCss &&
      !!get(inventoryData, 'inventories.results.length') &&
      !isEmpty(pageFilters) &&
      zipCode &&
      !isPrequalified &&
      !rooftopId;

    const isMakeModelLeaseDealsPage = isMakeModelLeaseDeals(urlPattern);

    const pagePublicationState = getPagePublicationState(inventoryType);
    const isNewPageState = [PUB_STATES_LOWERCASE.NEW, PUB_STATES_LOWERCASE.NEW_USED].includes(pagePublicationState);

    // for desktop = 4
    // for mobile = 2
    const numCardsAboveFold = state.mobile ? MOBILE_NUM_CARDS : DESKTOP_NUM_CARDS;
    const disableInventoryMLPhotoSort = modelState.get('disable-inventory-ml-photo-sort', FeatureFlagModel);
    const disableDrivewayNewCarLeadForms = modelState.get('disableDrivewayNewCarLeadForms', FeatureFlagModel);
    const pageNumber = get(inventoryData, 'inventories.pageNumber');
    const showExpandedRadiusMsg =
      !!get(inventoryData, 'attributes.expandedRadiusDueToLowResults', false) &&
      get(inventoryData, 'attributes.radius') === 6000 &&
      pageNumber === 1;
    const inventory = get(inventoryData, 'inventories.results', []);
    const attributes = get(inventoryData, 'attributes', {});

    const narrativeItems = get(inventoryData, 'seoInfo.narrativeWidget.items', []);
    const isSrpNarrativeText = narrativeItems.length > 0;

    const isShop3165Enabled = ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'shop-3165-recurrent',
      defaultVal: 'ctrl',
    }).includes('chal');

    const isRecurrentRendered =
      !isShop3165Enabled && inventory.slice(0, numCardsAboveFold).some(isInventoryWithRecurrentInfo);

    const isNoInventoryPorsche = isNoInventoryPorscheMessage({
      make: get(attributes, 'vehicle.make.name', ''),
      isNewPageState,
      facets: get(inventoryData, 'facets', []),
    });
    const isNoInventoryDealerless = !!getNoInventoryDealerlessMessageType({
      makeSlug: get(attributes, 'vehicle.make.niceId', ''),
      selectedCarTypes: get(pageFilters, 'inventoryType', []),
    });

    const firstInventory = get(inventoryData, 'inventories.results[0]');

    if (firstInventory) {
      const { photoUrls } = getUsurpImages(firstInventory, disableInventoryMLPhotoSort);
      const imageLink = get(photoUrls, '[0]');

      const preloadHeroImageAsset = {
        href: imageLink,
        as: 'image',
      };

      preloader.set('server.preloadedAssets', PageModel, [preloadHeroImageAsset]);
    }

    const preloaderResponse = await preloader.load();
    /* PLEASE DON'T PRELOAD ANY DATA IN THIS BLOCK.
       PLEASE MAKE YOUR CALLS IN PARALLEL.
       THIS SECTION WAS ONLY ADDED FOR CRITICAL CSS KEY DISPATCH. */

    let srpReviewComboKey = 'no';
    if (usedSRPReviewComboPage) {
      const makeModelSubmodelYear = preloaderResponse.get(vehiclePath, VehicleModel);
      const vehicleParams = getParamsFromVehicle(makeModelSubmodelYear);
      const editorialReview = preloaderResponse.get(buildReviewPath(vehicleParams), EditorialReviewModel);
      if (!isReviewSectionEmpty(editorialReview)) {
        srpReviewComboKey = get(editorialReview, 'ratings.overall.rating') ? 'rtng' : 'no-rtng';
      }
    }
    const inventoryResultsAboveFold = inventoryResults.slice(0, numCardsAboveFold);
    const isAboveFoldWithCta = inventoryResultsAboveFold.some(vehicle =>
      isCTAAvailable({ vehicle, disableDrivewayNewCarLeadForms })
    );
    const hasCtas =
      someInventoryHasCTA({
        inventories: inventoryResultsAboveFold,
        isDealerSrp: !!rooftopId,
      }) && isAboveFoldWithCta;
    const hasCpoMessage = preloaderResponse.get(cpoDataPath, InventoryModel)?.hasCpoMessage;

    const criticalCssKeys = enableCriticalCss
      ? getCriticalCssKeys({
          type: isOnlyType ? firstType : '',
          hasCpoMessage,
          isNational,
          isMakeModelLeaseDealsPage,
          inventoryResultsAboveFold,
          hasCtas,
          disableInventoryMLPhotoSort,
          showExpandedRadiusMsg,
          isMobile: state.mobile,
          isSrpNarrativeText,
          isRecurrentRendered,
          isNoInventoryPorsche,
          isNoInventoryDealerless,
          multiMM:
            !state.mobile &&
            getMultiMMCriticalKeyValue({
              makesNum: get(pageFilters, 'make', []).length,
              modelsNum: get(pageFilters, 'model', []).length,
            }),
          srpReviewCombo: srpReviewComboKey,
        })
      : false;

    store.dispatch(setCriticalCss(criticalCssKeys));

    if (isNational) {
      store.dispatch(forceDisableLooseEtag(true));
    }

    /* PLEASE DON'T PRELOAD ANY DATA IN THIS BLOCK.
       PLEASE MAKE YOUR CALLS IN PARALLEL.
       THIS SECTION WAS ONLY ADDED FOR CRITICAL CSS KEY DISPATCH. */
    return preloaderResponse;
  });
};

export const pageDefinitionConfiguration = {
  name: SRP_PAGE_NAME,
  category: 'car_inventory',
  seo: getSrpSeoObject,
  preload: getPreload,
  component: UsurpContainer,
  decorator: EdmundsCpaNoAdhesionDecorator,
  options: {
    disableScrollToTop: true,
    hasVehicleContext: true,
    vehicleContextChange: true,
    adsSpaMode: true,
    disableAdRefreshOnScroll: true,
  },
};

export default pageDefinition(pageDefinitionConfiguration);
