import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Card from 'reactstrap/lib/Card';
import Button from 'reactstrap/lib/Button';
import { getModelIncentivesLink } from 'site-modules/shared/utils/incentives-link-constructor';
import { Link } from 'site-modules/shared/components/link/link';

import './find-incentives-banner.scss';

export function FindIncentivesBannerUI({ zipCode, makeName, modelName, makeSlug, modelSlug, year, isPreprod }) {
  if (isPreprod) {
    return null;
  }

  return (
    <Card className="py-1_5 px-1 find-incentives-banner" tag="section">
      <header className="heading-4 mb-0_25">Find Deals Near {zipCode}</header>
      <small className="small text-gray-dark mb-0_75">
        Check out current offers on the {makeName} {modelName}
      </small>
      <Button
        tag={Link}
        to={getModelIncentivesLink(makeSlug, modelSlug, year)}
        color="primary-b"
        size="lg"
        className="px-1_5 py-0_5 me-auto text-transform-none"
      >
        See offers
      </Button>
    </Card>
  );
}

FindIncentivesBannerUI.propTypes = {
  zipCode: PropTypes.string,
  makeName: PropTypes.string,
  makeSlug: PropTypes.string,
  modelName: PropTypes.string,
  modelSlug: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPreprod: PropTypes.bool,
};

FindIncentivesBannerUI.defaultProps = {
  zipCode: null,
  makeName: null,
  makeSlug: null,
  modelName: null,
  modelSlug: null,
  year: null,
  isPreprod: false,
};

export const mapStateToProps = state => ({
  zipCode: get(state, 'visitor.location.zipCode'),
});

export const FindIncentivesBanner = connect(mapStateToProps)(FindIncentivesBannerUI);
