import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { getPotentialRebatesSum } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-potential-rebates-sum';
import { InventoryEvInsightsEntities } from 'client/data/models/inventory-ev-insights';
import { IncentivesWizardEntities } from 'client/data/models/incentives-wizard';
import { buildEligibleIncentives } from 'site-modules/shared/components/incentives/incentives-wizard/utils/build-eligible-incentives';
import { RebatesAmount } from './rebates-amount/rebates-amount';

export function SrpTopIncentivesText({ incentives, className, textColorClassName, wizardState }) {
  const eligibleIncentives = isEmpty(wizardState)
    ? incentives
    : buildEligibleIncentives(incentives, wizardState.eligibleIncentiveIds);
  const totalRebates = getPotentialRebatesSum(eligibleIncentives);

  return (
    <h2
      className={classnames('mb-0 fw-bold', textColorClassName, className)}
      style={{ lineHeight: '1.5rem', fontSize: '1.25rem' }}
    >
      Save up to <RebatesAmount value={wizardState && totalRebates} /> with EV Tax Credits & Rebates
    </h2>
  );
}

SrpTopIncentivesText.propTypes = {
  incentives: InventoryEvInsightsEntities.EvIncentivesData.isRequired,
  className: PropTypes.string,
  textColorClassName: PropTypes.string,
  wizardState: IncentivesWizardEntities.IncentivesWizardUnwrappedPersistedState,
};
SrpTopIncentivesText.defaultProps = {
  className: null,
  textColorClassName: 'text-cool-gray-10',
  wizardState: undefined,
};
