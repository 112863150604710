import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';

import './usurp-save-search.scss';

export function UsurpSaveSearch({ disabled, onClick, isSaved, trackingId, trackingValue, isLoading }) {
  return (
    <Button
      className={classnames(
        'save-search pos-r d-flex justify-content-center align-items-center text-capitalize fw-normal border-0 p-0',
        {
          'text-cool-gray-30': !isSaved,
          'text-blue-30': isSaved,
        }
      )}
      onClick={onClick}
      aria-label="Save Search"
      data-tracking-id={trackingId}
      data-tracking-value={trackingValue}
      disabled={disabled}
    >
      <i className={classnames('size-14 me-0_5', isSaved ? 'icon-heart3' : 'icon-heart4')} aria-hidden />
      <span className="size-14 text-underline">{isSaved ? 'Saved' : 'Save Search'}</span>
      {isLoading && (
        <span className="d-flex justify-content-center align-items-center bg-cool-gray-90 pos-a top-0 left-0 w-100 h-100">
          <Spinner color="primary-darker" size={16} />
        </span>
      )}
    </Button>
  );
}

UsurpSaveSearch.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isSaved: PropTypes.bool,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  isLoading: PropTypes.bool,
};

UsurpSaveSearch.defaultProps = {
  disabled: false,
  isSaved: false,
  trackingId: null,
  trackingValue: null,
  isLoading: false,
};
