import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { ALLOWED_HISTORY_PROVIDERS } from 'site-modules/shared/constants/inventory/vehicle-history';
import { transformVehicleHistory } from 'site-modules/shared/utils/inventory/srp-card-utils';

export function SrpCardVehicleHistory({ historyInfo, isSrpExpanded }) {
  const { historyProvider } = historyInfo;

  if (!ALLOWED_HISTORY_PROVIDERS.includes(historyProvider))
    return <p className="text-gray-dark small fw-bold bg-white">AutoCheck Vehicle History Summary Unavailable.</p>;

  const {
    personalUseOnly,
    salvageHistory,
    frameDamage,
    lemonHistory,
    theftHistory,
    ownerText,
    accidentText,
    usageType,
    cleanTitle,
    freeHistoryReport,
    noAccidents,
  } = transformVehicleHistory(historyInfo);

  const entries = compact([
    {
      label: 'Accident Free Vehicle',
      value: noAccidents,
    },
    !isSrpExpanded && {
      label: 'Accident History',
      value: accidentText,
    },
    !isSrpExpanded && {
      label: 'Number of Previous Owners',
      value: ownerText,
    },
    {
      label: 'Personal Use Only',
      value: personalUseOnly,
    },
    !isSrpExpanded && {
      label: 'Usage Type',
      value: usageType,
    },
    {
      label: 'History Provider',
      value: 'AutoCheck',
    },
    {
      label: 'Title Details',
      value: cleanTitle,
    },
    {
      label: 'Salvage Vehicle',
      value: salvageHistory,
    },
    {
      label: 'Frame Damage',
      value: frameDamage,
    },
    {
      label: 'Theft History',
      value: theftHistory,
    },
    {
      label: 'Lemon Status',
      value: lemonHistory,
    },
    {
      label: 'Free History Report',
      value: freeHistoryReport,
    },
  ]);

  return (
    <section className="mb-1">
      <h3 className="heading-5 text-gray-dark mb-1 mt-0_5 bg-white">AutoCheck Vehicle History Summary</h3>
      <Row>
        {entries.map(({ label, value }) => (
          <Col xs={12} md={6} tag="p" className="size-12 mb-0_5" key={label}>
            <b>{label}:</b> {value}
          </Col>
        ))}
      </Row>
    </section>
  );
}

SrpCardVehicleHistory.propTypes = {
  historyInfo: PropTypes.shape({}).isRequired,
  isSrpExpanded: PropTypes.bool,
};

SrpCardVehicleHistory.defaultProps = {
  isSrpExpanded: false,
};
