import { LEASE_PAYMENT, LOAN_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { TRANSACTION_TYPES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/transaction-types';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';

export const getPageTransactionType = (inventoryType, paymentType) => {
  if (paymentType === LEASE_PAYMENT) {
    return TRANSACTION_TYPES.LEASE;
  }

  return paymentType === LOAN_PAYMENT && isNew(inventoryType) ? TRANSACTION_TYPES.FINANCE : TRANSACTION_TYPES.CASH;
};
