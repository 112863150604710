import React from 'react';
import PropTypes from 'prop-types';
import { UnorderedListItem } from 'site-modules/shared/components/unordered-list/unordered-list';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export function EditorialReviewBullets({
  listItems,
  listItemClassName,
  listItemIconClasses,
  listItemStyles,
  listItemAriaLabel,
}) {
  return listItems.map(item => (
    <UnorderedListItem
      key={item}
      textClasses="text-gray-darker"
      listItemClasses={listItemClassName}
      listItemStyles={listItemStyles}
    >
      <i className={listItemIconClasses} style={{ position: 'absolute' }} aria-hidden />
      {listItemAriaLabel && <span className="visually-hidden">{`${listItemAriaLabel}:`}</span>}
      <ContentFragment componentToUse="span">{item}</ContentFragment>
    </UnorderedListItem>
  ));
}

EditorialReviewBullets.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string),
  listItemAriaLabel: PropTypes.string,
  listItemClassName: PropTypes.string,
  listItemIconClasses: PropTypes.string,
  listItemStyles: PropTypes.objectOf(PropTypes.string),
};

EditorialReviewBullets.defaultProps = {
  listItems: [],
  listItemAriaLabel: undefined,
  listItemClassName: 'ps-2 size-16',
  listItemIconClasses: 'icon-checkmark',
  listItemStyles: undefined,
};
