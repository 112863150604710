import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';

import { getCurrentYear } from 'site-modules/shared/utils/time-util';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import {
  getInventoryBadgeText,
  INVENTORY_BADGE_LABELS,
} from 'site-modules/shared/utils/inventory/usurp-inventory-card';
import './inventory-badge-srp.scss';

const INVENTORY_ICON_BADGES_MAP = {
  [INVENTORY_BADGE_LABELS.PRICE_DROP]: {
    icon: 'icon-arrow-down8',
    iconClassname: 'text-green-50 size-10',
    background: 'bg-green-100',
  },
  [INVENTORY_BADGE_LABELS.ARRIVING_SOON]: {
    icon: 'icon-clock',
    iconClassname: 'text-blue-50 size-10',
    background: 'bg-blue-100',
  },
  [INVENTORY_BADGE_LABELS.NEW_LISTING]: {
    icon: 'icon-sparkles',
    iconClassname: 'text-yellow-20 size-14',
    background: 'bg-yellow-100',
  },
  [INVENTORY_BADGE_LABELS.EV_REBATE]: {
    icon: 'icon-good-deal',
    iconClassname: 'text-turquoise-20 size-14',
    background: 'bg-turquoise-100',
    tooltip: {
      label: INVENTORY_BADGE_LABELS.EV_REBATE,
      text:
        'Certain EVs and PHEVs may be eligible for a federal used EV tax credit up to $4,000. Buyer and car must qualify.',
    },
  },
};

const isEvRebateBadgeApplicable = vinInfo =>
  get(vinInfo, 'vehicleInfo.partsInfo.engineType') === 'electric' &&
  get(vinInfo, 'vehicleInfo.styleInfo.year') <= getCurrentYear() - 2 &&
  get(vinInfo, 'prices.advertisedPrice') <= 25000 &&
  +get(vinInfo, 'historyInfo.ownerText') === 1;

export function InventoryBadgeSrp({ vinInfo, showInTransit }) {
  let text = getInventoryBadgeText(vinInfo, showInTransit);
  if (isEvRebateBadgeApplicable(vinInfo)) {
    text = INVENTORY_BADGE_LABELS.EV_REBATE;
  }

  if (!text) return null;
  const { icon, iconClassname, background, tooltip } = INVENTORY_ICON_BADGES_MAP[text];

  return (
    <span
      className={classnames('inventory-badge-srp size-12 fw-medium text-cool-gray-30 px-0_5 py-0_25', background)}
      style={{ position: 'absolute' }}
    >
      {icon && <i className={classnames(icon, iconClassname, 'me-0_25')} aria-hidden />}
      {text}
      {tooltip && (
        <TooltipItem
          id={vinInfo.vid}
          className="top-0"
          item={{ text: tooltip.text, placement: 'bottom' }}
          screenReaderTooltipLabel={tooltip.label}
        />
      )}
    </span>
  );
}

InventoryBadgeSrp.propTypes = {
  vinInfo: PropTypes.shape({}).isRequired,
  showInTransit: PropTypes.bool,
};

InventoryBadgeSrp.defaultProps = {
  showInTransit: false,
};
