import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ellipsisTruncate } from 'client/utils/string-utils';

function TopFeatureItem({ name }) {
  return (
    <li
      key={name}
      className="align-items-center bg-green-90 me-0_5 mb-0_5"
      style={{ height: '20px', borderRadius: '10px', flex: '1 0 auto', display: 'inline-flex' }}
    >
      <span className="bg-white-opacity-40 text-nowrap px-0_5 py-0_25 size-12">{ellipsisTruncate(name, 20)}</span>
    </li>
  );
}

TopFeatureItem.propTypes = {
  name: PropTypes.string,
};

TopFeatureItem.defaultProps = {
  name: null,
};
export function TopFeatureBubbles({ topFeatures, className }) {
  if (!topFeatures.length) return null;

  return (
    <ul
      className={classnames('list-unstyled text-cool-gray-30 fw-medium', className)}
      style={{
        height: '57px',
        overflow: 'hidden',
      }}
    >
      {topFeatures.map(({ name }) => (
        <TopFeatureItem key={name} name={name} />
      ))}
    </ul>
  );
}

TopFeatureBubbles.propTypes = {
  topFeatures: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

TopFeatureBubbles.defaultProps = {
  topFeatures: [],
  className: null,
};
