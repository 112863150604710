import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EditorialReviewEntities, FirstContentEntity } from 'client/data/models/editorial-review';
import { buildWhatsNewPath, VehicleComparisonsEntities, VehicleDetailsModel } from 'client/data/models/mmy-details';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { Link } from 'site-modules/shared/components/link/link';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';
import { EditorialReviewBullets } from 'site-modules/shared/components/editorial-review-pros-cons/editorial-review-bullets';

import './editorial-highlights-lists.scss';

export const HIGHLIGHTS_NAME = {
  PROS: 'view_pros',
  CONS: 'view_cons',
  WHATS_NEW: 'view_whats_new',
  WHAT_TO_EXPECT: 'view_what_to_expect',
  RECOMMENDED_TRIM: 'view_recommended_trim',
  TRIM_SRP: 'view_trim_srp_link',
};

const LIST_ITEM_CLASS_NAME = 'ps-2 size-16 mb-0_5';
const CIRCLE_ICON_CLASS_NAME = 'icon-circle2 p-0_5 pt-0_5';

export function EditorialHighlightsListsUI({
  editorialReview,
  firstContent,
  whatsNewContent,
  recommendedTrimName,
  recommendedTrimSrpLink,
  className,
  headingClassName,
  sectionsToRender,
  isPreprod,
  HeadingTag,
  core6457Recipe,
}) {
  const cons = get(editorialReview, 'cons', []);
  const pros = get(editorialReview, 'pros', []);
  const hasHighlights = cons.length || pros.length || get(whatsNewContent, 'length');
  const whatToExpect = get(firstContent, 'bullets.value', []);

  const sections = hasHighlights
    ? [
        {
          name: HIGHLIGHTS_NAME.PROS,
          text: 'Pros',
          data: pros,
          iconClasses: 'icon-checkmark text-primary-darker size-12 ps-0_25 pt-0_25',
        },
        {
          name: HIGHLIGHTS_NAME.CONS,
          text: 'Cons',
          iconClasses: 'icon-cross2 text-danger size-12 ps-0_25 pt-0_25',
          data: cons,
        },
        {
          name: HIGHLIGHTS_NAME.WHATS_NEW,
          text: "What's new",
          data: whatsNewContent,
          iconClasses: CIRCLE_ICON_CLASS_NAME,
        },
      ]
    : [
        {
          name: HIGHLIGHTS_NAME.WHAT_TO_EXPECT,
          text: isPreprod ? 'What to expect' : 'What You Need to Know',
          data: whatToExpect,
          iconClasses: CIRCLE_ICON_CLASS_NAME,
        },
      ];

  const renderedSections = [
    ...sections,
    { name: HIGHLIGHTS_NAME.RECOMMENDED_TRIM, data: recommendedTrimName, core6457: ['chal2', 'chal3'] },
    { name: HIGHLIGHTS_NAME.TRIM_SRP, data: recommendedTrimSrpLink, core6457: ['chal3'] },
  ].filter(
    ({ name, data, core6457 }) =>
      (!core6457 || core6457.includes(core6457Recipe)) && sectionsToRender.includes(name) && data?.length
  );

  if (!renderedSections.length) {
    return null;
  }

  return (
    <div
      className={classnames('editorial-highlights-lists', className, {
        row: core6457Recipe.includes('chal'),
      })}
    >
      {core6457Recipe === 'ctrl'
        ? renderedSections.map(({ name, text, iconClasses, data }, index) => (
            <Fragment key={`${name}-list`}>
              <HeadingTag id={name} className={classnames(headingClassName, { 'mt-1_5': index })}>
                {text}
              </HeadingTag>
              <ul className="list-unstyled mb-0" aria-labelledby={name} data-tracking-parent={name}>
                <EditorialReviewBullets
                  listItems={data}
                  listItemClassName={classnames('pos-r', LIST_ITEM_CLASS_NAME)}
                  listItemIconClasses={classnames('left-0', iconClasses)}
                  listItemStyles={{ paddingLeft: '2rem' }}
                />
              </ul>
            </Fragment>
          ))
        : renderedSections.map(({ name, text, iconClasses, data }, index) => (
            <Col
              xs={12}
              md={[HIGHLIGHTS_NAME.PROS, HIGHLIGHTS_NAME.CONS].includes(name) ? 6 : undefined}
              className={classnames({
                'mt-1_5': index,
                'mt-md-0': [HIGHLIGHTS_NAME.PROS, HIGHLIGHTS_NAME.CONS].includes(name),
                'pe-md-1 border-md-end': name === HIGHLIGHTS_NAME.PROS,
                'ps-md-1': name === HIGHLIGHTS_NAME.CONS,
              })}
              key={`${name}-list`}
            >
              {!!Array.isArray(data) && (
                <Fragment>
                  <HeadingTag id={name} className={headingClassName}>
                    {text}
                  </HeadingTag>
                  <ul className="list-unstyled mb-0" aria-labelledby={name} data-tracking-parent={name}>
                    <EditorialReviewBullets
                      listItems={data}
                      listItemClassName={classnames('pos-r', LIST_ITEM_CLASS_NAME)}
                      listItemIconClasses={classnames('left-0', iconClasses)}
                      listItemStyles={{ paddingLeft: '2rem' }}
                    />
                  </ul>
                </Fragment>
              )}
              {name === HIGHLIGHTS_NAME.RECOMMENDED_TRIM && (
                <Fragment>
                  <HeadingTag id={name} className={headingClassName}>
                    Edmunds Recommended Trim
                  </HeadingTag>
                  <div>
                    Our editors recommend the <strong>{recommendedTrimName}</strong> trim for the best balance of
                    features, performance, and value.{' '}
                    <ScrollLink
                      className="text-blue-40 text-underline"
                      to="recommended-trim"
                      scrollConfig={{ offset: SCROLL_OFFSET }}
                      data-tracking-id="view_recommended_trim"
                    >
                      See why we chose it
                    </ScrollLink>
                  </div>
                </Fragment>
              )}
              {name === HIGHLIGHTS_NAME.TRIM_SRP && (
                <div className="text-center">
                  <Button
                    size="lg"
                    color="outline-blue-40"
                    className="rounded-8 fw-medium px-1 py-0_5"
                    tag={Link}
                    to={recommendedTrimSrpLink}
                    data-tracking-id="recommended_trim_srp_link"
                  >
                    See all {recommendedTrimName} for sale
                  </Button>
                </div>
              )}
            </Col>
          ))}
    </div>
  );
}

EditorialHighlightsListsUI.propTypes = {
  firstContent: FirstContentEntity,
  editorialReview: EditorialReviewEntities.EditorialReview,
  whatsNewContent: VehicleComparisonsEntities.WhatsNewContent,
  recommendedTrimName: PropTypes.string,
  recommendedTrimSrpLink: PropTypes.string,
  className: PropTypes.string,
  headingClassName: PropTypes.string,
  HeadingTag: PropTypes.string,
  sectionsToRender: PropTypes.arrayOf(PropTypes.oneOf(Object.values(HIGHLIGHTS_NAME))),
  isPreprod: PropTypes.bool,
  core6457Recipe: PropTypes.string,
};

EditorialHighlightsListsUI.defaultProps = {
  firstContent: null,
  editorialReview: null,
  whatsNewContent: null,
  recommendedTrimName: null,
  recommendedTrimSrpLink: null,
  className: null,
  headingClassName: 'heading-4 mb-0_5',
  sectionsToRender: [
    HIGHLIGHTS_NAME.PROS,
    HIGHLIGHTS_NAME.CONS,
    HIGHLIGHTS_NAME.WHATS_NEW,
    HIGHLIGHTS_NAME.WHAT_TO_EXPECT,
  ],
  HeadingTag: 'div',
  isPreprod: false,
  core6457Recipe: 'ctrl',
};

export const propsAreEqual = (prevProps, { editorialReview, firstContent }) => !(editorialReview || firstContent);
export const EditorialHighlightsLists = connectToModel(React.memo(EditorialHighlightsListsUI, propsAreEqual), {
  whatsNewContent: bindToPath(
    ({ params, sectionsToRender }) =>
      (!sectionsToRender || sectionsToRender.includes(HIGHLIGHTS_NAME.WHATS_NEW)) && buildWhatsNewPath(params),
    VehicleDetailsModel
  ),
});
