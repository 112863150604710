import { compact } from 'lodash';
import { parsePersistedIncentiveId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/persisted-incentive-id';

const findIncentiveByPersistedIncentiveId = (incentives, persistedIncentiveId) => {
  const { subtypeId, grantor } = parsePersistedIncentiveId(persistedIncentiveId);
  return incentives.find(
    fullIncentive =>
      fullIncentive.subtypeId === subtypeId && fullIncentive.grantor.toLowerCase() === grantor.toLowerCase()
  );
};

const unpackIncentive = (incentiveId, incentives) => {
  const { eligibilityFactorId } = parsePersistedIncentiveId(incentiveId);
  const incentive = findIncentiveByPersistedIncentiveId(incentives, incentiveId);

  if (!incentive) {
    return incentive;
  }

  return {
    ...incentive,
    rebateOverridesByEligibility:
      incentive.rebateOverridesByEligibility?.filter(
        override => override.eligibilityFactor?.id === eligibilityFactorId
      ) || [],
  };
};

export const unpackPersistedIncentives = (wizardState, incentives) => {
  const { incentives: persistedIncentives, eligibleIncentiveIds: persistedEligibleIncentiveIds } = wizardState;
  const unpackedIncentives = compact(persistedIncentives.map(incentiveId => unpackIncentive(incentiveId, incentives)));

  return {
    ...wizardState,
    incentives: unpackedIncentives,
    eligibleIncentiveIds: compact(
      persistedEligibleIncentiveIds.map(
        persistedEligibleIncentiveId =>
          findIncentiveByPersistedIncentiveId(unpackedIncentives, persistedEligibleIncentiveId)?.id
      )
    ),
  };
};
