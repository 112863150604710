import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { merge } from 'site-modules/shared/components/lead-form/utils/merge';

import { LEAD_TYPES, SUB_HEADER_COMPONENT } from 'site-modules/shared/constants/lead-form/lead-form-params';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { UnifiedLeadFormAsync } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-async';
import { getPathname, getLeadSubmissionInfoWithoutEmail } from 'site-modules/shared/components/lead-form/utils/utils';
import {
  sendMarketoThankYouEmail,
  sendUsedInventoryMarketoThankYouEmail,
} from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
import { getLeadFormCreativeId } from 'client/site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { CarBuyingAPI } from 'client/data/api/api-client';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { getApiHeaders } from 'site-modules/shared/components/lead-form/unified-lead-form-pages/digital-retail-lead-form-utils';
import { formatAdjustedDealAttributes } from 'site-modules/shared/components/profile/attributes/deals';
import { getSubmissionInfo } from 'site-modules/shared/utils/car-buying/submit-lead-receipt-helper';
import { getCarZingTags } from 'site-modules/shared/components/inventory/utils/carzing';

/* eslint-disable no-template-curly-in-string */
export function UciLeadForm({ params, options }) {
  const {
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    pageNameLegacy,
    isNewVin,
    formName,
    isDRLeadFormSubmitAvailable,
    visitorId,
    deals,
    queryParams,
    partnerCode,
  } = params;
  const buyonline = get(options, 'buyonline');
  const shouldUpdateLFCopy = get(options, 'shouldUpdateLFCopy');
  const headerText = shouldUpdateLFCopy ? 'Get Dealer Offer' : 'Check Availability';
  const explainingText = shouldUpdateLFCopy
    ? 'Enter your contact information to get this dealer’s offer before you head to the dealership!'
    : 'Please provide your contact info so the dealership can confirm the availability of this vehicle.';

  const customConfig = {
    leadType: LEAD_TYPES.INVENTORY,
    creativeId: getLeadFormCreativeId({
      isNewVin,
      pageNameLegacy,
      formName: formName || LEAD_FORMS_CREATIVE_ID.SINGLE_BASE_VDP_FORM,
    }),
    leadFormConfig: {
      disableInventoryCard: true,
      headerConfig: {
        header: {
          text: buyonline ? 'Request Information' : headerText,
        },
        subHeaderComponent: SUB_HEADER_COMPONENT.BASE,
        subHeader: {
          explainingText: buyonline
            ? `A ${get(params, 'dealerName')} agent will answer your questions.`
            : explainingText,
        },
      },
      tcpaParentDealershipName: get(options, 'tcpaParentDealershipName'),
      options: {
        layoutOptions: {
          userInfoColSize: { xs: 12, md: 8 },
        },
      },
      userInfoOptions: {
        isLabelInside: true,
      },
      ...(buyonline && { submitButtonText: 'Request Information' }),
    },
    thankYouConfig: {
      headerConfig: {
        subHeaderComponent: SUB_HEADER_COMPONENT.BASE,
        subHeader: {
          text: 'You’re All Set',
          explainingText: 'Your request has been sent and the dealer should follow up shortly.',
          insiderLinkOut: true,
        },
      },
    },
    tags: getCarZingTags(partnerCode),
  };

  let optionsConfig = get(options, 'customConfig', {});

  optionsConfig = {
    ...optionsConfig,
    processDataMethods: {
      ...optionsConfig.processDataMethods,
      postProcessMethods: [
        ...get(optionsConfig, 'processDataMethods.postProcessMethods', []),
        isNewVin ? sendMarketoThankYouEmail : sendUsedInventoryMarketoThankYouEmail,
      ],
      getLeadSubmissionInfo: getLeadSubmissionInfoWithoutEmail,
    },
  };

  const adjustedCustomConfig = isDRLeadFormSubmitAvailable
    ? merge({}, customConfig, {
        apiParams: {
          leadSubmissionAPI: CarBuyingAPI,
          leadSubmissionURL: `/api/v1/receipt/?${objectToQueryString(queryParams)}`,
        },
        processDataMethods: {
          getInsiderSaveData: formatAdjustedDealAttributes(deals),
          getLeadSubmissionInfo: getSubmissionInfo(deals),
          getLeadSubmissionAPIHeaders: getApiHeaders(visitorId),
        },
      })
    : customConfig;

  const formProps = mergedCustomConfig => ({
    vin,
    make,
    model,
    year,
    submodel,
    submodelIdentifier,
    zipCode,
    radius,
    pathname: getPathname(options),
    customConfig: mergedCustomConfig,
  });

  const leadFormProps = formProps(merge(adjustedCustomConfig, optionsConfig));

  return <UnifiedLeadFormAsync {...leadFormProps} />;
}

UciLeadForm.propTypes = {
  params: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
};
