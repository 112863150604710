import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { EditorialRatingsEntities } from 'client/data/models/editorial-review';
import { breakSectionsWithEmbeds, getModelSectionMap } from 'site-modules/shared/utils/core-page/editorial-review';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Link } from 'site-modules/shared/components/link/link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { RatingBadge } from 'site-modules/shared/components/core-page/rating-badge/rating-badge';
import { RatingScorecardSection } from './rating-scorecard-section';

import './rating-scorecard.scss';

const INSERT_BEFORE = [
  { key: 'mpg', embed: 'filmstripModule' },
  { key: 'interior', embed: 'researchLinksModule' },
  { key: 'technology', embed: 'pricingModule' },
];
const VERDICT_KEY = 'verdict';

export function RatingScorecard({
  ratings,
  scale,
  makeName,
  modelName,
  edmundsTypeCategories,
  embeddedModules,
  showMobileSummary,
  isMobile,
  isCore6426Enabled,
}) {
  if (!get(ratings, 'overall.rating')) {
    return null;
  }

  const currentInsertBefore = INSERT_BEFORE.filter(({ embed }) => !!embeddedModules?.[embed]);
  const modelSectionMap = getModelSectionMap(modelName, edmundsTypeCategories);
  const renderMap = breakSectionsWithEmbeds(
    [
      { key: VERDICT_KEY },
      modelSectionMap.DRIVING,
      modelSectionMap.COMFORT,
      modelSectionMap.INTERIOR,
      modelSectionMap.TECHNOLOGY,
      modelSectionMap.UTILITY,
      modelSectionMap.MPG,
      modelSectionMap.VALUE,
      modelSectionMap.WILDCARD,
    ],
    currentInsertBefore
  );

  const ourVerdict = isCore6426Enabled ? (
    <Fragment key={VERDICT_KEY}>
      <h3 className="heading-4 mb-1">Edmunds Expert Rating</h3>
      <div className="d-flex flex-column flex-md-row-reverse align-items-center px-1 py-1 bg-white rounded-8 mb-1">
        <RatingBadge className="px-3 mb-2 mb-md-0" ratingScale={scale} editorialRating={ratings.overall.rating} />
        <div className="me-md-1">
          <div className="mb-1">
            Our rating for the {makeName} {modelName} is based on our experience driving the car at our test track and
            under real world conditions.
          </div>
          <Link
            to="/car-reviews/features/how-we-test-cars-and-trucks.html"
            rel="nofollow"
            className="d-inline-block medium text-blue-40"
            data-tracking-id="view_content"
          >
            <span className="text-underline">See How We Test</span>
            <i className="icon-arrow-right4 size-10 ms-0_25" aria-hidden />
          </Link>
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment key={VERDICT_KEY}>
      <div className="fw-bold mb-1">
        Our Verdict
        <TooltipItem
          id="rating-scorecard-tooltip"
          className="top-0"
          iconClassName="text-primary-darker medium"
          innerClassName="p-1"
          tooltipClassName="px-10"
          item={{
            placement: 'bottom',
            text:
              'The Edmunds Vehicle Testing Team evaluates a fresh batch of vehicles every week, pairing objective ' +
              'assessments at our test track with real-world driving on city streets, freeways and winding roads. The ' +
              'data we gather results in our Expert Ratings. They’re based on 30-plus scores that cover every aspect ' +
              'of the automotive experience.',
          }}
        />
      </div>
      <div className="d-flex flex-column flex-md-row align-items-center px-1 py-1 bg-white rounded-8 mb-1">
        <RatingBadge className="px-0_75" ratingScale={scale} editorialRating={ratings.overall.rating} />
        <div className="ms-md-0_5">
          {(!isMobile || showMobileSummary) && (
            <ContentFragment classes="review-section-fragment text-gray-darker size-16">
              {get(ratings, 'overall.text', '')}
            </ContentFragment>
          )}
          <em className="d-inline-block medium text-gray-darker text-center text-md-start mt-1_5">
            Rated for you by{' '}
            <Link
              to="/car-reviews/features/how-we-test-cars-and-trucks.html"
              className="text-underline text-primary-darker"
              data-tracking-id="view_content"
              rel="nofollow"
            >
              America&apos;s best test team
            </Link>
            .
          </em>
        </div>
      </div>
    </Fragment>
  );

  // TODO: Try to remove extra wrapper after core-6426
  return (
    <ScrollElement
      id="subnav-rating-scorecard"
      className="rating-scorecard mt-2 mb-2"
      data-tracking-parent="edm-entry-review-scorecard"
    >
      {!isCore6426Enabled && <h3 className="heading-4 mb-1_5">Edmunds Expert Rating</h3>}

      {renderMap.map(({ sections, embedAfter }, index) => {
        const creativeId = `edm-entry-review-scorecard${index + 1}`;

        return (
          <Fragment key={creativeId}>
            <div
              className="bg-cool-gray-90 rounded-12 px-1 py-1 mb-1_5"
              data-tracking-parent={index ? creativeId : undefined}
            >
              {sections.map(({ name, key, question }, sectionIndex) => {
                if (key === VERDICT_KEY) {
                  return ourVerdict;
                }

                if (!get(ratings, `[${key}].rating`)) {
                  return null;
                }

                const ratingQuestion = get(ratings, `[${key}].title`) || question;
                const ratingText = get(ratings, `[${key}].text`);
                const ratingEmbeds = get(ratings, `[${key}].embeds`);

                return (
                  <RatingScorecardSection
                    key={`scorecard-${key}`}
                    className={classnames({ 'mt-0_5': sectionIndex })}
                    sectionKey={key}
                    name={name}
                    scale={scale}
                    rating={ratings[key].rating}
                    content={`${ratingQuestion ? `${ratingQuestion} ` : ''}${ratingText}`}
                    embeds={ratingEmbeds}
                    isMobile={isMobile}
                  />
                );
              })}

              {!!embedAfter && (
                <em className="d-inline-block mt-1 medium text-cool-gray-40">
                  <i className="icon-arrow-down8 small me-0_5" aria-hidden /> Continue reading Edmunds Expert Rating
                  below
                </em>
              )}
            </div>
            {!!embedAfter && <div className="mb-1_5">{embeddedModules[embedAfter]}</div>}
          </Fragment>
        );
      })}
    </ScrollElement>
  );
}

RatingScorecard.propTypes = {
  ratings: EditorialRatingsEntities,
  scale: PropTypes.number,
  makeName: PropTypes.string,
  modelName: PropTypes.string,
  edmundsTypeCategories: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  embeddedModules: PropTypes.shape({
    pricingModule: PropTypes.node,
    filmstripModule: PropTypes.node,
    researchLinksModule: PropTypes.node,
  }),
  showMobileSummary: PropTypes.bool,
  isMobile: PropTypes.bool,
  isCore6426Enabled: PropTypes.bool,
};

RatingScorecard.defaultProps = {
  ratings: null,
  scale: 10,
  makeName: null,
  modelName: null,
  edmundsTypeCategories: null,
  embeddedModules: null,
  showMobileSummary: false,
  isMobile: false,
  isCore6426Enabled: false,
};
