import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-rebate';

export const checkIncentiveBelongsToCategory = (category, incentive) => {
  const categoryCheckersMap = {
    [CATEGORIES.FEDERAL]: [isFederalRebate],
    [CATEGORIES.LOCAL]: [isLocalRebate],
  };

  return categoryCheckersMap[category].some(checker => !!checker(incentive));
};
