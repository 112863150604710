import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InventoryEntities } from 'client/data/models/inventory';
import { SOURCES } from 'client/utils/image-helpers';
import { SrpCardPhotoOverlay } from 'site-modules/shared/components/inventory/srp-card/srp-card-photo-overlay';
import { InventoryHeart } from 'site-modules/shared/components/inventory-heart/inventory-heart';
import { trackShowSilhouette } from 'site-modules/shared/utils/inventory/tracking';
import { TrackingConstant } from 'client/tracking/constant';
import { PreventVerticalScrollOnSwipe } from 'site-modules/shared/components/carousel/prevent-vertical-scroll-on-swipe';
import { InventoryBadgeSrp } from 'site-modules/shared/components/usurp-inventory-card/inventory-badge-srp';
import { UsurpInventoryCardCarousel } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/usurp-inventory-card-carousel';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { isCpoMessageMake } from 'site-modules/shared/utils/cpo-message';
import { CpoLabel } from 'site-modules/shared/components/inventory/certified-pre-owned-message/cpo-label';
import { UsurpInventoryCardPhotoImage } from './usurp-inventory-card-photo-image';

import './usurp-inventory-card-photo.scss';

export const UsurpInventoryCardPhoto = ({
  photos,
  hasPhotos,
  provider,
  vehicle,
  visitorStateCode,
  isMobile,
  vin,
  photoType,
  vdpLink,
  clientSideRenderImage,
  lazyLoadImage,
  importance,
  isSimilarCards,
  isSrpExpanded,
  isViewed,
  showInventoryHeart,
  showInventoryBadge,
  tabIndex,
  showInTransit,
  heartAriaLabel,
  isLlmCard,
  searchId,
  isVdpPartnerListing,
  classNames,
  photoImgClassnames,
  isVinPreview,
  withAspectRatio,
  openLinksInNewTab,
  cpoData,
}) => {
  const fireTrackShowSilhouette = useCallback(() => {
    trackShowSilhouette(TrackingConstant.SHOW_SILHOUETTE_MISSING, vin);
  }, [vin]);

  const bodyType = vehicle?.vehicleInfo?.styleInfo?.bodyType ?? '';
  const make = vehicle?.vehicleInfo?.styleInfo?.make ?? '';

  const carouselEl = (
    <UsurpInventoryCardCarousel
      photos={photos}
      isMobile={isMobile}
      photoType={photoType}
      tabIndex={tabIndex}
      vehicle={vehicle}
      vdpLink={vdpLink}
      vin={vin}
      clientSideRenderImage={clientSideRenderImage}
      lazyLoadImage={lazyLoadImage}
      importance={importance}
      bodyType={bodyType}
      isLlmCard={isLlmCard}
      searchId={searchId}
      isSimilarCards={isSimilarCards}
      isVdpPartnerListing={isVdpPartnerListing}
      photoImgClassnames={photoImgClassnames}
      isVinPreview={isVinPreview}
      openLinksInNewTab={openLinksInNewTab}
    />
  );

  const imgEl = (
    <UsurpInventoryCardPhotoImage
      fireTracking={fireTrackShowSilhouette}
      vin={vin}
      isNoImage
      clientSideRenderImage={clientSideRenderImage}
      lazyLoadImage={lazyLoadImage}
      importance={importance}
      bodyType={bodyType}
      classNames={photoImgClassnames}
    />
  );

  return (
    <div
      className={classnames(
        'usurp-inventory-card-photo pos-r',
        {
          'desktop-photo': !isMobile,
          'similar-card': isSimilarCards,
          'srp-expanded': isSrpExpanded,
        },
        classNames
      )}
    >
      {showInventoryBadge &&
        (cpoData?.hasCpoMessage && isCpoMessageMake(make) ? (
          <CpoLabel cpoData={cpoData} />
        ) : (
          <InventoryBadgeSrp vinInfo={vehicle} showInTransit={showInTransit} />
        ))}
      {!isSimilarCards && showInventoryHeart && (
        <div className="photo-shadow text-end top w-100">
          <InventoryHeart
            vehicle={vehicle}
            visitorStateCode={visitorStateCode}
            classes={classnames('mb-0 p-0_5', { 'srp-expanded': isSrpExpanded, 'vin-preview': isVinPreview })}
            isSrp
            ariaLabel={heartAriaLabel}
          />
        </div>
      )}
      {hasPhotos ? (
        <Fragment>
          {isSimilarCards ? (
            <div>
              <UsurpInventoryCardPhotoImage
                imageId={photos[0]}
                source={SOURCES.EDMUNDSCDN}
                vin={vin}
                isLoadingStateDisabled
                lazyLoadImage
              />
            </div>
          ) : (
            <div className="usurp-inventory-card-photo-carousel pos-r">
              {withAspectRatio ? (
                <PreventVerticalScrollOnSwipe>
                  <AspectRatioContainer aspectRatio="3:2">{carouselEl}</AspectRatioContainer>
                </PreventVerticalScrollOnSwipe>
              ) : (
                carouselEl
              )}
            </div>
          )}
          <div className="w-100 photo-overlay-wrapper pos-a">
            <SrpCardPhotoOverlay provider={provider} />
          </div>
        </Fragment>
      ) : (
        <>{withAspectRatio ? <AspectRatioContainer aspectRatio="3:2">{imgEl}</AspectRatioContainer> : imgEl}</>
      )}
      {isViewed && (
        <div
          className="viewed-label bg-white text-gray-darker"
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            opacity: 0.7,
            fontSize: '0.875rem',
            padding: '8px 12px',
          }}
        >
          Viewed
        </div>
      )}
    </div>
  );
};

UsurpInventoryCardPhoto.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string),
  hasPhotos: PropTypes.bool,
  provider: PropTypes.string,
  vehicle: InventoryEntities.InventoryVin.isRequired,
  visitorStateCode: PropTypes.string,
  isMobile: PropTypes.bool,
  vin: PropTypes.string,
  photoType: PropTypes.string,
  vdpLink: PropTypes.string.isRequired,
  clientSideRenderImage: PropTypes.bool,
  lazyLoadImage: PropTypes.bool,
  importance: PropTypes.string,
  isSimilarCards: PropTypes.bool,
  isSrpExpanded: PropTypes.bool,
  isViewed: PropTypes.bool,
  showInventoryHeart: PropTypes.bool,
  showInventoryBadge: PropTypes.bool,
  tabIndex: PropTypes.number,
  showInTransit: PropTypes.bool,
  heartAriaLabel: PropTypes.string,
  isLlmCard: PropTypes.bool,
  searchId: PropTypes.string,
  isVdpPartnerListing: PropTypes.bool,
  classNames: PropTypes.string,
  photoImgClassnames: PropTypes.string,
  isVinPreview: PropTypes.bool,
  withAspectRatio: PropTypes.bool,
  openLinksInNewTab: PropTypes.bool,
  cpoData: PropTypes.shape({
    hasCpoMessage: PropTypes.bool,
    oemLogo: PropTypes.string,
  }),
};

UsurpInventoryCardPhoto.defaultProps = {
  photos: [],
  hasPhotos: false,
  provider: null,
  visitorStateCode: null,
  isMobile: false,
  vin: '',
  photoType: '',
  clientSideRenderImage: false,
  lazyLoadImage: false,
  importance: null,
  isSimilarCards: false,
  isSrpExpanded: false,
  isViewed: false,
  showInventoryHeart: true,
  showInventoryBadge: true,
  tabIndex: 0,
  showInTransit: false,
  heartAriaLabel: null,
  isLlmCard: false,
  searchId: undefined,
  isVdpPartnerListing: false,
  classNames: '',
  photoImgClassnames: '',
  isVinPreview: false,
  withAspectRatio: false,
  openLinksInNewTab: false,
  cpoData: null,
};
