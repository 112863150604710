import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { TRANSACTION_TYPE_OPTIONS } from 'site-modules/shared/constants/appraisal/appraisal';

import './transaction-type-select.scss';

/**
 * Renders transaction type select.
 * @param {function} onChange
 * @param {string} className
 * @param {string} selected
 * @param {string} labelText
 * @param {string} labelClassName
 * @returns {ReactElement}
 */
export function TransactionTypeSelect({ onChange, className, selected, labelText, labelClassName, isLabelHidden }) {
  return (
    <div className={classNames('transaction-type-select form-group', className)}>
      <StyledSelect
        name="transactionType"
        labelText={labelText}
        onChange={onChange}
        options={TRANSACTION_TYPE_OPTIONS}
        value={selected}
        className="select form-control text-gray-darker pe-2"
        labelClassName={labelClassName}
        isLabelHidden={isLabelHidden}
      />
    </div>
  );
}

TransactionTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selected: PropTypes.string,
  labelText: PropTypes.string,
  labelClassName: PropTypes.string,
  isLabelHidden: PropTypes.bool,
};

TransactionTypeSelect.defaultProps = {
  className: '',
  selected: '',
  labelText: 'Transaction type',
  labelClassName: 'mb-0 small text-gray-darker',
  isLabelHidden: false,
};
