import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'reactstrap'; // eslint-disable-line no-restricted-imports
import { noop, get } from 'lodash';
import { randomInt } from 'client/utils/seed-randomizers';

/**
 * Class creates info popover icon with tooltip.
 * @param id - id for tooltip
 * @param item.text - text/node for tooltip
 * @param item.placement - tooltip placement (top || right || bottom || left)
 */
export class TooltipItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      placement: PropTypes.string.isRequired,
      boundariesElement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }).isRequired,
    container: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    innerClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
    useUniqId: PropTypes.bool,
    autohide: PropTypes.bool,
    onTooltipOpen: PropTypes.func,
    delay: PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
    screenReaderTooltipLabel: PropTypes.string,
    tooltipContainerTag: PropTypes.string,
    trigger: PropTypes.string,
    renderTarget: PropTypes.func,
  };

  static defaultProps = {
    container: undefined,
    className: '',
    iconClassName: '',
    innerClassName: '',
    tooltipClassName: '',
    useUniqId: false,
    onTooltipOpen: noop,
    autohide: undefined,
    delay: undefined,
    screenReaderTooltipLabel: '',
    tooltipContainerTag: 'sup',
    trigger: undefined,
    renderTarget: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      focusinEvent: false,
    };

    this.uniqTooltipId = '';

    if (props.useUniqId) this.uniqTooltipId = randomInt();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.tooltipOpen && this.state.tooltipOpen && !this.state.focusinEvent) {
      this.props.onTooltipOpen({ id: this.props.id });
    }
  }

  tooltipElement = React.createRef();

  clickEvents = ['click', 'touchend'];

  lastTooltipOpenDelayTimeout = null;

  clickHandler = e => {
    if (this.tooltipElement && this.tooltipElement.current && this.tooltipElement.current === e.target) {
      e.stopPropagation();
    }

    this.setState({ tooltipOpen: false });
    this.clickEvents.forEach(event => document.body.removeEventListener(event, this.clickHandler));
  };

  registerCloseEvent = () => {
    this.clickEvents.forEach(event => document.body.addEventListener(event, this.clickHandler));
  };

  toggle = e => {
    if (get(e, 'persist')) {
      e.persist();
    }

    // TODO: Once we upgrade our Reactstrap to a version later than 8.0.0 with a fix for the issue,
    //  remove below delay based on Reactstrap community-suggested workaround to conflicting mobile event handlers.
    //  See: https://github.com/reactstrap/reactstrap/issues/1425 | EVAL-2117
    if (this.lastTooltipOpenDelayTimeout) {
      clearTimeout(this.lastTooltipOpenDelayTimeout);
    }
    this.lastTooltipOpenDelayTimeout = setTimeout(() => {
      this.setState(
        ({ tooltipOpen }) => ({ tooltipOpen: !tooltipOpen, focusinEvent: get(e, 'type') === 'focusin' }),
        () => {
          if (this.state.tooltipOpen) {
            this.registerCloseEvent();
          }
        }
      );
    }, 100); // Must be >10ms to work in Browserstack iPhone 11 Pro Safari (iOS 13)
  };

  render() {
    const {
      container,
      className,
      iconClassName,
      id,
      item: { placement, text, boundariesElement },
      innerClassName,
      tooltipClassName,
      delay,
      autohide,
      screenReaderTooltipLabel,
      renderTarget,
      tooltipContainerTag: TooltipContainerTag,
      trigger,
    } = this.props;
    const tooltipId = `tooltip${id}${this.uniqTooltipId}`;
    const isTooltipOpen = this.state.tooltipOpen;

    const targetProps = {
      id: tooltipId,
      role: 'button',
      'aria-label': `Tooltip${screenReaderTooltipLabel ? ` of ${screenReaderTooltipLabel}` : ''}`,
      'aria-describedby': `${tooltipId}-sr-content`,
      tabIndex: 0,
      onClick: this.toggle,
    };

    /*
     CAUTION:

     It is rarely recommended to disable jsx-a11y rules.  However, this is a case where it is better for a11y
     to go against the rules.

     >  "A tooltip is a popup that displays information related to an element when the element receives keyboard
     >  focus or the mouse hovers over it. It typically appears after a small delay and disappears when Escape
     >  is pressed or on mouse out."
     >
     >  - http://w3c.github.io/aria-practices/#tooltip

     However, what we are really building is a "toggletip":

     >  "Toggletips are like tooltips in the sense that they can provide supplementary or clarifying information.
     >  However, they differ by making the control itself supplementary: toggletips exist to reveal information
     >  balloons, and serve no other purpose.
     >
     >  Often they take the form of little "i" icons.
     >
     >  To work by touch just as well as by mouse or keyboard, toggletips are revealed by click rather than
     >  by hover and focus. Crucially, this means the aria-describedby association is no longer appropriate.
     >  Why? Because a screen reader user would have access to the information before pressing the button,
     >  so pressing it would appear not to do anything. Technically, they have access to the information,
     >  making the control "accessible" — but the control simply wouldn't make sense. In other words, it's
     >  a user experience issue more than a strict accessibility one, but it's important."
     >
     >  - https://inclusive-components.design/tooltips-toggletips/

     Why we disabled jsx-a11y/no-noninteractive-tabindex and jsx-a11y/no-noninteractive-element-interactions:

     1. We want to use 'i' as our element. Even though it is not technically semantic, it is a common practice.
     2. Therefore, in order for the tooltip to be keyboard accessible for sighted users, we need to add a tabindex.
     3. We want to use role="tooltip" and aria-label.  It is easier to understand when it is read by a screen reader.

     In a previous version we had role="button", but this makes it appear that the tooltip is something interactive.

     Imagine this example:
     ⓘ <== "Click on the model you’re interested in to see what Edmunds suggests you should pay to get a good deal"

     If we use role="button", a screen reader will read:
     "Button. Click on the model you’re interested in to see what Edmunds suggests you should pay to get a good deal"

     This sounds like you are on a button that you can click on to see "good deals".  But this is not the case.
     You are on a tooltip that will not do anything if you click on it.

     If we use role="tooltip", a screen reader will read:
     "Tooltip. Click on the model you’re interested in to see what Edmunds suggests you should pay to get a good deal"

     This is a better user experience, because the user will hear that they are focused on a tooltip and not
     a button.  They will search for the next (or previous) focusable element to show them "good deals", not the
     element they are focused on.
     */
    return (
      <TooltipContainerTag className={classnames('ms-0_25', className)}>
        {renderTarget ? (
          renderTarget(targetProps, this.tooltipElement)
        ) : (
          <i /* eslint-disable-line jsx-a11y/no-noninteractive-element-interactions */
            {...targetProps}
            ref={this.tooltipElement}
            className={classnames('icon-info info-popover-icon', iconClassName)}
          />
        )}
        {/* Added so that a screen reader can read tooltip text. */}
        <span role="tooltip" id={`${tooltipId}-sr-content`} className="visually-hidden">
          {text}
        </span>
        <Tooltip
          className={classnames('tooltip--white', tooltipClassName)}
          innerClassName={innerClassName}
          placement={placement}
          isOpen={isTooltipOpen}
          target={tooltipId}
          id={`${tooltipId}-container`}
          container={container}
          toggle={this.toggle}
          delay={delay}
          autohide={autohide}
          /* DO NOT REMOVE flip={false} UNTIL https://github.com/reactstrap/reactstrap/issues/1482 IS FIXED */
          flip={false} /* SEE: https://edmunds.atlassian.net/browse/PROD-394368 */
          boundariesElement={boundariesElement}
          trigger={trigger}
        >
          {text}
        </Tooltip>
      </TooltipContainerTag>
    );
  }
}
