import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { ZIP_CODE } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { getFacetTrackingParamsWithDefaultValue } from 'site-modules/shared/utils/inventory/tracking';
import { StyledZipInput } from 'site-modules/shared/components/styled-zip-input/styled-zip-input';
import { fireTrackingZipChange } from 'site-modules/shared/utils/fire-tracking-zip-change';
import { Input } from 'site-modules/shared/components/inventory/input/input';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

import './usurp-zip-input.scss';

export function UsurpZipInputUI({
  onZipCodeUpdate,
  isNational,
  className,
  isMobile,
  dataTrackingParent,
  errorTextClasses,
  isSHOP3464Chal,
}) {
  return (
    <div className="usurp-zip-input-wrapper pos-r" data-tracking-parent={dataTrackingParent || undefined}>
      <StyledZipInput
        styledWrapperClasses={classnames('usurp-zip-input', className, {
          'with-go-btn': isNational || isSHOP3464Chal,
          'shop-3464': isSHOP3464Chal,
        })}
        inputAriaLabel="Near ZIP"
        facetName={ZIP_CODE}
        onValidate={validation.validateZip}
        onZipChange={onZipCodeUpdate}
        getTracking={getFacetTrackingParamsWithDefaultValue}
        forceEmptyZip={isNational}
        placeholder={isNational ? 'ZIP' : ''}
        isMobile={isMobile}
        fireTrackingZipChange={fireTrackingZipChange}
        btnColor="primary-b"
        btnClassname="fw-medium btn-blue-50"
        inputComponent={Input}
        inputClasses="usurp-zip-input-control"
        inputSize=""
        errorTextClasses={errorTextClasses}
        withGoBtn={isSHOP3464Chal}
        withErrorText={isSHOP3464Chal}
      />
    </div>
  );
}

UsurpZipInputUI.propTypes = {
  onZipCodeUpdate: PropTypes.func.isRequired,
  isNational: PropTypes.bool,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  dataTrackingParent: PropTypes.string,
  errorTextClasses: PropTypes.string,
  isSHOP3464Chal: PropTypes.bool,
};

UsurpZipInputUI.defaultProps = {
  isNational: false,
  className: '',
  isMobile: false,
  dataTrackingParent: 'edm-entry-facets-zip_and_radius',
  errorTextClasses: 'text-nowrap',
  isSHOP3464Chal: false,
};

const mapStateToProps = state => ({
  isSHOP3464Chal:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'shop-3464-zip',
      defaultVal: 'ctrl',
    }) === 'chal',
});

export const UsurpZipInput = connect(mapStateToProps)(UsurpZipInputUI);
