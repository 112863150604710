import { gql } from '@apollo/client'; // eslint-disable-line
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { VisitorModel } from 'client/data/models/visitor';
import { InventoryModel } from 'client/data/models/inventory';
import { checkVinApplicableForFederalIncentives, getVinPath } from 'site-modules/shared/utils/inventory/vdp-utils';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import { CONTENT_TYPES, INCENTIVE_TRANSACTION_TYPES, PROGRAM_TYPES } from 'site-modules/shared/constants/incentives';
import { WARRANTY_TYPE } from 'site-modules/shared/constants/inventory/warranty';
import { FEDERAL_SUBTYPE_ID } from 'site-modules/shared/components/incentives/incentives-wizard/constants/subtype-ids';
import { getPageTransactionType } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-page-transaction-type';

const EvIncentivesData = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    programType: PropTypes.string,
    rebateAmount: PropTypes.number,
    restrictions: PropTypes.string,
    subtype: PropTypes.string,
    transactionTypes: PropTypes.arrayOf(PropTypes.string),
  })
);

const InventoryEvInsightsData = PropTypes.shape({
  style: PropTypes.shape({
    epaSpecifications: PropTypes.shape({
      electricityRange: PropTypes.number,
      batteryChargeTime: PropTypes.number,
    }),
    engine: PropTypes.shape({
      electricMotor: PropTypes.shape({
        fastChargePortType: PropTypes.string,
      }),
    }),
    batteryWarranty: PropTypes.shape({
      name: PropTypes.string,
      maxYears: PropTypes.string,
      comments: PropTypes.string,
      warrantyType: PropTypes.string,
      maxMileage: PropTypes.string,
      maxMileageType: PropTypes.string,
      maxYearsType: PropTypes.string,
    }),
    hybridComponentsWarranty: PropTypes.shape({
      maxYears: PropTypes.string,
      maxYearsType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
      maxMileage: PropTypes.string,
      maxMileageType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
    }),
  }),
  incentives: EvIncentivesData,
});

export const InventoryEvInsightsEntities = {
  InventoryEvInsightsData,
  EvIncentivesData,
};

const getInventoryEvInsightsDataPath = vin => vin && `vin["${vin}"].evInsights`;
const getEvInsightsAllProgramIncentivesDataPath = (vin, paymentType) =>
  vin && `vin["${vin}"].evInsightsAllProgramIncentives["${paymentType}"]`;

export const InventoryEvInsightsPaths = {
  getInventoryEvInsightsDataPath,
  getEvInsightsAllProgramIncentivesDataPath,
};

function buildStyleInfo(inventoryIncentives) {
  const style = get(inventoryIncentives, 'inventory.vinStyle.style', {});
  const warranty = get(style, 'warranty', []) || [];
  const batteryWarranty = warranty.find(({ warrantyType }) => warrantyType === WARRANTY_TYPE.EV_BATTERY);
  const hybridComponentsWarranty = warranty.find(({ warrantyType }) => warrantyType === WARRANTY_TYPE.HYBRID_COMPONENT);

  return {
    style: {
      ...style,
      warranty: undefined,
      batteryWarranty,
      hybridComponentsWarranty,
    },
  };
}

export const InventoryEvInsightsModel = createModelSegment('inventoryEvInsights', [
  {
    /**
     * @see getInventoryEvInsightsDataPath
     */
    path: 'vin["{vin}"].evInsights',
    async resolve({ vin }, context) {
      const [vinData, { zipCode }] = await Promise.all([
        context.resolveValue(getVinPath(vin), InventoryModel),
        context.resolveValue('location', VisitorModel),
      ]);

      const inventoryCodes = isNew(vinData.type) ? [INVENTORY_TYPES.NEW] : [INVENTORY_TYPES.USED, INVENTORY_TYPES.CPO];
      const styleId = get(vinData, 'vehicleInfo.styleInfo.styleId');
      if (!styleId) return {};

      const incentiveFilters = {
        zip: zipCode,
        engineTypes: ['electric', 'plug-in hybrid'],
        styleIds: [styleId],
        inventoryCodes,
        programTypesFilter: {
          in: [
            PROGRAM_TYPES.EV_REBATE,
            PROGRAM_TYPES.EV_CHARGING,
            PROGRAM_TYPES.TAX_CREDIT,
            PROGRAM_TYPES.UTILITY_REBATE,
            PROGRAM_TYPES.MUNICIPALITY_REBATE,
            PROGRAM_TYPES.CHARGER_INSTALLATION,
            PROGRAM_TYPES.STATE_REBATE,
          ],
        },
        types: { notIn: [CONTENT_TYPES.CUSTOMER_APR, CONTENT_TYPES.DEALER_CASH] },
        transactionTypes: {
          in: [INCENTIVE_TRANSACTION_TYPES.CASH, INCENTIVE_TRANSACTION_TYPES.LEASE],
        },
      };

      const data = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query($vin: String!, $incentiveFilters: IncentiveFilters!) {
            inventory(vin: $vin) {
              vinStyle {
                style {
                  epaSpecifications {
                    electricityRange
                    batteryChargeTime
                  }
                  engine {
                    electricMotor {
                      fastChargePortType
                    }
                  }
                  warranty {
                    name
                    maxYears
                    comments
                    warrantyType
                    maxMileage
                    maxMileageType
                    maxYearsType
                  }
                }
              }
            }
            incentives(incentiveFilters: $incentiveFilters) {
              incentives {
                id
                programType
                rebateAmount
                restrictions
                subtype
                transactionTypes
                subtypeId
              }
            }
          }
        `,
        { vin, incentiveFilters }
      );
      const incentives = get(data, 'incentives.incentives', []);

      return {
        ...buildStyleInfo(data),
        incentives,
      };
    },
  },
  {
    /**
     * @see getEvInsightsAllProgramIncentivesDataPath
     */
    path: 'vin["{vin}"].evInsightsAllProgramIncentives["{paymentType}"]',
    async resolve({ vin, paymentType }, context) {
      const vinData = await context.resolveValue(getVinPath(vin), InventoryModel);
      const zipCode = get(vinData, 'dealerInfo.address.zip');
      const inventoryCodes = isNew(vinData.type) ? [INVENTORY_TYPES.NEW] : [INVENTORY_TYPES.USED, INVENTORY_TYPES.CPO];
      const styleId = get(vinData, 'vehicleInfo.styleInfo.styleId');
      const engineType = get(vinData, 'vehicleInfo.partsInfo.engineType');
      if (!styleId) return {};

      const incentiveFilters = {
        zip: zipCode,
        engineTypes: engineType ? [engineType] : ['electric', 'plug-in hybrid'],
        styleIds: [styleId],
        inventoryCodes,
        types: { notIn: [CONTENT_TYPES.CUSTOMER_APR, CONTENT_TYPES.DEALER_CASH] },
        transactionTypes: {
          in: [getPageTransactionType(vinData.type, paymentType)],
        },
        subtypeIds: checkVinApplicableForFederalIncentives(vinData) ? undefined : { notIn: [FEDERAL_SUBTYPE_ID] },
      };

      const data = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query(
            $vin: String!
            $incentiveFilters: IncentiveFilters!
            $page: PageRequest!
            $sortBy: IncentiveAggregationByGrantorAndSubtypeSort!
          ) {
            inventory(vin: $vin) {
              vinStyle {
                style {
                  epaSpecifications {
                    electricityRange
                    batteryChargeTime
                  }
                  engine {
                    electricMotor {
                      fastChargePortType
                    }
                  }
                  warranty {
                    name
                    maxYears
                    comments
                    warrantyType
                    maxMileage
                    maxMileageType
                    maxYearsType
                  }
                }
              }
            }
            incentives(incentiveFilters: $incentiveFilters) {
              incentiveAggregations {
                byGrantorAndSubtype(page: $page, sortBy: $sortBy) {
                  topIncentiveByRebate {
                    programUrl
                    programType
                    subtype
                    subtypeId
                    rebateAmount
                    endDate
                    restrictions
                    id
                    type
                    transactionTypes
                    grantor
                    updateDate
                    compatibleIncentiveIds
                    primary
                    rebateOverridesByEligibility {
                      rebateAmount
                      eligibilityFactor {
                        qualifier
                        id
                        taxFilingStatus
                        maxIncome
                        householdSize
                        assistanceParticipant
                        qualifierDescription
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        {
          vin,
          incentiveFilters,
          page: { pageNum: 1, pageSize: 16 },
          sortBy: { topIncentiveByRebateAmount: 'DESC' },
        }
      );
      const incentives = get(data, 'incentives.incentiveAggregations.byGrantorAndSubtype', []).map(incentive =>
        get(incentive, 'topIncentiveByRebate')
      );

      return {
        ...buildStyleInfo(data),
        incentives,
      };
    },
  },
]);
