import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, unset, pick, has, omit, head, noop } from 'lodash';
import classNames from 'classnames';

// Models
import { InventoryEntities } from 'client/data/models/inventory';
import { VisitorModel, VisitorEntities } from 'client/data/models/visitor';
import { VehicleEntities } from 'client/data/models/vehicle-v2';

// Utils
import {
  getAllFacetsBySelectedValues,
  getFacetsBySelectedValues,
  getFilter,
  isUrlPatternEnabled,
  PERSIST_URL_PATHNAME_FILTERS,
} from 'site-modules/shared/utils/inventory/search-filter';
import { scrollToHash } from 'client/utils/scroll';
import {
  isLeaseSRP,
  isNationalSRP,
  isDealerSRP,
  isClearAllFilters,
} from 'site-modules/shared/utils/inventory/srp-type-checkers';
import {
  getCurrentPaymentType,
  getSelectedPaymentType,
} from 'site-modules/shared/utils/inventory-utils/get-current-payment-type';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { TrackingHandler } from 'client/tracking/handler';
import {
  buildCurrentUrlWithRandomParam,
  getFreshHistoryParam,
} from 'site-modules/shared/components/search-by-module/search-by-module-utils';
import { changeSrpRoute } from 'site-modules/inventory/utils/routes';
import { isParseableMMYUrl, isParseableUrl } from 'client/utils/parseable-url';
import { getQuery } from 'client/utils/location';
import { buildSearchFilterSEOObject } from 'site-modules/shared/utils/inventory/srp-seo-utils';
import { getAppliedRadius, getStaticFacet } from 'site-modules/shared/utils/inventory/srp-utils';
import { isAvailableLocation } from 'site-modules/shared/utils/financing/financing';

// Hooks
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';

// Constants
import {
  MAKE,
  MODEL,
  PAYMENT_TYPE,
  PAGE_NUMBER,
  RADIUS,
  CREDIT_PROVIDER,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { LEASE_PAYMENT, PREQUAL_MSG } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { SPR_SCROLL_TO_TOP_ANCHOR, SRP_BASE_PATHNAME } from 'site-modules/shared/constants/inventory/routes-constants';
import { GEO_TRACKING_PARAMS } from 'site-modules/inventory/constants/tracking';
import { TrackingMap } from 'client/tracking/maps/inventory/srp';
import { CORE_SRP_MMYS, CORE_SRP_URL_PATTERN } from 'site-modules/shared/constants/inventory/srp-url-patterns';
import { PAGE_EVENTS } from 'client/constants/page-events';
import {
  CREDIT_PROVIDER_FACET,
  RADIUS_FACET,
  RADIUS_FACET_EXTENDED,
} from 'site-modules/shared/constants/inventory/static-facets';

// Utils
import { venomHistory } from 'client/utils/history/venom-history';
import { isMultiMMInitial } from 'site-modules/shared/utils/inventory/is-multi-make-model-initial';
import convexMask from 'site-modules/shared/components/clipped-container/mask-library/usurp-header';

// Components
import { LoadingSpinner } from 'site-modules/shared/components/loading-spinner/loading-spinner';
import { GeoLocation } from 'site-modules/shared/components/geo-location/geo-location';
import { UsurpFiltersDrawer } from 'site-modules/shared/components/inventory/usurp-filters-drawer/usurp-filters-drawer';
import { UsurpFilters } from 'site-modules/shared/components/inventory/usurp-filters/usurp-filters';
import { UsurpErrorReload } from 'site-modules/inventory/components/usurp-error-reload/usurp-error-reload';
import { UsurpMainContent } from 'site-modules/inventory/components/usurp-main-content/usurp-main-content';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

import './usurp.scss';

const useUpdateMetaTags = ({ seoHeadContent }) => {
  const title = get(seoHeadContent, 'title');
  const description = get(seoHeadContent, 'description');

  useEffect(() => {
    if (title && title !== document.title) {
      document.title = title;
      document.querySelector("meta[name='title']").setAttribute('content', title);
      document.querySelector("meta[name='description']").setAttribute('content', description);
    }
  }, [title, description]);
};

const filterOutNonFlatUrlParams = (urlPattern, type, params) =>
  urlPattern && PERSIST_URL_PATHNAME_FILTERS.includes(type) ? pick(params, [PERSIST_URL_PATHNAME_FILTERS]) : params;

export const Usurp = memo(props => {
  const {
    seoHeadContent,
    isMobile,
    urlContext,
    visitorLocation,
    location,
    isSrpOnMakeType,
    makeModelSubmodelYear,
    match,
    zipCode,
    isSrpOnUsedCore,
    disableInventoryMLPhotoSort,
    disableGeoLocation,
    isCrawler,
    isPrequalified,
    inventoryData,
    setModelValue,
    selectedFacets,
    withCoreReviewSection,
    isSHOP1857Enabled,
    renderAsidePageContent,
    isVinPreview,
    disableDrivewayNewCarLeadForms,
    cpoData,
    isShop3181Chal,
    isSHOP3424Chal,
    isShop3165Enabled,
    modifiedZip,
  } = props;

  const [setRouteChangeTimeout] = useTimeout();
  const [setDrawerTimeout] = useTimeout();
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isMultiMM, setIsMultiMM] = useState(isMultiMMInitial(selectedFacets));
  const selectedFiltersBeforeDrawer = useRef();
  const drawerFiltersCancelled = useRef(false);
  const wasDrawerFacetsChanged = useRef(false);

  useEffect(() => {
    const fireExpandedRadius = () => {
      const isExpandedRadiusDueToLowResults = !!get(inventoryData, 'attributes.expandedRadiusDueToLowResults', false);
      const attrRadius = get(inventoryData, 'attributes.radius', '').toString();
      if (isExpandedRadiusDueToLowResults) {
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
          event_data: {
            action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
            action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
            action_name: TrackingConstant.ACTION_SHOW_CONTENT,
            subaction_name: 'expand_search_radius',
            creative_id: 'edm-entry-facets-zip_and_radius',
            value: attrRadius,
          },
        });
      }
    };

    TrackingHandler.useMap(TrackingMap);
    EventToolbox.on(PAGE_EVENTS.PAGE_LOAD, fireExpandedRadius);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = useCallback(() => {
    scrollToHash(`#${SPR_SCROLL_TO_TOP_ANCHOR}`, isVinPreview);
  }, [isVinPreview]);

  const closeFiltersDrawer = useCallback(() => {
    setDrawerTimeout(() => {
      setIsFilterDrawerOpen(false);
    }, 0);

    selectedFiltersBeforeDrawer.current = null;
    drawerFiltersCancelled.current = false;
  }, [setDrawerTimeout]);

  useEffect(() => {
    if (drawerFiltersCancelled.current) {
      closeFiltersDrawer();
    }

    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryData]);

  useUpdateMetaTags({ seoHeadContent });

  const radius = useMemo(() => getAppliedRadius({ inventoryData, visitorLocation, selectedFacets }), [
    inventoryData,
    selectedFacets,
    visitorLocation,
  ]);
  const seoData = useMemo(() => {
    const inventory = get(inventoryData, 'inventories.results', []);
    const { pageNumber, totalNumber: inventoryCount } = get(inventoryData, 'inventories', {});
    return buildSearchFilterSEOObject({
      searchResultsFilter: selectedFacets,
      urlContext,
      inventories: [head(inventory)],
      attributes: get(inventoryData, 'attributes', {}),
      inventoryCount,
      pageNumber,
    });
  }, [inventoryData, selectedFacets, urlContext]);

  const extendedFacets = useMemo(() => {
    const { isCarFinder } = seoData;
    const isNational = isNationalSRP(urlContext) || isCarFinder;

    // The reason we don’t show “national” radius is related to inventory sorting speed.
    // When there is no make or model selected, it is too slow to search through inventory for 6000 radius.
    const shouldBeRadiusFacetExtended = isNational || (!!selectedFacets[MAKE] && !!selectedFacets[MODEL]);
    const radiusFacetConfig = shouldBeRadiusFacetExtended ? RADIUS_FACET_EXTENDED : RADIUS_FACET;
    const radiusFacet = getStaticFacet(radiusFacetConfig, radius.toString());
    const visitorStateCode = get(visitorLocation, 'stateCode');
    const facets = get(inventoryData, 'facets', []);

    return isPrequalified && isAvailableLocation(visitorStateCode)
      ? [...facets, radiusFacet, CREDIT_PROVIDER_FACET]
      : [...facets, radiusFacet];
  }, [inventoryData, isPrequalified, radius, selectedFacets, seoData, urlContext, visitorLocation]);

  const filteringByFacets = useMemo(
    () => getFacetsBySelectedValues(getAllFacetsBySelectedValues(extendedFacets, selectedFacets)),
    [extendedFacets, selectedFacets]
  );

  const pathName = get(location, 'pathname');
  const routeParams = get(match, 'params', {});

  // Inventory data
  const { totalNumber: inventoryCount } = get(inventoryData, 'inventories', {});
  const attributes = get(inventoryData, 'attributes', {});
  const { isCarFinder } = seoData;
  const isNational = isNationalSRP(urlContext) || isCarFinder;
  const paymentType = getCurrentPaymentType(selectedFacets, get(attributes, 'paymentType'));
  const selectedPaymentType = getSelectedPaymentType(selectedFacets);
  const isDealerSrp = isDealerSRP(selectedFacets);
  const isLocationUserSet = get(visitorLocation, 'userSet', false);
  const isGeoLocationAvailable = !isLocationUserSet && !(urlContext && urlContext.location);
  const query = useMemo(() => getQuery(location), [location]);
  const userCapOnePrequalApproved = has(query, CREDIT_PROVIDER) && has(query, PREQUAL_MSG) && isPrequalified;

  const updateRouteOnNextPaint = useCallback(
    routeUpdater => {
      setIsLoading(true);
      requestAnimationFrame(() => {
        setRouteChangeTimeout(() => {
          routeUpdater();
        }, 0);
      });
    },
    [setRouteChangeTimeout]
  );

  const changeRoute = useCallback(
    (updatedFiltersData, { urlPathname } = { urlPathname: pathName }) => {
      updateRouteOnNextPaint(() => changeSrpRoute(updatedFiltersData, { urlPathname }));
    },
    [pathName, updateRouteOnNextPaint]
  );

  const handleUpdateFilter = useCallback(
    (type, facet, isChecked) => {
      const urlPattern = get(urlContext, 'urlPattern');
      const initialUrlPattern = get(selectedFacets, 'initialUrlPattern');
      const shouldPersistUrlPattern = isUrlPatternEnabled({ initialUrlPattern, urlContext });
      const currentFilter = { ...selectedFacets };
      const isClearAllFiltersAgain = !(type && facet) && isClearAllFilters(selectedFacets);

      if (shouldPersistUrlPattern) {
        currentFilter.initialUrlPattern = urlPattern || initialUrlPattern;
      }
      const isExpandedRadiusDueToLowResults = !!get(inventoryData, 'attributes.expandedRadiusDueToLowResults', false);
      const shouldPersistExpandedRadius =
        isExpandedRadiusDueToLowResults &&
        !isNationalSRP(urlContext) &&
        (isParseableUrl(pathName) || isParseableMMYUrl(pathName)) &&
        ![MAKE, MODEL, RADIUS].includes(type);
      const updatedFiltersData = filterOutNonFlatUrlParams(
        urlPattern,
        type,
        getFilter({
          type,
          facet,
          isChecked,
          currentFilter,
          isRedirectFromLeasePage: isLeaseSRP(urlContext),
          radius,
          shouldPersistExpandedRadius,
          isMultiMM,
          facets: get(inventoryData, 'facets', []),
          isSHOP1857Enabled,
        })
      );

      if (!isClearAllFiltersAgain) {
        if (isParseableUrl(buildCurrentUrlWithRandomParam(location))) {
          history.replaceState({}, '', buildCurrentUrlWithRandomParam(location));
        }

        /*
            For all flat urls (ie. urls with a urlPattern), if the user interacts with one of the
            filters in PERSIST_URL_PATHNAME_FILTERS, then we want to use the existing pathname of the url.
            We also want to persist the pathname for the `vin-preview-srp`.
            However, on most filter interactions, we will change the pathname to the
            SRP_BASE_PATHNAME (/inventory/srp.html).

          */
        const useCurrentPath = !(urlPattern && !PERSIST_URL_PATHNAME_FILTERS.includes(type));
        changeRoute(updatedFiltersData, {
          urlPathname: useCurrentPath ? pathName : SRP_BASE_PATHNAME,
        });
      }
    },
    [changeRoute, inventoryData, isMultiMM, isSHOP1857Enabled, location, pathName, radius, selectedFacets, urlContext]
  );

  const changeRouteOnZipUpdate = useCallback(() => {
    const baseParams = { ...selectedFacets, ...getFreshHistoryParam() };
    unset(baseParams, PAGE_NUMBER);

    const urlPattern = get(urlContext, 'urlPattern');
    if ([CORE_SRP_URL_PATTERN, CORE_SRP_MMYS].includes(urlPattern)) {
      baseParams.initialUrlPattern = urlPattern;
    }

    if (isParseableUrl(buildCurrentUrlWithRandomParam(location))) {
      history.replaceState({}, '', buildCurrentUrlWithRandomParam(location));
    }
    changeRoute(isLeaseSRP(urlContext) ? { ...baseParams, [PAYMENT_TYPE]: [LEASE_PAYMENT] } : { ...baseParams });
  }, [changeRoute, location, selectedFacets, urlContext]);

  const updateZipCode = useCallback(
    async newZipCode => {
      setIsLoading(true);
      try {
        await setModelValue('location', VisitorModel, { zipCode: newZipCode });
        return changeRouteOnZipUpdate();
      } catch (e) {
        setIsLoading(false);
        return Promise.reject(e);
      }
    },
    [changeRouteOnZipUpdate, setModelValue]
  );

  const handleOpenFiltersDrawer = useCallback(() => {
    selectedFiltersBeforeDrawer.current = selectedFacets;
    setIsFilterDrawerOpen(true);
  }, [selectedFacets]);

  const cancelFiltersUpdate = useCallback(() => {
    if (!isEqual(selectedFacets, selectedFiltersBeforeDrawer.current)) {
      changeRoute(selectedFiltersBeforeDrawer.current);
      drawerFiltersCancelled.current = true;
      return;
    }

    closeFiltersDrawer();
  }, [changeRoute, closeFiltersDrawer, selectedFacets]);

  const confirmFiltersUpdate = useCallback(() => {
    wasDrawerFacetsChanged.current = selectedFacets !== selectedFiltersBeforeDrawer.current;
    closeFiltersDrawer();
  }, [closeFiltersDrawer, selectedFacets]);

  const onDestroy = useCallback(() => {
    if (wasDrawerFacetsChanged.current) {
      scrollToTop();
    }
  }, [scrollToTop]);

  const onSuggestedFacetUpdate = useCallback(
    (...args) => {
      scrollToTop();
      handleUpdateFilter(...args);
    },
    [scrollToTop, handleUpdateFilter]
  );

  const onRemoveCapOnePrequalParam = useCallback(() => {
    changeRoute(omit(query, [PREQUAL_MSG]));
  }, [changeRoute, query]);

  const handleSetIsMultiMM = useCallback(isMulti => {
    setIsMultiMM(isMulti);
  }, []);

  const handlePaginationLink = useCallback(
    e => {
      const { currentTarget, ctrlKey, metaKey } = e;

      if (ctrlKey || metaKey) return;

      e.preventDefault();

      const disabledLink = currentTarget.hasAttribute('disabled');
      if (disabledLink) {
        return;
      }

      scrollToTop();
      updateRouteOnNextPaint(() => venomHistory.push(currentTarget.getAttribute('href')));
    },
    [scrollToTop, updateRouteOnNextPaint]
  );

  useEffect(() => {
    if (modifiedZip) {
      changeRouteOnZipUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedZip]);

  return (
    <div className="usurp pos-r bg-cool-gray-90 pt-1">
      <FeatureFlag name="disable-srp-llm">
        {disableLlm => (
          <ClippedContainer
            containerClasses={classNames('wave pos-a top-0 left-0 w-100', { 'llm-disabled': disableLlm })}
            mask={convexMask}
          />
        )}
      </FeatureFlag>
      {renderAsidePageContent()}
      <main id="main-content">
        <UsurpErrorReload />
        <LoadingSpinner isLoading={isLoading}>
          <UsurpMainContent
            disableInventoryMLPhotoSort={disableInventoryMLPhotoSort}
            isNational={isNational}
            inventoryData={inventoryData}
            visitorLocation={visitorLocation}
            paymentType={paymentType}
            selectedPaymentType={selectedPaymentType}
            radius={radius}
            isDealerSrp={isDealerSrp}
            isMobile={isMobile}
            facets={extendedFacets}
            selectedFacets={selectedFacets}
            seoData={seoData}
            filteringByFacets={filteringByFacets}
            onOpenFiltersDrawer={handleOpenFiltersDrawer}
            onSuggestedFacetUpdate={onSuggestedFacetUpdate}
            onUpdateFilter={handleUpdateFilter}
            zipCode={zipCode}
            isLoading={isLoading}
            onUpdateZipCode={updateZipCode}
            isSrpOnUsedCore={isSrpOnUsedCore}
            pathName={pathName}
            userCapOnePrequalApproved={userCapOnePrequalApproved}
            urlContext={urlContext}
            makeModelSubmodelYear={makeModelSubmodelYear}
            isSrpOnMakeType={isSrpOnMakeType}
            year={routeParams.year}
            submodel={routeParams.submodel}
            onRemoveCapOnePrequalParam={onRemoveCapOnePrequalParam}
            onMultiMMChange={handleSetIsMultiMM}
            onPageChange={handlePaginationLink}
            withCoreReviewSection={withCoreReviewSection}
            isVinPreview={isVinPreview}
            disableDrivewayNewCarLeadForms={disableDrivewayNewCarLeadForms}
            cpoData={cpoData}
            isShop3181Chal={isShop3181Chal}
            isSHOP3424Chal={isSHOP3424Chal}
            isShop3165Enabled={isShop3165Enabled}
          />
        </LoadingSpinner>
        <UsurpFiltersDrawer
          isOpen={isFilterDrawerOpen}
          onToggle={closeFiltersDrawer}
          onCancel={cancelFiltersUpdate}
          onApply={confirmFiltersUpdate}
          onDestroy={onDestroy}
          searchResultsFound={inventoryCount}
          isLoading={isLoading && isFilterDrawerOpen}
          dataTrackingParent="srp-new-drawer-facets"
          isMobile={isMobile}
          filtersComponent={UsurpFilters}
          paymentType={selectedPaymentType}
          facets={extendedFacets}
          filteringByFacets={filteringByFacets}
          onUpdate={handleUpdateFilter}
          zipCode={zipCode}
          onZipCodeUpdate={updateZipCode}
          isNational={isNational}
          isDealerSrp={isDealerSrp}
          selectedFacets={selectedFacets}
          setIsMultiMM={handleSetIsMultiMM}
          drawerContainerSelector="usurp-filters-drawer-container"
          drawerContainerClasses="bg-cool-gray-10"
          drawerContentClasses="h-100"
          isSHOP3424Chal={isSHOP3424Chal}
          seeResultsBtnColor="blue-50"
          saveBtnColor="blue-30"
        />
        {isNational && isGeoLocationAvailable && !disableGeoLocation && !isCrawler && (
          <GeoLocation tracking={GEO_TRACKING_PARAMS} onSuccess={changeRouteOnZipUpdate} />
        )}
        <Experiment name="shop-1857-model-family" showDefault>
          <Recipe name="ctrl" isDefault />
          <Recipe name="chal1" />
          <Recipe name="chal2" />
        </Experiment>
        <Experiment name="shop-3424-facet-names" showDefault>
          <Recipe name="ctrl" isDefault />
          <Recipe name="chal" />
        </Experiment>
        <Experiment name="shop-3464-zip" showDefault>
          <Recipe name="ctrl" isDefault />
          <Recipe name="chal" />
        </Experiment>
      </main>
    </div>
  );
});

Usurp.propTypes = {
  inventoryData: PropTypes.shape({
    inventories: PropTypes.shape({
      results: InventoryEntities.InventoriesVin,
      totalNumber: PropTypes.number,
      totalPages: PropTypes.number,
    }),
    facets: InventoryEntities.Facets,
    seoInfo: PropTypes.shape({}),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  setModelValue: PropTypes.func.isRequired,
  selectedFacets: PropTypes.shape({}),
  urlContext: PropTypes.shape({}),
  zipCode: PropTypes.string.isRequired,
  location: PropTypes.shape({}),
  visitorLocation: VisitorEntities.Location,
  isSrpOnMakeType: PropTypes.bool,
  makeModelSubmodelYear: VehicleEntities.MakeModelSubmodelYear,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  isSrpOnUsedCore: PropTypes.bool,
  disableInventoryMLPhotoSort: PropTypes.bool,
  disableGeoLocation: PropTypes.bool,
  isCrawler: PropTypes.bool,
  seoHeadContent: PropTypes.shape({}),
  isPrequalified: PropTypes.bool,
  withCoreReviewSection: PropTypes.bool,
  isSHOP1857Enabled: PropTypes.bool,
  renderAsidePageContent: PropTypes.func,
  isVinPreview: PropTypes.bool,
  disableDrivewayNewCarLeadForms: PropTypes.bool,
  cpoData: PropTypes.shape({
    hasCpoMessage: PropTypes.bool,
    oemLogo: PropTypes.string,
  }),
  isShop3181Chal: PropTypes.bool,
  isSHOP3424Chal: PropTypes.bool,
  isShop3165Enabled: PropTypes.bool,
  modifiedZip: PropTypes.string,
};

Usurp.defaultProps = {
  selectedFacets: {},
  urlContext: {},
  location: {},
  visitorLocation: {},
  isSrpOnMakeType: false,
  makeModelSubmodelYear: null,
  match: {},
  isSrpOnUsedCore: false,
  disableInventoryMLPhotoSort: false,
  disableGeoLocation: false,
  isCrawler: false,
  seoHeadContent: {},
  isPrequalified: false,
  withCoreReviewSection: false,
  isSHOP1857Enabled: false,
  renderAsidePageContent: noop,
  isVinPreview: false,
  disableDrivewayNewCarLeadForms: false,
  cpoData: undefined,
  isShop3181Chal: false,
  isSHOP3424Chal: false,
  isShop3165Enabled: false,
  modifiedZip: null,
};

Usurp.displayName = 'Usurp';
