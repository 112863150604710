import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './article-title.scss';

export function ArticleTitle({ tag: Tag, title, subtitle, center, invert }) {
  return (
    <Container>
      <Row
        className={classnames('text-center justify-content-center', {
          'article-title text-md-start justify-content-md-start': !center,
        })}
      >
        <Col xs={12} lg={8}>
          {!!title && (
            <ContentFragment
              componentToUse={Tag}
              classes={classnames('display-1 title fw-bold', { 'text-white text-zdepth-1': !invert })}
            >
              {title}
            </ContentFragment>
          )}
          {!!subtitle && (
            <ContentFragment
              componentToUse="h2"
              classes={classnames('display-2 mt-1_5 subtitle d-none d-md-block', {
                'text-white text-zdepth-1': !invert,
              })}
            >
              {subtitle}
            </ContentFragment>
          )}
        </Col>
      </Row>
    </Container>
  );
}

ArticleTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  center: PropTypes.bool,
  tag: PropTypes.string,
  invert: PropTypes.bool,
};

ArticleTitle.defaultProps = {
  title: '',
  subtitle: '',
  center: false,
  tag: 'h1',
  invert: false,
};
