import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { getIncentivesWithMaxRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-incentives-with-max-rebate';

export const buildEligibleIncentives = (incentives, eligibleIncentiveIds) => {
  const { eligibleIncentivesBasedOnIds, eligibleOemIncentives } = incentives.reduce(
    (result, incentive) => {
      if (eligibleIncentiveIds.includes(incentive.id)) {
        return { ...result, eligibleIncentivesBasedOnIds: [...result.eligibleIncentivesBasedOnIds, incentive] };
      }
      if (isOEMRebate(incentive)) {
        return { ...result, eligibleOemIncentives: [...result.eligibleOemIncentives, incentive] };
      }
      return result;
    },
    {
      eligibleIncentivesBasedOnIds: [],
      eligibleOemIncentives: [],
    }
  );

  return [...eligibleIncentivesBasedOnIds, ...getIncentivesWithMaxRebate(eligibleOemIncentives)];
};
