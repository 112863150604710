import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { getConsumerReviewsUrl, getFeaturesSpecsUrlStandard } from 'site-modules/shared/utils/core-link-constructor';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';

import './research-related-links.scss';

export function ResearchRelatedLinksUI({
  vehicle: {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
  },
}) {
  const linksList = [
    {
      label: 'See Features and Specs',
      iconClassName: 'icon-clipboard5',
      to: getFeaturesSpecsUrlStandard({ makeSlug, modelSlug, year }),
      trackingId: 'view_features_specs',
    },
    {
      label: 'Read Consumer Reviews',
      iconClassName: 'icon-star-full',
      to: getConsumerReviewsUrl({ makeSlug, modelSlug, year }),
      trackingId: 'read_all_reviews',
    },
  ];

  return (
    <div
      className="research-related-links bg-cool-gray-90 rounded-12 px-1 pt-1 pb-0_5 my-1_5"
      data-tracking-parent="research-related-links"
    >
      <h3 className="mb-1 heading-5">
        Learn more about the {makeName} {modelName}
      </h3>
      <Row>
        {linksList.map(({ label, iconClassName, to, trackingId }) => (
          <Col xs={12} md={6} key={iconClassName} className="mb-1">
            <ShoppingLink
              label={label}
              to={to}
              className="bg-white rounded-8 px-1_5 py-1_25"
              iconClassName={iconClassName}
              iconContainerClassName="me-1 size-20 text-white bg-blue-50"
              iconStyles={{ height: '48px', width: '48px' }}
              arrowClassName="icon-arrow-right3 text-primary-darker"
              data-tracking-id={trackingId}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

ResearchRelatedLinksUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
};

export const ResearchRelatedLinks = React.memo(ResearchRelatedLinksUI);
