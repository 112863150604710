import React, { Fragment, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AnimatedCollapse } from 'site-modules/shared/utils/collapse-utils';

import './show-more.scss';

export function ShowMore(props) {
  const { children, collapsedText, expandedText, expandedAriaLabel, collapsedAriaLabel } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCollapseStateChange = useCallback(({ state }) => {
    if (state === 'expanded') {
      ref.current?.focus();
    }
  }, []);

  return (
    <Fragment>
      <AnimatedCollapse isOpen={isOpen} onChange={handleCollapseStateChange}>
        {children({ isOpen, ref })}
      </AnimatedCollapse>
      <button
        aria-label={isOpen ? expandedAriaLabel || expandedText : collapsedAriaLabel || collapsedText}
        aria-expanded={isOpen}
        type="button"
        onClick={handleClick}
        className="collapse-btn size-16 text-primary-darker d-flex align-items-center w-100 justify-content-start px-0"
        style={{ border: 0, marginTop: '0.75rem' }}
      >
        <span className="btn-label me-0_25">{isOpen ? expandedText : collapsedText}</span>
        <span
          className={classnames(
            'arrow-icon d-inline-block size-12 pt-0_25',
            isOpen ? 'icon-arrow-up3' : 'icon-arrow-down3'
          )}
          style={{ width: '12px', height: '16px' }}
        />
      </button>
    </Fragment>
  );
}

ShowMore.propTypes = {
  children: PropTypes.func.isRequired,
  collapsedText: PropTypes.string,
  expandedText: PropTypes.string,
  expandedAriaLabel: PropTypes.string,
  collapsedAriaLabel: PropTypes.string,
};

ShowMore.defaultProps = {
  children: PropTypes.func.isRequired,
  collapsedText: 'Show less',
  expandedText: 'Show more',
  expandedAriaLabel: undefined,
  collapsedAriaLabel: undefined,
};
