import { isEmpty, uniqBy, unionBy } from 'lodash';
import { getCoreUrl, getCustomCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { sortByYear } from 'site-modules/shared/utils/sorting';
import { PUB_STATES } from 'client/constants/pub-states';
import { USED_COMBO_REDIRECT_YEARS } from 'site-modules/shared/utils/core-page/used-combo';

const MIN_YEAR_FOR_SALE = 2000;

export const getModelYears = modelYears =>
  modelYears.map(({ makeSlug, modelSlug, year }) => ({
    name: year,
    url: getCoreUrl({ makeSlug, modelSlug, year }),
  }));

export const getFilteredModelYears = (years, model) => {
  if (isEmpty(years)) {
    return [];
  }
  const identicalModelYears = uniqBy(years.filter(({ modelName }) => model === modelName), 'year');
  const lowestPricedModelYears = uniqBy(years, 'year');
  return unionBy(identicalModelYears, lowestPricedModelYears, 'year').sort(sortByYear);
};

export const getModelYearsWithReview = (years, defaultYear) =>
  years.map(({ makeSlug, modelSlug, year, publicationStates }) => {
    const isUsed = publicationStates.includes(PUB_STATES.USED) && !publicationStates.includes(PUB_STATES.NEW_USED);
    const isUsedCombo = USED_COMBO_REDIRECT_YEARS.includes(parseInt(year, 10));

    return {
      name: year,
      isUsed,
      url: isUsed
        ? getCustomCoreUrl({ makeSlug, modelSlug, year }, isUsedCombo ? '' : 'review/')
        : getCoreUrl({
            makeSlug,
            modelSlug,
            year: parseInt(year, 10) === defaultYear ? null : year,
          }),
      noSpeculation: isUsed && isUsedCombo,
    };
  });

export const getModelYearsForSale = modelYears =>
  getModelYears(
    modelYears.filter(
      ({ publicationStates, year }) => publicationStates.includes(PUB_STATES.USED) && year >= MIN_YEAR_FOR_SALE
    )
  );

export const getForSaleLinks = ({ forSaleYears, makeSlug, modelSlug }) =>
  forSaleYears
    ? forSaleYears.map(year => ({
        name: year.toString(),
        url: getCustomCoreUrl({ makeSlug, modelSlug, year }, 'for-sale/'),
      }))
    : [];
