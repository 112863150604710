import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './white-back-button.scss';

/**
 * This component is not supposed to be customized to keep consistency between all usages.
 */
export function WhiteBackButton({ onClick, className, dataTrackingId, dataTrackingValue }) {
  return (
    <div
      className={classnames(
        'white-back-button pos-r py-1 py-md-1_5 d-flex justify-content-end justify-content-md-start',
        className
      )}
      style={{ zIndex: 1 }}
    >
      <button
        onClick={onClick}
        className="text-white bg-transparent border-0 size-20 d-md-none"
        aria-label="Close drawer"
        data-tracking-id={dataTrackingId}
        data-tracking-value={dataTrackingValue}
      >
        <i className="icon-cross2" aria-hidden />
      </button>
      <button
        onClick={onClick}
        className="text-white bg-transparent border-0 size-20 d-none d-md-block"
        data-tracking-id={dataTrackingId}
        data-tracking-value={dataTrackingValue}
      >
        <i className="icon-arrow-left3 size-16" aria-hidden /> Back
      </button>
    </div>
  );
}

WhiteBackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataTrackingId: PropTypes.string,
  dataTrackingValue: PropTypes.string,
};

WhiteBackButton.defaultProps = {
  className: null,
  dataTrackingId: undefined,
  dataTrackingValue: undefined,
};
