import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { SCROLL_DURATION, SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { ArticleAuthor } from 'site-modules/shared/components/preprod-articles/article-author';

import './review-section.scss';

export function ReviewSection({
  children,
  heading,
  headingTag: HeadingTag,
  subhead,
  body,
  author,
  ratedBy,
  collapsedBody,
  collapsedChildren,
  hideCollapseBtn,
  id,
  bodyClassName,
  collapsedBodyClasName,
  collapseTrackingId,
  headingClassName,
  bodyCreativeId,
  showMrectAd,
  afterBodyChildren,
}) {
  const hasBody = get(body, 'length');
  const hasCollapsedBody = get(collapsedBody, 'length');
  if (!hasBody && !hasCollapsedBody && !children) {
    return null;
  }

  const hasCollapse = Boolean(hasCollapsedBody || collapsedChildren);

  return (
    <Fragment>
      {hasCollapse && !!id && <ScrollElement id={`scrollTop-${id}`} />}
      {heading && <HeadingTag className={headingClassName}>{heading}</HeadingTag>}
      {subhead && <p className="mb-1_5 size-16 text-gray-darker">{subhead}</p>}
      {author && <ArticleAuthor author={author} ratedBy={ratedBy} lazyLoadImage showBioTooltip />}
      {showMrectAd && <MrectNative wrapperClass="mrect float-end ms-1_5 mb-1_5" wiredOnly refreshable={false} />}
      {children}
      {!!hasBody && (
        <ContentFragment
          classes={classnames('review-section-fragment size-16', bodyClassName, {
            first: hasCollapse,
          })}
          componentProps={{ 'data-tracking-parent': bodyCreativeId }}
        >
          {body}
        </ContentFragment>
      )}
      {!hasCollapse && afterBodyChildren}
      {hasCollapse && (
        <ContentCollapse
          isEclipseFade={false}
          btnClasses="px-0_5 py-0 mt-0_75 size-16 text-primary-darker"
          btnContainerClasses="py-0"
          hideCollapseBtn={hideCollapseBtn}
          trackingId={collapseTrackingId}
          justify="justify-content-start"
          showBtnBelow
          btnTextClasses="fw-normal"
          classes="size-16"
          ariaLabelHeading="about this model"
          resetParams={{
            offset: SCROLL_OFFSET,
            duration: SCROLL_DURATION,
            to: `scrollTop-${id}`,
          }}
        >
          <ContentFragment
            classes={classnames('review-section-fragment size-16', collapsedBodyClasName, {
              rest: hasBody,
              'mb-1_5': collapsedChildren,
            })}
          >
            {collapsedBody}
          </ContentFragment>
          {collapsedChildren}
          {afterBodyChildren}
        </ContentCollapse>
      )}
    </Fragment>
  );
}

ReviewSection.propTypes = {
  heading: PropTypes.string,
  headingTag: PropTypes.string,
  subhead: PropTypes.string,
  body: PropTypes.string,
  collapsedBody: PropTypes.string,
  collapsedChildren: PropTypes.node,
  hideCollapseBtn: PropTypes.bool,
  id: PropTypes.string,
  bodyClassName: PropTypes.string,
  collapsedBodyClasName: PropTypes.string,
  collapseTrackingId: PropTypes.string,
  headingClassName: PropTypes.string,
  bodyCreativeId: PropTypes.string,
  author: EditorialReviewEntities.AuthorData,
  ratedBy: EditorialReviewEntities.AuthorData,
  children: PropTypes.node,
  showMrectAd: PropTypes.bool,
  afterBodyChildren: PropTypes.node,
};

ReviewSection.defaultProps = {
  heading: null,
  headingTag: 'h3',
  subhead: null,
  body: null,
  collapsedBody: null,
  collapsedChildren: null,
  hideCollapseBtn: false,
  id: null,
  bodyClassName: 'mb-1_5',
  collapsedBodyClasName: null,
  collapseTrackingId: undefined,
  headingClassName: 'heading-4 mb-0_5',
  bodyCreativeId: null,
  author: null,
  ratedBy: null,
  children: null,
  showMrectAd: false,
  afterBodyChildren: null,
};
