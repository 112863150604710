import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/* Constants */
import { TrackingConstant } from 'client/tracking/constant';
/* Utils */
import { TrackingHandler } from 'client/tracking/handler';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
/* Components */
import { VehicleParam } from 'site-modules/shared/components/car-buying/vehicle-param/vehicle-param';
import { TooltipTextItem } from 'site-modules/shared/components/tooltip-text-item/tooltip-text-item';

export function DeliveryTooltip({ deliveryFee, price, showNote, label, className, classNameWrapper }) {
  const onDeliveryFeesTooltipOpen = () => {
    TrackingHandler.fireEvent(TrackingConstant.ACTION_VIEW_CONTENT, {
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: 'edm-entry-vehicle-card-list',
        value: 'Including Delivery',
      },
    });
  };

  const formattedSummaryPrice = formatPriceString(price + deliveryFee);

  const priceBreakdown = (
    <div className={classNames('price-breakdown-wrapper', classNameWrapper)}>
      <div className="fw-bold size-16 mb-1">Price Breakdown</div>

      <div className={className}>
        <VehicleParam
          label="List Price"
          value={formatPriceString(price)}
          className="mb-1 medium"
          valueClassName="ms-1"
        />
        <VehicleParam
          label="Delivery Fee*"
          value={formatPriceString(deliveryFee)}
          className="medium"
          valueClassName="ms-1"
        />
        <hr className="my-0_75" />
        <VehicleParam label="Total" value={formattedSummaryPrice} className="fw-bold medium" valueClassName="ms-1" />
      </div>
      {showNote && (
        <div className="medium mt-1">
          Please note that Edmunds price ratings do not take delivery fees into account, even when included in the
          price.
        </div>
      )}
    </div>
  );

  return (
    <TooltipTextItem
      buttonClassName="small p-0 mb-0_5"
      id="vehicle-price-tooltip"
      item={{
        text: priceBreakdown,
        placement: 'bottom',
      }}
      tooltipClassName="px-1 tooltip-vehicle-price"
      onTooltipOpen={onDeliveryFeesTooltipOpen}
    >
      <span className="icon-transit me-0_25" title="Shipping" />
      <span className="text-underline">{label}</span>
    </TooltipTextItem>
  );
}

DeliveryTooltip.propTypes = {
  deliveryFee: PropTypes.number,
  price: PropTypes.number,
  showNote: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
};

DeliveryTooltip.defaultProps = {
  deliveryFee: 0,
  price: 0,
  showNote: false,
  label: 'Includes Delivery',
  className: '',
  classNameWrapper: '',
};
