import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  IncentivesWizardEntities,
  IncentivesWizardModel,
  IncentivesWizardPaths,
} from 'client/data/models/incentives-wizard';
import { isPersistedWizardStateApplicable } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-persisted-wizard-state-applicable';
import { unpackPersistedIncentives } from 'site-modules/shared/components/incentives/incentives-wizard/utils/unpack-persisted-incentives';

const getUnpackedWizardState = ({
  wizardState,
  incentives,
  zipCode,
  paymentType,
  inventoryType,
  engineTypes,
  stateCode,
  evaluatePersistedStateMatchWizardContext,
}) => {
  if (!wizardState) {
    return wizardState;
  }

  return isPersistedWizardStateApplicable(
    { wizardState, incentives, zipCode, paymentType, inventoryType, engineTypes, stateCode },
    evaluatePersistedStateMatchWizardContext
  )
    ? unpackPersistedIncentives(wizardState, incentives)
    : {};
};

const WithPersistedWizardStateUI = props => {
  const {
    wizardState,
    incentives,
    children,
    zipCode,
    paymentType,
    inventoryType,
    engineTypes,
    evaluatePersistedStateMatchWizardContext,
    stateCode,
  } = props;

  return children(
    getUnpackedWizardState({
      wizardState,
      incentives,
      zipCode,
      paymentType,
      inventoryType,
      engineTypes,
      stateCode,
      evaluatePersistedStateMatchWizardContext,
    })
  );
};

WithPersistedWizardStateUI.propTypes = {
  incentives: IncentivesWizardEntities.IncentivesData.isRequired,
  wizardState: IncentivesWizardEntities.IncentivesWizardPersistedState,
  children: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
  stateCode: PropTypes.string,
  paymentType: PropTypes.string.isRequired,
  inventoryType: PropTypes.string.isRequired,
  engineTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  evaluatePersistedStateMatchWizardContext: PropTypes.func,
};

WithPersistedWizardStateUI.defaultProps = {
  wizardState: undefined,
  stateCode: undefined,
  evaluatePersistedStateMatchWizardContext: undefined,
};

const stateToPropsConfig = {
  wizardState: bindToPath(IncentivesWizardPaths.getPersistedWizardState(), IncentivesWizardModel, null, false),
};

export const WithPersistedWizardState = connectToModel(WithPersistedWizardStateUI, stateToPropsConfig);
