import { isCarZingPremium, isCarZingPartner } from 'site-modules/shared/utils/dealer-details-utils';
import { CTA_TYPE } from 'site-modules/shared/constants/inventory/lead-form';

export function getCarZingCta(vehicle) {
  if (isCarZingPartner(vehicle)) {
    return CTA_TYPE.USED.BASE;
  }

  return null;
}

export function getCarZingTags(partnerCode) {
  if (isCarZingPremium(partnerCode)) {
    return ['#payperlead', '#carzingpremium'];
  }

  return undefined;
}
