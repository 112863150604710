import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNumber } from 'lodash';

/* Utils */
import { formatPriceString } from 'site-modules/shared/utils/price-utils';

/* Components */
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';

export function VehicleParam(props) {
  const {
    label,
    value,
    tooltip,
    isPrice,
    hideValue,
    showValueAsterisk,
    className,
    valueClassName,
    periodValue,
    sign,
    children,
    withCents,
  } = props;

  if (!hideValue && !value && value !== 0) {
    return null;
  }

  const fractionDigits = withCents ? 2 : 0;

  return (
    <Fragment>
      <div
        className={classNames(
          'd-flex align-items-baseline flex-nowrap justify-content-between g-0 vehicle-param text-gray-darker',
          className
        )}
      >
        <div>
          <span>{label}</span>
          {tooltip && (
            <ClientSideRender>
              <TooltipItem
                id="vehicleParam"
                item={tooltip}
                className="vehicle-param-tooltip small"
                screenReaderTooltipLabel={label}
                useUniqId
              />
            </ClientSideRender>
          )}
        </div>
        {!hideValue && (
          <div className={classNames('text-end', valueClassName)}>
            {sign}
            {isPrice && isNumber(value) ? `${formatPriceString(value, fractionDigits, fractionDigits)}` : value}
            {periodValue && `/${periodValue}`}
            {showValueAsterisk && '*'}
          </div>
        )}
      </div>
      {children}
    </Fragment>
  );
}

VehicleParam.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideValue: PropTypes.bool,
  isPrice: PropTypes.bool,
  showValueAsterisk: PropTypes.bool,
  withCents: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  periodValue: PropTypes.string,
  sign: PropTypes.string,
  tooltip: PropTypes.shape({
    placement: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    boundariesElement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  valueClassName: PropTypes.string,
};

VehicleParam.defaultProps = {
  label: null,
  value: null,
  tooltip: null,
  isPrice: false,
  hideValue: false,
  showValueAsterisk: false,
  sign: '',
  className: 'py-0_5 display-5',
  valueClassName: '',
  periodValue: '',
  children: null,
  withCents: false,
};
