import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './key-point.scss';

export function KeyPoint({ icon, iconTitle, content, classes, showFiveStar }) {
  return (
    <Fragment>
      <span
        aria-label={iconTitle}
        role="img"
        className={classnames(icon, classes, 'key-point-icon d-inline-block size-12 me-0_5')}
      />
      <span className={classnames({ 'me-0_5': showFiveStar }, 'text-cool-gray-30')}>{content}</span>
      {showFiveStar && (
        <span className="text-blue-50">
          <span title="Five Star Dealer" className="icon-5star key-point-icon d-inline-block size-12 me-0_25" />
          <span className="size-12 fw-medium">Five Star Dealer</span>
        </span>
      )}
    </Fragment>
  );
}

KeyPoint.propTypes = {
  icon: PropTypes.string.isRequired,
  iconTitle: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.string.isRequired,
  showFiveStar: PropTypes.bool,
};

KeyPoint.defaultProps = {
  showFiveStar: false,
};
