import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ZipInput, propTypes, defaultProps } from 'site-modules/shared/components/zip-input/zip-input';
import { validation } from 'site-modules/shared/components/form-validation/validation';

import './styled-zip-input.scss';

// Zip input with location icon
export function StyledZipInput({ size, styledWrapperClasses, inputClasses, isMobile, ...props }) {
  return (
    <div className={classnames('styled-zip-input location', styledWrapperClasses, { 'size-lg': size === 'lg' })}>
      <ZipInput
        {...props}
        inputClasses={classnames(
          'size-16 text-gray-darker',
          { 'ps-2': !isMobile },
          { 'ps-0_75': isMobile },
          inputClasses
        )}
      />
    </div>
  );
}

StyledZipInput.propTypes = {
  ...propTypes,
  size: PropTypes.oneOf(['lg']),
  styledWrapperClasses: PropTypes.string,
  onValidate: PropTypes.func,
};

StyledZipInput.defaultProps = {
  ...defaultProps,
  size: null,
  styledWrapperClasses: '',
  onValidate: validation.validateZip,
};
