import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { getRatingScale } from 'site-modules/shared/utils/editorial-helper';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { RatingBadge } from 'site-modules/shared/components/core-page/rating-badge/rating-badge';
import { separateFirstParagraph } from 'site-modules/shared/utils/string';

const CREATIVE_ID = 'edmunds-highlights';

export function SrpCoreHighlights({ editorialReview, toggleButton }) {
  const editorialRating = get(editorialReview, 'ratings.overall.rating');
  const editorialOverallText = get(editorialReview, 'ratings.overall.text');
  const editorialSummaryText = get(editorialReview, 'summary');
  const ratingScale = getRatingScale(editorialReview);

  return (
    <div className="srp-core-highlights rounded-12 bg-white" data-tracking-parent={CREATIVE_ID}>
      <div className="d-md-flex align-items-md-center clearfix">
        <div className="d-flex float-start float-md-none">
          {editorialRating && (
            <RatingBadge
              className="align-self-start clickable px-md-0_75 me-1"
              ratingScale={ratingScale}
              editorialRating={editorialRating}
              isScrollLink={false}
              isCoreOnSrp
            />
          )}
        </div>
        <div>
          <ContentFragment>{editorialOverallText || separateFirstParagraph(editorialSummaryText)[0]}</ContentFragment>
          {toggleButton && <div className="mt-1 mt-md-0_75">{toggleButton}</div>}
        </div>
      </div>
    </div>
  );
}

SrpCoreHighlights.propTypes = {
  editorialReview: EditorialReviewEntities.EditorialReview.isRequired,
  toggleButton: PropTypes.node,
};

SrpCoreHighlights.defaultProps = {
  toggleButton: null,
};
